const currencies = [
  {
    value: "aed",
    desc: "AED - United Arab Emirates Dirham",
    sym: "DH",
  },
  {
    value: "ars",
    desc: "ARS - Argentine Peso",
    sym: "$",
  },
  {
    value: "aud",
    desc: "AUD - Australian Dollar",
    sym: "A$",
  },
  {
    value: "bdt",
    desc: "BDT - Bangladeshi Taka",
    sym: "৳",
  },
  {
    value: "bhd",
    desc: "BHD - Bahraini Dinar",
    sym: "BD",
  },
  {
    value: "bmd",
    desc: "BMD - Bermudian Dollar",
    sym: "$",
  },
  {
    value: "brl",
    desc: "BRL - Brazil Real",
    sym: "R$",
  },
  {
    value: "cad",
    desc: "CAD - Canadian Dollar",
    sym: "CA$",
  },
  {
    value: "chf",
    desc: "CHF - Swiss Franc",
    sym: "Fr.",
  },
  {
    value: "clp",
    desc: "CLP - Chilean Peso",
    sym: "CLP$",
  },
  {
    value: "cny",
    desc: "CNY - Chinese Yuan",
    sym: "¥",
  },
  {
    value: "czk",
    desc: "CZK - Czech Koruna",
    sym: "Kč",
  },
  {
    value: "dkk",
    desc: "DKK - Danish Krone",
    sym: "kr.",
  },
  {
    value: "eur",
    desc: "EUR - Euro",
    sym: "€",
  },
  {
    value: "gbp",
    desc: "GBP - British Pound Sterling",
    sym: "£",
  },
  {
    value: "hkd",
    desc: "HKD - Hong Kong Dollar",
    sym: "HK$",
  },
  {
    value: "huf",
    desc: "HUF - Hungarian Forint",
    sym: "Ft",
  },
  {
    value: "idr",
    desc: "IDR - Indonesian Rupiah",
    sym: "Rp",
  },
  {
    value: "ils",
    desc: "ILS - Israeli New Shekel",
    sym: "₪",
  },
  {
    value: "inr",
    desc: "INR - Indian Rupee",
    sym: "₹",
  },
  {
    value: "jpy",
    desc: "JPY - Japanese Yen",
    sym: "¥",
  },
  {
    value: "krw",
    desc: "KRW - South Korean Won",
    sym: "₩",
  },
  {
    value: "kwd",
    desc: "KWD - Kuwaiti Dinar",
    sym: "KD",
  },
  {
    value: "lkr",
    desc: "LKR - Sri Lankan Rupee",
    sym: "Rs",
  },
  {
    value: "mmk",
    desc: "MMK - Burmese Kyat",
    sym: "K",
  },
  {
    value: "mxn",
    desc: "MXN - Mexican Peso",
    sym: "MX$",
  },
  {
    value: "myr",
    desc: "MYR - Malaysian Ringgit",
    sym: "RM",
  },
  {
    value: "ngn",
    desc: "NGN - Nigerian Naira",
    sym: "₦",
  },
  {
    value: "nok",
    desc: "NOK - Norwegian Krone",
    sym: "kr",
  },
  {
    value: "nzd",
    desc: "NZD - New Zealand Dollar",
    sym: "NZ$",
  },
  {
    value: "php",
    desc: "PHP - Philippine Peso",
    sym: "₱",
  },
  {
    value: "pkr",
    desc: "PKR - Pakistani Rupee",
    sym: "₨",
  },
  {
    value: "pln",
    desc: "PLN - Polish Zloty",
    sym: "zł",
  },
  {
    value: "rub",
    desc: "RUB - Russian Ruble",
    sym: "₽",
  },
  {
    value: "sar",
    desc: "SAR - Saudi Riyal",
    sym: "SR",
  },
  {
    value: "sek",
    desc: "SEK - Swedish Krona",
    sym: "kr",
  },
  {
    value: "sgd",
    desc: "SGD - Singapore Dollar",
    sym: "S$",
  },
  {
    value: "thb",
    desc: "THB - Thai Baht",
    sym: "฿",
  },
  {
    value: "try",
    desc: "TRY - Turkish Lira",
    sym: "₺",
  },
  {
    value: "twd",
    desc: "TWD - New Taiwan Dollar",
    sym: "NT$",
  },
  {
    value: "uah",
    desc: "UAH - Ukrainian hryvnia",
    sym: "₴",
  },
  {
    value: "usd",
    desc: "USD - US Dollar",
    sym: "$",
  },
  {
    value: "vef",
    desc: "VEF - Venezuelan bolívar fuerte",
    sym: "Bs.F",
  },
  {
    value: "vnd",
    desc: "VND - Vietnamese đồng",
    sym: "₫",
  },
  {
    value: "zar",
    desc: "ZAR - South African Rand",
    sym: "R",
  },
];

export default currencies;
