import React, { useState } from "react";
import { Card, Spinner } from "react-bootstrap";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var LocalizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const PortfolioDetailsCard = ({ raptorio }) => {
  const [loading, setLoading] = useState(true);
  const [portfolios, setPortfolios] = useState(true);
  const [defaultPortfolio, setDefaultPortfolio] = useState(false);
  const [native, setNative] = useState(false);
  const [watched, setWatched] = useState(false);
  const [apis, setApis] = useState(false);
  const [nodes, setNodes] = useState(false);

  const fetchData = React.useCallback(() => {
    // Set the loading state
    setLoading(true);

    const getPortfolios = raptorio.getPortfolios();
    const getAddresses = raptorio.getAddresses();
    const getApis = raptorio.getPortfolioEntries("apis");
    const getNodes = raptorio.getPortfolioEntries("smartnodes");

    getApis.then((data) => {
      setApis(data);
    });

    getNodes.then((data) => {
      setNodes(data);
    });

    getAddresses.then((data) => {
      const native = [];
      const watched = [];
      data.map((address) => {
        if (address.category === "native") {
          native.push(address);
        }
        if (address.category === "watched") {
          watched.push(watched);
        }
        return false;
      });
      setNative(native);
      setWatched(watched);
    });

    getPortfolios
      .then((data) => {
        //setPortfolios(data);
        const portfolios = [];
        data.map((portfolio) => {
          if (portfolio.isDefault) {
            setDefaultPortfolio(portfolio);
          } else {
            portfolios.push(portfolio);
          }
          return false;
        });
        setPortfolios(portfolios);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [raptorio]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Card className="flex-grow-1 align-self-stretch shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Raptorio Details {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        Currently using: {defaultPortfolio.label}
        <br />
        Others available: {portfolios.length}
        <br />
        This portfolio has {native.length} native wallets
        <br />
        and {watched.length} watched wallets
        <br />
        Utilizing {apis.length} service apis
        <br />
        Tracking {nodes.length} smartnodes
      </Card.Body>
    </Card>
  );
};

export default PortfolioDetailsCard;
