import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBeer } from "@fortawesome/free-solid-svg-icons";
import NotyfContext from "../contexts/NotyfContext";
import { copyEntry } from "../utils/interactions";

const TipJar = () => {
  const notyf = React.useContext(NotyfContext);
  const provideNotyf = (message, type, duration = 2000) => {
    notyf.open({
      type,
      message,
      duration,
      dismissible: true,
      ripple: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };
  const copyCallback = (msg, status) => {
    provideNotyf(msg, status);
  };
  const copyTipJar = () => {
    copyEntry(process.env.REACT_APP_TIPJAR, copyCallback);
  };

  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      key="tipjar-tooltip"
      placement="top"
      overlay={
        <Popover id="tipjar-tooltip">
          <Popover.Header as="h3">
            <FontAwesomeIcon
              icon={faBeer}
              className="text-warning me-2 align-middle"
              fixedWidth
              size="2x"
            />{" "}
            <span className="fs-4 align-middle">Tip Jar</span>
          </Popover.Header>
          <Popover.Body>
            <strong>Please consider donating!</strong>
            <br />
            <span className="small">
              Your support motivates further development.
            </span>
            <br />
            <span
              className="font-monospace smaller d-block mt-2 user-select-all"
              onClick={() => copyTipJar()}
            >
              {process.env.REACT_APP_TIPJAR}
            </span>
          </Popover.Body>
        </Popover>
      }
    >
      <span className="d-inline-block text-start p-0 m-0">
        <FontAwesomeIcon
          icon={faBeer}
          className="text-warning fa-sm"
          fixedWidth
        />
      </span>
    </OverlayTrigger>
  );
};
export default TipJar;
