import React, { useContext } from "react";
import { Button, CloseButton, Form, Modal } from "react-bootstrap";
import { ModalContext } from "../contexts/ModalContext";

const ModalComponent = () => {
  let { handleModal, show, options } = useContext(ModalContext);
  const [confirm, setConfirm] = React.useState(false);
  const handleConfirmDelete = (checked) => {
    setConfirm(checked);
  };

  React.useEffect(() => {
    if (show) {
      setConfirm(false);
    }
  }, [show]);
  return (
    <Modal
      show={show}
      centered={options.centered}
      size={options.size}
      fullscreen={options.fullscreen}
      onHide={options.onhide}
    >
      <Modal.Header>
        <Modal.Title as="h5">{options.header}</Modal.Title>
        <CloseButton onClick={() => handleModal()} />
      </Modal.Header>
      <Modal.Body className={options.className}>
        {options.content}
        {options.confirmDelete && (
          <div className="d-inline-flex p-2 bd-highlight">
            <Form.Switch
              label="Yes, delete."
              name="formSkipConfirm"
              defaultChecked={false}
              onChange={(e) => handleConfirmDelete(e.target.checked)}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => handleModal(false)}
        >
          Cancel
        </Button>
        {confirm && options.footer}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalComponent;
