import React from "react";
import { Decimal } from "decimal.js";

export const truncate = (str, index = 6) => {
  if (typeof str !== "undefined" && str !== null && str.length > index * 2) {
    var begin = str.substring(0, index);
    var end = str.substring(str.length - index);
    return begin + "..." + end;
  } else {
    return str;
  }
};

export const v1UpdateAddresses = (addresses) => {
  //Upgrade localstorage data from v1 to v2
  if (!!addresses && Array.isArray(addresses)) {
    if (!addresses[0].address) {
      let restore = [];
      addresses.forEach((address) => {
        restore.push({ address: address, label: "" });
      });
      return restore;
    }
  }
  return false;
};

function formatLargeNumber(n, decimalPlaces) {
  var exponentScales = [
    {
      val: 1000000000000000000000000000000000,
      name: "?",
      abbreviation: "V",
      exponent: "33",
    },
    {
      val: 1000000000000000000000000000000,
      name: "?",
      abbreviation: "W",
      exponent: "30",
    },
    {
      val: 1000000000000000000000000000,
      name: "?",
      abbreviation: "X",
      exponent: "27",
    },
    {
      val: 1000000000000000000000000,
      name: "yotta",
      abbreviation: "Y",
      exponent: "24",
    },
    {
      val: 1000000000000000000000,
      name: "zetta",
      abbreviation: "Z",
      exponent: "21",
    },
    {
      val: 1000000000000000000,
      name: "exa",
      abbreviation: "E",
      exponent: "18",
    },
    {
      val: 1000000000000000,
      name: "peta",
      abbreviation: "P",
      exponent: "15",
    },
    {
      val: 1000000000000,
      name: "tera",
      abbreviation: "T",
      exponent: "12",
    },
    {
      val: 1000000000,
      name: "giga",
      abbreviation: "G",
      exponent: "9",
    },
    {
      val: 1000000,
      name: "mega",
      abbreviation: "M",
      exponent: "6",
    },
    {
      val: 1000,
      name: "kilo",
      abbreviation: "K",
      exponent: "3",
    },
  ];
  for (var i = 0; i < exponentScales.length; i++) {
    var item = exponentScales[i];
    var fraction = new Decimal(n / item.val);
    if (fraction >= 1) {
      return [fraction.toDecimalPlaces(decimalPlaces), item];
    }
  }
  return [new Decimal(n).toDecimalPlaces(decimalPlaces), {}];
}

export const FormatDifficulty = ({ dif }) => {
  const format = formatLargeNumber(dif, 3);
  const difficulty = format[0].toString();
  const exp = format[1].exponent ?? 0;
  return (
    <React.Fragment>
      <span>{difficulty}</span>
      <span> x 10</span>
      <span>
        <sup>{exp}</sup>
      </span>
    </React.Fragment>
  );
};

export const FormatChainwork = ({ chwork }) => {
  const format = formatLargeNumber(parseInt("0x" + chwork), 2);
  const chainwork = format[0].toString();
  const exp = format[1].exponent ?? 0;
  return (
    <React.Fragment>
      <span>{chainwork}</span>
      <span> x 10</span>
      <span>
        <sup>{exp}</sup>
      </span>{" "}
      hashes
    </React.Fragment>
  );
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const formatHash = (hash, decimals = 2) => {
  if (!+hash) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["H", "KH", "MH", "GH", "TH", "PH", "EH", "ZH", "YH"];

  const i = Math.floor(Math.log(hash) / Math.log(k));

  return `${parseFloat((hash / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const exportable = {
  truncate,
  FormatDifficulty,
  FormatChainwork,
  formatBytes,
  formatHash,
};

export default exportable;
