import React from "react";
import PasswordStrengthBar from "react-password-strength-bar";
//import Raptorio from "../../../../service/Raptorio";
import {
  Accordion,
  Alert,
  Button,
  Container,
  Col,
  InputGroup,
  Row,
  Tabs,
  Tab,
  Modal,
  Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faRedo } from "@fortawesome/free-solid-svg-icons";
import VanityAddress from "./VanityAddress";
const randomAnimalName = require("random-animal-name");
//const raptorio = new Raptorio({ coin: "RTM", stacy: true });

const ImportWallet = ({ handleAdd, raptorio }) => {
  const [show, setShow] = React.useState(false);
  const [key, setKey] = React.useState("home");
  const [isValidWordlist, setIsValidWordlist] = React.useState(false);
  const [isValidOptions, setIsValidOptions] = React.useState(false);
  const [entropy, setEntropy] = React.useState(256);
  const [label, setLabel] = React.useState(randomAnimalName());
  const [password, setPassword] = React.useState("");
  const [isDefault, setIsDefault] = React.useState(false);
  const [isTestnet, setIsTestnet] = React.useState(false);
  const [isStacy, setIsStacy] = React.useState(false);
  const [mnemonic, setMnemonic] = React.useState(new Array(24).fill(0));
  const [enteredMnemonic, setEnteredMnemonic] = React.useState([]);
  const [vanityPath, setVanityPath] = React.useState(false);
  const [countAdd, setCountAdd] = React.useState(1);

  const resetState = () => {
    setKey("home");
    setMnemonic(new Array(24).fill(0));
    setIsValidWordlist(false);
    setIsValidOptions(false);
    setEntropy(256);
    setLabel(randomAnimalName());
    setEnteredMnemonic([]);
    setPassword("");
    setIsDefault(false);
    setIsStacy(false);
  };

  const handlePasteAnywhere = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text").split(" ");
    //setMnemonic(pastedText);
    setEnteredMnemonic(pastedText);
  };

  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      resetState();
    }, 100);
  };

  const handleShow = () => {
    setShow(true);
    const tabEl = document.getElementById("import-tabs-tabpane-home");
    if (tabEl !== null) {
      tabEl.addEventListener("paste", handlePasteAnywhere, {
        capture: true,
        passive: false,
        once: true,
      });
    }
  };

  const generateArray = (x) => {
    let mnemonicSpaces =
      x === "256" ? new Array(24).fill(0) : new Array(12).fill(0);
    return mnemonicSpaces;
  };

  const handleEntropyChange = (e) => {
    let set = e.target.value;
    setEntropy(set);
    setMnemonic(generateArray(set));
  };

  const handleDefaultChange = (set) => {
    const isSet = set.target.checked ? true : false;
    setIsDefault(isSet);
  };

  const handleNetworkChange = (set) => {
    const isSet = set.target.checked ? true : false;
    setIsTestnet(isSet);
  };

  const handleDeriveChange = (e) => {
    const isSet = e.target.checked ? true : false;
    setIsStacy(isSet);
  };

  const savePortfolio = () => {
    //createPortfolio(seed, label, pass, isDefault)
    try {
      raptorio
        .createPortfolio(
          enteredMnemonic.join(" "),
          label,
          password,
          isDefault,
          isTestnet,
          vanityPath,
          parseInt(countAdd),
          isStacy
        )
        .then((res) => {
          if (!!res?.id) {
            handleAdd(res.id);
            handleClose();
          } else {
            throw new Error("Couldnt create portfolio!");
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const ControlButtons = () => {
    let controls;
    switch (key) {
      case "finalize":
        controls = (
          <Button
            onClick={() => savePortfolio()}
            disabled={!isValidOptions || !isValidWordlist}
          >
            Save Portfolio
          </Button>
        );
        break;
      default:
        controls = (
          <Button
            onClick={() => setKey("finalize")}
            disabled={!isValidWordlist}
          >
            Continue
          </Button>
        );
    }
    return controls;
  };

  const handleFormChange = (e) => {
    var id = e.target.id.split("-").slice().pop();
    enteredMnemonic[id] = e.target.value;
    if (
      enteredMnemonic.filter((word) => {
        return word.length;
      }).length === mnemonic.length
    ) {
      //onValid({ valid: true, wordlist: enteredMnemonic });
      setIsValidWordlist(true);
    } else {
      //onValid({ valid: false, wordlist: enteredMnemonic });
      setIsValidWordlist(false);
    }
  };

  const validateLabel = () => {
    const label = document.getElementById("label");
    if (
      label.value.length < 1 ||
      label.value === " " ||
      label.value.toLowerCase() === "default"
    ) {
      label.classList.remove("is-valid");
      label.classList.add("is-invalid");
      return false;
    }
    label.classList.add("is-valid");
    label.classList.remove("is-invalid");
    return true;
  };

  const validatePassword = () => {
    const pass = document.getElementById("password");
    const passConfirm = document.getElementById("passwordConfirm");
    setPassword(pass.value);
    if (pass.value.length < 1 || pass.value === " ") {
      pass.classList.remove("is-valid");
      pass.classList.add("is-invalid");
      return false;
    }
    if (pass.value.length > 1 && passConfirm.value === "") {
      passConfirm.classList.remove("is-valid");
      passConfirm.classList.remove("is-invalid");
      return false;
    }
    if (pass.value !== passConfirm.value) {
      passConfirm.classList.remove("is-valid");
      passConfirm.classList.add("is-invalid");
      return false;
    }
    pass.classList.add("is-valid");
    pass.classList.remove("is-invalid");
    passConfirm.classList.add("is-valid");
    passConfirm.classList.remove("is-invalid");
    return true;
  };

  const validateInput = () => {
    if (validateLabel() && validatePassword()) {
      return setIsValidOptions(true);
    } else {
      return false;
    }
  };

  const handleLabelRefresh = () => {
    let randomLabel = randomAnimalName();
    document.getElementById("label").value = randomLabel;
    setLabel(randomLabel);
  };

  const handleCountChange = (e) => {
    setCountAdd(e.target.value);
  };

  return (
    <React.Fragment>
      <Button variant="primary" size="sm" onClick={() => setShow(true)}>
        Import
      </Button>
      <Modal
        show={show}
        onShow={() => handleShow()}
        onHide={() => handleClose()}
        fullscreen={true}
        backdrop="static"
        keyboard={false}
        id="import-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            id="import-tabs"
            activeKey={key}
            onSelect={(k) => {
              setKey(k);
            }}
            className="mb-3"
            mountOnEnter={true}
          >
            <Tab eventKey="home" title="Home">
              <Container>
                <Row>
                  <Col className="text-start">
                    <p>
                      Enter your word list, then continue.
                      <br />
                      <span className="small text-info">
                        Hint: Autofill by pasting a space delimited word list
                        into cell 1.
                      </span>
                    </p>
                  </Col>
                </Row>
                <Row className="justify-content-start">
                  {mnemonic.map((word, i) => (
                    <Col
                      xs={4}
                      md={3}
                      lg={2}
                      className="position-relative p-2"
                      key={i}
                    >
                      <span className="float-label text-sm fw-light">
                        {i + 1}
                      </span>
                      <Form.Control
                        id={"word-" + i}
                        size="sm"
                        className="w-float-label font-monospace"
                        type="text"
                        autoComplete="off"
                        autoCapitalize="off"
                        onBlur={(e) => handleFormChange(e)}
                        defaultValue={enteredMnemonic[i] || ""}
                      />
                    </Col>
                  ))}
                </Row>
                <Row className="mt-5">
                  <Col>
                    <Alert key="import-alert" variant="info">
                      <p className="float-start p-3 m-0 text-center">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          fixedWidth
                          size="4x"
                        />
                      </p>
                      <p className="p-3 m-0 float-end">
                        <strong>Importing from the Discord Wallet bot?</strong>
                        <br />
                        Use Advanced Options and enable.{" "}
                        <span className="small">
                          The Discord wallet bot uses a different derivation
                          path!
                        </span>
                      </p>
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Advanced Options</Accordion.Header>
                      <Accordion.Body>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label as="legend" column sm={3}>
                            Set as Default Portfolio?
                          </Form.Label>
                          <Col sm={9}>
                            <Form.Switch
                              label="Yes"
                              value="true"
                              name="formSetDefault"
                              onChange={(e) => handleDefaultChange(e)}
                              defaultChecked={isDefault}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label as="legend" column sm={3}>
                            Use on Testnet Network?
                          </Form.Label>
                          <Col sm={9}>
                            <Form.Switch
                              label="Yes"
                              value="true"
                              name="create-formSetNetwork"
                              onChange={(e) => handleNetworkChange(e)}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label as="legend" column sm={3}>
                            Create Addresses
                          </Form.Label>
                          <Col sm={1}>
                            <Form.Control
                              aria-label="Small"
                              aria-describedby="inputGroup-sizing-sm"
                              type="number"
                              min="1"
                              defaultValue="1"
                              onChange={(e) => handleCountChange(e)}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label as="legend" column sm={3}>
                            Word List
                          </Form.Label>
                          <Col sm={9}>
                            <Form.Check
                              type="radio"
                              label="12 Words"
                              value="128"
                              name="formHorizontalRadios"
                              key="formHorizontalRadios1"
                              onChange={(e) => handleEntropyChange(e)}
                              defaultChecked={entropy === 128}
                            />
                            <Form.Check
                              type="radio"
                              label="24 Words"
                              value="256"
                              name="formHorizontalRadios"
                              key="formHorizontalRadios2"
                              onChange={(e) => handleEntropyChange(e)}
                              defaultChecked={entropy === 256}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label as="legend" column sm={3}>
                            Import from Wallet Bot (aka Stacy Mom)?
                          </Form.Label>
                          <Col sm={9}>
                            <Form.Switch
                              label="Yes"
                              value="true"
                              name="formSetDerive"
                              onChange={(e) => handleDeriveChange(e)}
                              defaultChecked={isStacy}
                            />
                          </Col>
                        </Form.Group>
                      </Accordion.Body>
                    </Accordion.Item>
                    {!!isValidWordlist && (
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Vanity Address</Accordion.Header>
                        <Accordion.Body>
                          <VanityAddress
                            mnemonic={enteredMnemonic}
                            setVanityPath={setVanityPath}
                            isStacy={isStacy}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                  </Accordion>
                </Row>
              </Container>
            </Tab>
            <Tab
              eventKey="finalize"
              title="Finalize"
              disabled={!isValidWordlist}
            >
              <Container>
                <Row>
                  <Col className="text-start">
                    <Form>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                          Label
                        </Form.Label>
                        <Col sm="4">
                          <InputGroup className="mb-3">
                            <Form.Control
                              id="label"
                              size="sm"
                              type="text"
                              name="label"
                              className="is-valid"
                              placeholder="Label"
                              defaultValue={label}
                              onBlur={(e) => validateInput()}
                              onChange={(e) => validateInput()}
                              required
                              autoComplete="off"
                            />
                            <Button variant="outline-secondary" size="sm">
                              <FontAwesomeIcon
                                icon={faRedo}
                                fixedWidth
                                onClick={() => handleLabelRefresh()}
                              />
                            </Button>
                          </InputGroup>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                          Password
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            id="password"
                            size="sm"
                            type="password"
                            name="password"
                            placeholder="Enter password"
                            onBlur={(e) => validateInput()}
                            onChange={(e) => validateInput()}
                            required
                            autoComplete="off"
                          />
                          <PasswordStrengthBar password={password} />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                          Confirm Password
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            id="passwordConfirm"
                            size="sm"
                            type="password"
                            name="passwordConfirm"
                            placeholder="Confirm password"
                            onBlur={(e) => validateInput()}
                            onChange={(e) => validateInput()}
                            required
                            autoComplete="off"
                          />
                        </Col>
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            type="reset"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <ControlButtons />
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ImportWallet;
