import React from "react";
import { Col, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import DataTable from "../../../components/tables/DataTable";
import { getData } from "../../../service/Service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faArrowRightFromBracket,
  faArrowRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var updateLocale = require("dayjs/plugin/updateLocale");
var utc = require("dayjs/plugin/utc");
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "1 minute",
    mm: "%d minutes",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day",
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years",
  },
});

/*
const ExtraTypeName = (type) => {
  //https://dashcore.readme.io/docs/core-ref-transactions-special-transactions
  switch (type) {
    case 0:
      return "Standard Transaction";
    case 1:
      return "Masternode Registration";
    case 2:
      return "Update Masternode Service";
    case 3:
      return "Update Masternode Operator";
    case 4:
      return "Masternode Operator Revocation";
    case 5:
      return "Coinbase";
    //return "Masternode List Merkle Proof";
    case 6:
      return "Long-Living Masternode Quorum Commitment";
    case 7:
      //return "Masternode hard fork signal";
      return "Future";
    case 8:
      return "Asset";
    case 9:
      return "Update Asset";
    default:
      return "Unknown Type";
  }
};

const ExtraTypeClass = (type) => {
  //https://dashcore.readme.io/docs/core-ref-transactions-special-transactions
  // see above for transaction types
  switch (type) {
    case 0:
      return "success";
    case 1:
      return "info";
    case 2:
      return "info";
    case 3:
      return "info";
    case 4:
      return "info";
    case 5:
      return "primary";
    case 6:
      return "info";
    case 7:
      return "danger";
    case 8:
      return "warning";
    case 9:
      return "warning";
    default:
      return "secondary";
  }
};
*/
const AddressTxCard = ({ address }) => {
  // We'll start our table without any data
  const [loading, setLoading] = React.useState(false);
  const defaultSortCol = React.useRef("n_time");
  const service = React.useRef("explorer");
  const method = React.useRef("getAddressTxList");
  const isMultiple = address.includes(",");

  const TransactionListCols = React.useMemo(
    () =>
      isMultiple
        ? [
            {
              id: "is_spent",
              cell: (info) => info.getValue(),
              header: () => (
                <FontAwesomeIcon
                  icon={faLock}
                  fixedWidth
                  title="Spent Status"
                />
              ),
              footer: (props) => props.column.id,
              accessorFn: (row) => {
                const icon =
                  row.spent === true
                    ? faArrowRightFromBracket
                    : faArrowRightToBracket;
                const classText =
                  row.spent === true ? "text-warning" : "text-success";

                return row.status === "Pending" ? (
                  <Spinner animation="border" size="sm" variant="dark" />
                ) : (
                  <FontAwesomeIcon
                    icon={icon}
                    fixedWidth
                    className={classText}
                    title={row.status}
                  />
                );
              },
            },
            {
              accessorFn: (row) => {
                return (
                  <Link
                    to={"/explorer/address/" + row.address}
                    className="cursor-zoom-in"
                  >
                    <span className="d-none d-xl-inline">{row.address}</span>
                    <span className="d-xl-none">{row.address_truncate}</span>
                  </Link>
                );
              },
              id: "address",
              cell: (info) => info.getValue(),
              header: () => <span>Address</span>,
              footer: (props) => props.column.id,
            },
            {
              accessorFn: (row) => {
                const link = row.spent ? "#input-" : "#output-";
                return (
                  <Link
                    to={"/explorer/tx/" + row.txid + link + row.index}
                    className="cursor-zoom-in"
                  >
                    <span className="d-none d-xl-inline">{row.txid}</span>
                    <span className="d-xl-none">{row.txid_truncate}</span>
                  </Link>
                );
              },
              id: "hash_this_tx",
              cell: (info) => info.getValue(),
              header: () => <span>Tx ID</span>,
              footer: (props) => props.column.id,
            },
            {
              accessorFn: (row) => {
                return (
                  <OverlayTrigger
                    key={`age-${row.txid}`}
                    placement="top"
                    delay={{ show: 500, hide: 100 }}
                    overlay={
                      <Tooltip id={`agetooltip-${row.txid}`}>
                        {row.time_format}
                      </Tooltip>
                    }
                  >
                    <span>{dayjs.unix(row.timestamp).fromNow(true)}</span>
                  </OverlayTrigger>
                );
              },
              id: "n_time",
              cell: (info) => info.getValue(),
              header: () => <span>Age</span>,
              footer: (props) => props.column.id,
            },
            {
              accessorFn: (row) => {
                return (
                  <OverlayTrigger
                    key={`satamount-${row.txid}`}
                    placement="left"
                    delay={{ show: 500, hide: 100 }}
                    overlay={<Tooltip id="tooltip-left">RTM</Tooltip>}
                  >
                    <span>
                      <NumericFormat
                        value={row.satoshis_format}
                        displayType={"text"}
                        thousandSeparator={true}
                        defaultValue={0}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </span>
                  </OverlayTrigger>
                );
              },
              id: "satoshis",
              cell: (info) => {
                return <div className="text-end">{info.getValue()}</div>;
              },
              header: () => <div className="text-end">Amount</div>,
              footer: (props) => props.column.id,
            },
          ]
        : [
            {
              id: "is_spent",
              cell: (info) => info.getValue(),
              header: () => (
                <FontAwesomeIcon
                  icon={faLock}
                  fixedWidth
                  title="Spent Status"
                />
              ),
              footer: (props) => props.column.id,
              accessorFn: (row) => {
                const icon =
                  row.spent === true
                    ? faArrowRightFromBracket
                    : faArrowRightToBracket;
                const classText =
                  row.spent === true ? "text-warning" : "text-success";
                const classTextP =
                  row.status === "Pending" ? "text-danger" : classText;

                return (
                  <FontAwesomeIcon
                    icon={icon}
                    fixedWidth
                    className={classTextP}
                    title={row.status}
                  />
                );
              },
            },
            {
              accessorFn: (row) => {
                const link = row.spent ? "#input-" : "#output-";
                return (
                  <Link
                    to={"/explorer/tx/" + row.txid + link + row.index}
                    className="cursor-zoom-in"
                  >
                    <span className="d-none d-xl-inline">{row.txid}</span>
                    <span className="d-xl-none">{row.txid_truncate}</span>
                  </Link>
                );
              },
              id: "hash_this_tx",
              cell: (info) => info.getValue(),
              header: () => <span>Tx ID</span>,
              footer: (props) => props.column.id,
            },
            {
              accessorFn: (row) => {
                return (
                  <OverlayTrigger
                    key={`age-${row.txid}`}
                    placement="top"
                    delay={{ show: 500, hide: 100 }}
                    overlay={
                      <Tooltip id={`agetooltip-${row.txid}`}>
                        {row.time_format}
                      </Tooltip>
                    }
                  >
                    <span>{dayjs.unix(row.timestamp).fromNow(true)}</span>
                  </OverlayTrigger>
                );
              },
              id: "n_time",
              cell: (info) => info.getValue(),
              header: () => <span>Age</span>,
              footer: (props) => props.column.id,
            },
            {
              accessorFn: (row) => {
                return (
                  <OverlayTrigger
                    key={`satamount-${row.txid}`}
                    placement="left"
                    delay={{ show: 500, hide: 100 }}
                    overlay={<Tooltip id="tooltip-left">RTM</Tooltip>}
                  >
                    <span>
                      <NumericFormat
                        value={row.satoshis_format}
                        displayType={"text"}
                        thousandSeparator={true}
                        defaultValue={0}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </span>
                  </OverlayTrigger>
                );
              },
              id: "satoshis",
              cell: (info) => info.getValue(),
              header: () => <span>Amount</span>,
              footer: (props) => props.column.id,
            },
          ],
    [isMultiple]
  );

  const fetchData = React.useCallback(
    ({ pageIndex, pageSize, sorting, globalFilter }) => {
      // Set the loading state
      setLoading(true);

      const sortBy = sorting[0]?.id ?? defaultSortCol.current;
      const sortDir =
        sorting[0]?.id !== undefined
          ? sorting[0]?.desc === true
            ? "DESC"
            : "ASC"
          : "DESC";

      const params = {
        service: service.current,
        method: method.current,
        address: address,
        page: pageIndex,
        limit: pageSize,
        dir: sortDir,
        sort: sortBy,
        filter: globalFilter,
      };

      const serverData = getData(params)
        .then((data) => {
          const status = data.status;
          if (status === "success") {
            if (typeof data[params.method].results !== "undefined") {
              const payload = data[params.method].results;
              const count = parseInt(data[params.method].summary.pages);

              return { rows: payload, pageCount: count };
              //setPageCount(parseInt(data[params.method].summary.pages));
            }
          } else {
            throw new Error("didnt get data");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .then((data) => {
          setLoading(false);
          return data;
        });
      return serverData;
    },
    [address]
  );

  return (
    <Row>
      <Col>
        <DataTable
          queryKey={method.current + "-" + address}
          columns={TransactionListCols}
          fetchData={fetchData}
          loading={loading}
          placeholderText="Search (by tx hash)"
          defaultSortCol={defaultSortCol.current}
        />
      </Col>
    </Row>
  );
};

export default AddressTxCard;
