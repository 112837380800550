import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, ProgressBar, Table } from "react-bootstrap";
import RefreshButtons from "../../components/live/RefreshTimer";
import { getData } from "../../service/Service";
import { NumericFormat } from "react-number-format";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var LocalizedFormat = require("dayjs/plugin/localizedFormat");

dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const SystemNode = () => {
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [refreshInterval, setRefreshInterval] = React.useState(10000);

  const fetchData = React.useCallback(() => {
    // Set the loading state
    setLoading(true);

    const params = {
      service: "node",
      method: "getstatus",
    };

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setData(payload);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData, refreshInterval]);

  return (
    <React.Fragment>
      <Helmet title={`${process.env.REACT_APP_NAME} Status`} />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3 fw-light">
          <span className="me-1 fs-4">{`${process.env.REACT_APP_NAME} Status`}</span>
        </h1>
        <Row>
          <Col>
            <Card className="shadow-lg">
              <Card.Header className="p-2">
                <Card.Title tag="h6" className="m-0">
                  Summary
                  <RefreshButtons
                    refreshInterval={refreshInterval}
                    setRefreshInterval={setRefreshInterval}
                    loading={loading}
                    callback={fetchData}
                  />
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Table size="sm" className="user-select-none">
                  <thead>
                    <tr>
                      <th scope="col" className="w-25"></th>
                      <th scope="col" className="w-75"></th>
                    </tr>
                  </thead>
                  {data && (
                    <tbody>
                      <tr>
                        <th scope="row">Sync</th>
                        <td>
                          {data.isResyncingClient ? (
                            <React.Fragment>
                              {`Wallet client syncing... ${data.walletSyncPercent}%`}
                              <br />
                              <ProgressBar
                                animated
                                variant="danger"
                                now={data.walletSyncPercent}
                              />
                              <span className="small">
                                Block {data.cliBlocks} of {data.cliHeaders} -{" "}
                                {data.cliHeaders - data.cliBlocks} remaining
                              </span>
                            </React.Fragment>
                          ) : data.isSyncing ? (
                            <React.Fragment>
                              {`Syncing... ${data.percent}%`}
                              <br />
                              <ProgressBar
                                animated
                                variant="warning"
                                now={data.percent}
                              />
                              <span className="text-warning small">
                                [
                                {dayjs.unix(data.this_block).fromNow(true) +
                                  " behind"}
                                ]
                              </span>{" "}
                              <span className="small">
                                Last block {data.this_block_format}
                              </span>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {data.isStalled ? (
                                <React.Fragment>
                                  {`Stalled... ${data.percent}%`}
                                  <br />
                                  <ProgressBar
                                    animated
                                    variant="danger"
                                    now={data.percent}
                                  />
                                  <span className="small">
                                    {data.this_block_format}
                                  </span>{" "}
                                  <span className="text-warning small">
                                    [
                                    {dayjs.unix(data.this_block).fromNow(true) +
                                      " behind"}
                                    ]
                                  </span>
                                </React.Fragment>
                              ) : (
                                "Synchronized with network"
                              )}
                            </React.Fragment>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Block Count</th>
                        <td>
                          <NumericFormat
                            value={data.dbBlocks}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                          {data.isSyncing || data.isStalled ? (
                            <React.Fragment>
                              {" "}
                              /{" "}
                              <NumericFormat
                                value={data.cliBlocks}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      {data.isSyncing && (
                        <tr>
                          <th scope="row">Remaining</th>
                          <td>
                            <NumericFormat
                              value={data.cliBlocks - data.dbBlocks}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th scope="row">Uptime</th>
                        <td>
                          {dayjs().subtract(data.uptime, "s").fromNow(true)}{" "}
                          <span className="text-secondary small">
                            [{dayjs().subtract(data.uptime, "s").format("L LT")}
                            ]
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Version</th>
                        <td>{data.version}</td>
                      </tr>
                      <tr>
                        <th scope="row">Connections</th>
                        <td>{data.connections}</td>
                      </tr>
                      <tr>
                        <th scope="row">Blockchain</th>
                        <td>
                          {data.size_on_disk_format}
                          <br />
                          {data.pruned ? "Pruned: true" : "Pruned: false"}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default SystemNode;
