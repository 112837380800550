import React from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Modal,
  Nav,
  OverlayTrigger,
  Popover,
  Row,
  Tab,
  Table,
} from "react-bootstrap";
import {
  Book,
  ChevronsLeft,
  ChevronsRight,
  ChevronsUp,
  ChevronsDown,
  Filter,
  Info,
} from "react-feather";
import { NumericFormat } from "react-number-format";
import DatePicker from "react-datepicker";
import CoinControl from "./CoinControl";
import { getData, postData } from "../../service/Service";
import NotyfContext from "../../contexts/NotyfContext";
import Addresses from "../settings/portfolio/Addresses";
//const raptoreumcore = window.raptoreumcore;
const raptoreumcore = require("@socialruins/raptoreumcore-lib");
var dayjs = require("dayjs");
var duration = require("dayjs/plugin/duration");
var relativeTime = require("dayjs/plugin/relativeTime");
var updateLocale = require("dayjs/plugin/updateLocale");
var utc = require("dayjs/plugin/utc");
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "1 minute",
    mm: "%d minutes",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day",
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years",
  },
});

const PassModal = ({ passModalShow, setPassModalShow, handleAction }) => {
  const [pass, setPass] = React.useState(false);
  const handleClose = () => setPassModalShow(false);
  const handleSave = () => {
    if (!!pass && pass.length > 1) {
      handleAction(pass);
      setPass(false);
      handleClose();
      //console.log("use password: " + pass);
    }
  };
  return (
    <Modal show={passModalShow} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Enter Portfolio Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Control
            type="password"
            placeholder="Password"
            onChange={(e) => setPass(e.target.value)}
            autoComplete="false"
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Unlock
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ConfirmModal = ({
  confirmModalShow,
  handleConfirmModalClose,
  handleAction,
  summary,
  rawData,
  rawFee,
  rawTx,
  futures,
}) => {
  const [lockInterval] = React.useState(5000);
  const [countdown, setCountdown] = React.useState(lockInterval / 1000);
  const [locked, setLocked] = React.useState(true);
  const handleClose = () => {
    handleConfirmModalClose();
    setLocked(true);
    setCountdown(lockInterval / 1000);
  };
  const handleSave = () => {
    handleAction();
    handleClose();
  };

  const getRecipientLabel = () => {
    if (!summary.addressbook) {
      return summary.recipient;
    }
    const recipientLabel = summary.addressbook.filter(
      (address) => address.address === summary.recipient
    );

    if (!recipientLabel.length) {
      return <div className="font-monospace">{summary.recipient}</div>;
    }

    return recipientLabel.map((label) => {
      return (
        <div key={`recipientLabel-${label.address}`}>
          <div className="font-monospace">{label.address}</div>
          <div>
            <span className="text-warning small">{label.label}</span>
          </div>
        </div>
      );
    });
  };

  const getSenderLabel = () => {
    if (!summary.addressLabels) {
      return summary.sender;
    }
    return summary.sender.map((sendAddress) => {
      const senderLabels = summary.addressLabels.filter(
        (address) => address.address === sendAddress
      );
      return senderLabels.map((label) => {
        return (
          <div key={`senderLabel-${label.address}`}>
            <div className="font-monospace">{label.address}</div>
            <div>
              <span className="text-primary small">
                {summary.portfolio.label}
              </span>{" "}
              &gt; <span className="text-success small">{label.label}</span>
            </div>
          </div>
        );
      });
    });
  };

  //console.log(summary.sendLabels, summary.addressbook);
  //console.log(getRecipientLabel());

  React.useEffect(() => {
    if (confirmModalShow && locked) {
      let count = 5;
      const interval = setInterval(() => {
        if (count > 0) {
          count--;
          setCountdown(count);
        }
        if (count === 0) {
          //count = lockInterval / 1000;
          setLocked(false);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [confirmModalShow, locked]);

  return (
    <Modal show={confirmModalShow} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Confirm Transaction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tab-light wallet-ui">
          <Tab.Container id="tx-confirm-tabs" defaultActiveKey="summary">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="summary">Summary</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="raw">Raw</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="summary">
                <Table striped bordered hover size="sm">
                  <tbody>
                    <tr>
                      <th>From</th>
                      <td>{getSenderLabel()}</td>
                    </tr>
                    <tr>
                      <th>To</th>
                      <td>{getRecipientLabel()}</td>
                    </tr>
                    <tr>
                      <th>Amount</th>
                      <td className="font-monospace">
                        <NumericFormat
                          value={summary.amount - rawFee / 1e8}
                          displayType={"text"}
                          thousandSeparator={true}
                          defaultValue={0}
                          decimalScale={8}
                          fixedDecimalScale={true}
                        />
                      </td>
                    </tr>
                    {futures.isFuture ? (
                      <React.Fragment>
                        <tr>
                          <th>Estimated Maturity</th>
                          <td className="font-monospace">
                            {dayjs(futures.estDate).format("L LT")}
                          </td>
                        </tr>
                        <tr>
                          <th>In Blocks</th>
                          <td className="font-monospace">{futures.maturity}</td>
                        </tr>
                        <tr>
                          <th>Or in seconds</th>
                          <td className="font-monospace">
                            {futures.locktime} (in{" "}
                            {dayjs
                              .duration(futures.locktime, "seconds")
                              .humanize()}
                            )
                          </td>
                        </tr>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                    {summary.note ? (
                      <tr>
                        <th>Note</th>
                        <td>{summary.note}</td>
                      </tr>
                    ) : (
                      ""
                    )}
                    <tr>
                      <th>Estimated Fee</th>
                      <td className="font-monospace">
                        <NumericFormat
                          value={rawFee / 1e8}
                          displayType={"text"}
                          thousandSeparator={true}
                          defaultValue={0}
                          decimalScale={8}
                          fixedDecimalScale={true}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div className="text-warning">
                  Double check your transaction for mistakes.
                </div>
                <div className="text-danger">
                  After this point, you cannot change.
                </div>
                <div className="text-secondary mt-4">
                  View 'Raw' tab for details
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="raw">
                Serialized Transaction Hex
                <div
                  className="p-2 overflow-scroll text-muted border"
                  style={{ maxHeight: "100px" }}
                >
                  <code>{rawTx}</code>
                </div>
                Transaction JSON
                <div className="p-2 vh-50 overflow-scroll text-muted border">
                  <pre>
                    <code>{JSON.stringify(rawData, null, 4)}</code>
                  </pre>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={locked}>
          {locked ? `(${countdown})` : "Send"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const SuccessModal = ({
  successModalShow,
  handleSuccessModalClose,
  sentTx,
}) => {
  const handleClose = () => handleSuccessModalClose();
  return (
    <Modal show={successModalShow} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Transaction Sent</Modal.Title>
      </Modal.Header>
      <Modal.Body>{sentTx}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AddressBookModal = ({
  showAddressBook,
  handleAddressBookClose,
  raptorio,
}) => {
  return (
    <Modal
      show={showAddressBook}
      onHide={handleAddressBookClose}
      size="lg"
      id="address-book-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Address Book</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Addresses
          key="addressbook-modal"
          category="addressbook"
          name="Addresses"
          raptorio={raptorio}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleAddressBookClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleAddressBookClose}>
          Select
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Send = ({
  raptorio,
  sendWallets,
  addressLabels,
  addresses,
  portfolio,
  changeWallet,
  addressBook,
  balances,
}) => {
  //confirmation modal data
  const [txJsonData, setTxJsonData] = React.useState(false);
  const [rawTx, setRawTx] = React.useState(false);
  const [rawTxFee, setRawTxFee] = React.useState(0);

  const [selectedWallet, setSelectedWallet] = React.useState(false);
  const [sentTx, setSentTx] = React.useState(false);

  //const [fees, setFees] = React.useState(false);
  const [currentPrice, setCurrentPrice] = React.useState(0);
  const [normalFee, setNormalFee] = React.useState(0);
  const [specialFee, setSpecialFee] = React.useState(0);
  //MODALS
  const [showCoinControl, setShowCoinControl] = React.useState(false);
  const [passModalShow, setPassModalShow] = React.useState(false);
  const [showAddressBook, setShowAddressBook] = React.useState(false);
  const [confirmModalShow, setConfirmModalShow] = React.useState(false);
  const [successModalShow, setSuccessModalShow] = React.useState(false);

  const [selectedTx, setSelectedTx] = React.useState(false);
  const [utxos, setUtxos] = React.useState(false);

  //const [selectedRowIds, setSelectedRowIds] = React.useState({});
  //const [selectedTxSummary, setSelectedTxSummary] = React.useState(false);

  const [privKey, setPrivKey] = React.useState(false);

  const [recipientWallet, setRecipientWallet] = React.useState(false);
  //const [availableBalance, setAvailableBalance] = React.useState(false);
  const [sendAmount, setSendAmount] = React.useState(0);
  const [sendNote, setSendNote] = React.useState(false);

  const [asFuture, setAsFuture] = React.useState(false);
  const [futureStartDate, setFutureStartDate] = React.useState(new Date());
  const [ftMaturity, setFtMaturity] = React.useState(0);
  const [ftLocktime, setFtLocktime] = React.useState(0);

  const [isCoinControl, setIsCoinControl] = React.useState(false);

  const notyf = React.useContext(NotyfContext);
  const provideNotyf = React.useCallback(
    (message, type, duration = 2000) => {
      notyf.open({
        type,
        message,
        duration,
        dismissible: true,
        ripple: true,
        position: {
          x: "right",
          y: "top",
        },
      });
    },
    [notyf]
  );

  const handleCoinControlShow = () => setShowCoinControl(true);
  const handleCoinControlClose = () => setShowCoinControl(false);
  const handleCoinControlSelect = (rows) => {
    setSelectedTx(rows);

    let selectedVal = 0;
    rows.map((tx) => {
      return (selectedVal += tx.satoshis);
    });
    selectedVal = selectedVal / 1e8;

    //setSelectedRows(selectedRowIds.ids);
    setShowCoinControl(false);
    setIsCoinControl(selectedVal > 0);
    setSendAmount(selectedVal > 0 ? selectedVal : 0);
  };
  const handleAddressBookClose = () => setShowAddressBook(false);
  const handleAddressBookShow = () => setShowAddressBook(true);
  //const handleConfirmModalShow = () => setConfirmModalShow(true);
  const handleConfirmModalClose = () => {
    setConfirmModalShow(false);
    setPrivKey(false);
    setTxJsonData(false);
    setRawTx(false);
    setRawTxFee(0);
  };

  const handleSuccessModalClose = () => {
    setSuccessModalShow(false);
    handleReset();
  };
  const handleReset = () => {
    setRecipientWallet(false);
    setSendAmount(false);
    setSendNote(false);
    setSelectedTx(false);
    setPrivKey(false);
    setTxJsonData(false);
    setRawTx(false);
    setRawTxFee(false);
    setSentTx(false);
  };

  const getUtxos = React.useCallback(() => {
    if (!sendWallets || !sendAmount) {
      //alert("Cant do something with nothing!");
      provideNotyf("Missing information", "danger");
      return false;
    }

    let coverAmount = Math.ceil(parseFloat(sendAmount));

    if (!!asFuture) {
      coverAmount = coverAmount + specialFee;
    }

    if (Object.keys(selectedTx).length > 0) {
      let selectedVal = 0;
      selectedTx.map((tx) => {
        return (selectedVal += tx.satoshis);
      });
      selectedVal = selectedVal / 1e8;

      let summary = {};
      summary.total_inputs = Object.keys(selectedTx).length;
      summary.total_available = selectedVal;

      let useCoinControl = { utxos: selectedTx, summary: summary };

      return setUtxos(useCoinControl);
    }

    // Set the loading state
    const params = {
      service: "explorer",
      method: "getAddressUtxoForNewTx",
      address: sendWallets.join(","),
      //filter: Math.floor(Number(sendAmount) * 100000000),
      filter: coverAmount,
    };

    setSelectedWallet(sendWallets);

    const serverData = getData(params);

    serverData
      .then((data) => {
        if (data.status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            const summary = data[params.method].summary;
            //setSelectedTx(data[params.method].results);
            //setSelectedTxSummary(data[params.method].summary);
            return { utxos: payload, summary: summary };
          }
        } else {
          //alert("Couldnt get utxos...");
          provideNotyf("Couldnt retrieve utxos...", "danger");
          return false;
        }
      })
      .then((payload) => {
        setUtxos(payload);
      });
  }, [sendWallets, sendAmount, provideNotyf, asFuture, specialFee, selectedTx]);

  const prepareTx = React.useCallback(() => {
    //const privateKey = new raptoreumcore.PrivateKey(privKey);
    //const privKeys = privKey.map((key) => raptoreumcore.PrivateKey(key));
    let sendAmountSats = Math.floor(parseFloat(sendAmount) * 1e8);

    //getUtxos();

    try {
      const transaction = new raptoreumcore.Transaction();
      let inputs = [];
      for (const element of utxos.utxos) {
        let utxo = {};
        utxo.satoshis = element.satoshis;
        utxo.script = element.script_hex;
        utxo.address = element.address;
        utxo.txId = element.txid;
        utxo.outputIndex = element.n_vout;
        inputs.push(utxo);
      }

      let fee = Math.floor(parseFloat(normalFee) * 1e8 * inputs.length);
      let extraFee = Math.floor(parseInt(specialFee) * 1e8);
      if (!!asFuture) {
        fee = fee + extraFee;
      }

      sendAmountSats = sendAmountSats - fee;

      transaction.from(inputs);
      transaction.to(recipientWallet, sendAmountSats);
      transaction.change(changeWallet);
      transaction.fee(fee);

      if (!!asFuture) {
        const inputsHash = transaction.getInputsHash();
        const lockIndex = transaction.outputs.findIndex(
          (output) => output.satoshis === sendAmountSats
        );

        const formPayload = {
          maturity: parseInt(ftMaturity), // need this
          lockTime: parseInt(ftLocktime), // need this
          lockOutputIndex: lockIndex,
          fee: parseInt(specialFee), // need this
          inputsHash: inputsHash,
        };
        var Payload = raptoreumcore.Transaction.Payload;
        var FutureTxPayload = Payload.FutureTxPayload;
        var validPayload = new FutureTxPayload(formPayload);

        transaction.setType(7);
        transaction.setExtraPayload(validPayload);
      }

      if (!!sendNote) {
        transaction.addData(sendNote);
      }

      //transaction.sort();
      setRawTxFee(transaction.getFee());

      transaction.sign(privKey);

      if (!transaction.isFullySigned()) {
        return provideNotyf("Missing required signatures", "danger");
      } else {
        setTxJsonData(transaction.toJSON());
        setRawTx(transaction.serialize());
        //console.log(serializedTransaction);
        // send serializedtx to raptoracle for processing!
        setConfirmModalShow(true);
      }
    } catch (e) {
      console.log(e);
      provideNotyf("Unable to create this transaction", "danger");
      setPrivKey(false);
      return false;
    }
  }, [
    provideNotyf,
    changeWallet,
    recipientWallet,
    sendAmount,
    sendNote,
    privKey,
    asFuture,
    ftMaturity,
    ftLocktime,
    normalFee,
    specialFee,
    utxos,
  ]);

  const handleStartTx = React.useCallback(() => {
    if (!recipientWallet) {
      //alert("Cant do something with nothing!");
      return provideNotyf("Fill in the recipient field!", "danger");
    }

    if (!sendWallets) {
      //alert("Cant do something with nothing!");
      return provideNotyf("Portfolio wallet not set!", "danger");
    }

    if (!sendAmount || sendAmount === 0) {
      //alert("Cant do something with nothing!");
      return provideNotyf("Fill in the amount field!", "danger");
    }

    //if (!isFeeCovered()) {
    //  return provideNotyf("Not enough to cover fees!", "danger");
    //}
    if (!utxos) {
      return getUtxos();
    }

    if (asFuture) {
      if (ftMaturity <= 0) {
        return provideNotyf(
          `Incorrect maturity confirmations! Should be positive number`,
          "danger"
        );
      }
      if (ftMaturity <= 2) {
        return provideNotyf(
          `This future transaction will mature in ${ftMaturity} blocks. Make sure this is correct!`,
          "warning"
        );
      }
    }
    if (!privKey) {
      return setPassModalShow(true);
    }
    return prepareTx();
  }, [
    asFuture,
    ftMaturity,
    recipientWallet,
    prepareTx,
    utxos,
    getUtxos,
    privKey,
    sendWallets,
    sendAmount,
    provideNotyf,
  ]);

  const fetchFees = React.useCallback(() => {
    const params = {
      service: "fees",
      method: "getFees",
    };

    const serverData = getData(params);

    serverData
      .then((data) => {
        if (data.status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setNormalFee(payload.fee);
            setSpecialFee(payload.specialfee);
          }
        } else {
          throw new Error("Could not get current fees, using defaults...");
        }
      })
      .catch((err) => {
        provideNotyf(err, "warning");
        setNormalFee(0.00001504);
        setSpecialFee(100);
      });
  }, [setNormalFee, setSpecialFee, provideNotyf]);

  const fetchCurrentPrice = React.useCallback(() => {
    const params = {
      service: "market",
      method: "getRecentPrice",
    };

    const serverData = getData(params);

    serverData.then((data) => {
      const status = data.status;
      if (status === "success") {
        if (typeof data[params.method].results !== "undefined") {
          const payload = data[params.method].results;
          setCurrentPrice(payload.current_price);
        }
      }
    });
  }, []);

  const handlePass = (pass) => {
    raptorio.getPrivateKeyForSigning(pass, sendWallets).then((res) => {
      setPrivKey(res);
    });
  };

  const handleRecipientChange = (e) => {
    setRecipientWallet(e.target.value);
  };

  /*
  const triggerUtxoLoad = (e) => {
    if (e.target.value > 0 && e.target.value <= balances.total_balance) {
      getUtxos();
    } else {
      setUtxos(false);
    }
  };
  */
  const handleNoteChange = (e) => {
    const byteLen = new TextEncoder().encode(e.target.value).length;
    if (byteLen >= 80) {
      provideNotyf("Note limited to 80 bytes", "warning");
    }
    setSendNote(e.target.value);
  };

  const handleProcessTx = () => {
    // alert("sending tx");
    const params = {
      service: "relay",
      method: "sendTx",
      data: { tx_hex: rawTx },
    };

    const sendTx = postData(params);

    sendTx.then((res) => {
      if (res.status === "success") {
        if (typeof res[params.method].results !== "undefined") {
          const payload = res[params.method].results;
          if (!!payload.tx) {
            setSentTx(payload.tx);
            setSuccessModalShow(true);
          }
        }
      } else {
        //alert("nope");
        //console.log("nope");
        provideNotyf("Problem sending TX", "danger");
      }
    });

    // handleReset();
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const handleFutureDate = (value) => {
    const startNow = dayjs();
    const matureOn = dayjs(value);
    const diff = matureOn.diff(startNow, "seconds");
    setFtLocktime(diff);
    const avgBT = 120;
    const expectedConfirmations = Math.ceil(diff / avgBT);
    setFtMaturity(expectedConfirmations);
    setFutureStartDate(value);
    document.getElementById("estConfirms").value = expectedConfirmations;
  };

  const handleFutureMaturity = (e) => {
    let targetConfirmations = e.target.value;
    setFtMaturity(targetConfirmations);
    const avgBT = 120;
    const expectedSeconds = Math.floor(targetConfirmations * avgBT);
    setFtLocktime(expectedSeconds);
    const startNow = dayjs();
    const matureOn = startNow.add(expectedSeconds, "seconds").toDate();
    setFutureStartDate(matureOn);
  };

  /*
  const calcSelectedVal = () => {
    let selectedVal = 0;
    if (!!selectedTx) {
      selectedTx.map((tx) => {
        return (selectedVal += tx.satoshis);
      });
      selectedVal = selectedVal / 1e8;
    }

    return selectedVal;
  };
  */

  React.useEffect(() => {
    if (!!privKey) {
      handleStartTx();
    }
  }, [privKey, handleStartTx]);

  React.useEffect(() => {
    if (sendWallets && sendAmount > 0) {
      if (!asFuture || asFuture) {
        getUtxos();
      }
    }
  }, [asFuture, getUtxos, sendWallets, sendAmount]);

  React.useEffect(() => {
    if (normalFee === 0) {
      fetchFees();
    }
    if (currentPrice === 0) {
      fetchCurrentPrice();
    }
  }, [normalFee, fetchFees, currentPrice, fetchCurrentPrice]);

  const popover = (
    <Popover id="future-info">
      <Popover.Header as="h3" className="text-info p-1">
        <Info className="me-2" /> Future Transactions
      </Popover.Header>
      <Popover.Body>
        Due to variable block times, this transaction may be unlocked before or
        after your target time. <br />
        Maturity confirmations is estimated by an average block time of 2
        minutes.
      </Popover.Body>
    </Popover>
  );

  const CustomDatepickerInput = React.forwardRef(({ value, onClick }, ref) => (
    <Form.Control
      size="sm"
      className="custom-datepicker-input"
      onClick={onClick}
      ref={ref}
      autoComplete="off"
      inputMode="none"
      value={value}
      readOnly
    />
  ));

  return (
    <React.Fragment>
      <Form>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId="formPlaintextRecipient"
        >
          <Form.Label column sm="2">
            Recipient
          </Form.Label>
          <Col sm="10">
            <InputGroup className="mb-3">
              <Form.Control
                placeholder={`RTM Address ie (${process.env.REACT_APP_TIPJAR})`}
                aria-label="Recipient's Address"
                aria-describedby="basic-addon2"
                size="sm"
                onChange={handleRecipientChange}
                autoComplete="off"
              />
              <Button
                variant="primary"
                id="addressbookButton"
                onClick={handleAddressBookShow}
              >
                <Book size={16} />
              </Button>
            </InputGroup>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formSendAmounts">
          <Form.Label column sm="2">
            Amount
          </Form.Label>
          <Col xxl="3">
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                inputMode="decimal"
                placeholder="0.0000"
                min="0"
                step="0.0001"
                disabled={!balances.total_balance > 0}
                onChange={(e) => setSendAmount(e.target.value)}
                value={sendAmount > 0 ? parseFloat(sendAmount) : ""}
                pattern="([0-9]{1,3}).([0-9]{1,3})"
              />
              <DropdownButton
                variant="outline-secondary"
                title="RTM"
                id="input-group-dropdown-2"
                align="end"
              >
                <Dropdown.Item href="#">RTM</Dropdown.Item>
                <Dropdown.Item href="#">mRTM</Dropdown.Item>
                <Dropdown.Item href="#">µRTM</Dropdown.Item>
              </DropdownButton>
              <Button
                onClick={() => {
                  setSendAmount(parseFloat(balances.total_balance));
                  setIsCoinControl(false);
                }}
                variant="primary"
                size="sm"
              >
                Use max
              </Button>
            </InputGroup>
          </Col>
          <Col xxl="1" className="text-center">
            <div className="d-none d-xxl-inline">
              <ChevronsLeft />
              <ChevronsRight />
            </div>
            <div className="d-xxl-none mb-3">
              <ChevronsUp />
              <ChevronsDown />
            </div>
          </Col>
          <Col xxl="3">
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                inputMode="decimal"
                placeholder="0.00"
                min="0.01"
                step="0.01"
                disabled={!balances.total_balance > 0}
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                onChange={(e) =>
                  setSendAmount(parseFloat(e.target.value / currentPrice))
                }
                value={
                  sendAmount > 0 ? parseFloat(sendAmount * currentPrice) : ""
                }
              />
              <Button variant="outline-secondary" id="button-addon2">
                USD
              </Button>
            </InputGroup>
          </Col>
        </Form.Group>
        <Row className="mt-0 mb-3">
          <Col xxl={{ span: 10, offset: 2 }} className="text-muted">
            <Form.Group className="mb-3" controlId="futureTxCheckbox">
              <Form.Check
                type="switch"
                label="Send as Future Transaction"
                onChange={(e) => setAsFuture(e.target.checked)}
                className="d-inline-block align-middle"
              />
              <OverlayTrigger
                trigger="click"
                placement="top"
                overlay={popover}
                rootClose
              >
                <Button
                  variant="link"
                  size="sm"
                  className="text-info d-inline-block p-0 ms-2"
                >
                  <Info size={16} />
                </Button>
              </OverlayTrigger>
            </Form.Group>
            {!!asFuture && (
              <React.Fragment>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3 position-relative"
                      controlId="futureTxDatetime"
                    >
                      <Form.Label>Target Maturity: </Form.Label>
                      <DatePicker
                        selected={futureStartDate}
                        inputMode="none"
                        onChange={(date) => handleFutureDate(date)}
                        minDate={new Date()}
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeInput
                        timeIntervals={15}
                        filterTime={filterPassedTime}
                        showMonthDropdown
                        useShortMonthInDropdown
                        showYearDropdown
                        todayButton="Today"
                        withPortal
                        customInput={<CustomDatepickerInput />}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Label>Maturity Confirmations: </Form.Label>
                    <Form.Control
                      type="number"
                      inputMode="numeric"
                      id="estConfirms"
                      aria-describedby="estConfirms"
                      defaultValue={ftMaturity}
                      onChange={(e) => handleFutureMaturity(e)}
                      min={1}
                      size="sm"
                    />
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </Col>
        </Row>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Send From
          </Form.Label>
          <Col sm="10">
            <InputGroup>
              <Form.Control
                id="sendFrom"
                type="text"
                value={isCoinControl ? "Coin Control" : "My Portfolio"}
                size="sm"
                disabled={true}
              />
              <Button
                variant="primary"
                id="coinControlButton"
                onClick={handleCoinControlShow}
              >
                <Filter size={14} />
              </Button>
            </InputGroup>
          </Col>
        </Form.Group>
        <Row>
          <Col>
            <Form.Group as={Row} className="mb-3" controlId="sendNote">
              <Form.Label column sm="2">
                Note
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  type="text"
                  size="sm"
                  onChange={handleNoteChange}
                  maxLength="80"
                  autoComplete="off"
                />
              </Col>
              <Col sm="5">
                <span className="text-muted small">Limit of 80 characters</span>
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={2}>Fees</Col>
          <Col sm={3} className="text-muted">
            <Table size="sm" borderless className="small">
              <tbody>
                <tr>
                  <td className="p-1">
                    <span>Available balance:</span>
                  </td>
                  <td className="text-end p-1">
                    <span className="font-monospace small">
                      <NumericFormat
                        value={balances.total_balance}
                        displayType={"text"}
                        thousandSeparator={true}
                        defaultValue={0}
                        decimalScale={8}
                        fixedDecimalScale={true}
                      />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="p-1">
                    <span>Network Fee:</span>
                  </td>
                  <td className="text-end p-1">
                    <span className="font-monospace small">
                      <NumericFormat
                        value={normalFee}
                        displayType={"text"}
                        thousandSeparator={true}
                        defaultValue={0}
                        decimalScale={8}
                        fixedDecimalScale={true}
                      />
                    </span>
                  </td>
                </tr>
                {asFuture && (
                  <tr>
                    <td className="p-1">
                      <span>Special Tx Fee:</span>
                    </td>
                    <td className="text-end p-1">
                      <span className="text-warning font-monospace small">
                        <NumericFormat
                          value={specialFee}
                          displayType={"text"}
                          thousandSeparator={true}
                          defaultValue={0}
                          decimalScale={8}
                          fixedDecimalScale={true}
                        />
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mt-4 text-center">
          <Col>
            <Button
              variant="primary"
              size="lg"
              onClick={() => handleStartTx()}
              disabled={
                !sendAmount > 0 ||
                !recipientWallet ||
                (asFuture && ftMaturity < 1)
              }
            >
              Continue
            </Button>
          </Col>
        </Row>
      </Form>

      <CoinControl
        addresses={addresses}
        showCoinControl={showCoinControl}
        setShowCoinControl={setShowCoinControl}
        handleCoinControlClose={handleCoinControlClose}
        handleCoinControlSelect={handleCoinControlSelect}
      />

      <PassModal
        passModalShow={passModalShow}
        setPassModalShow={setPassModalShow}
        handleAction={handlePass}
      />
      {!!selectedWallet && !!recipientWallet && !!sendAmount && (
        <ConfirmModal
          confirmModalShow={confirmModalShow}
          handleConfirmModalClose={handleConfirmModalClose}
          handleAction={handleProcessTx}
          rawData={txJsonData}
          rawFee={rawTxFee}
          rawTx={rawTx}
          summary={{
            portfolio: portfolio,
            recipient: recipientWallet,
            sender: selectedWallet,
            sendWallets: sendWallets,
            amount: sendAmount,
            note: sendNote,
            addressLabels: addressLabels,
            addressbook: addressBook,
          }}
          futures={{
            isFuture: asFuture,
            maturity: ftMaturity,
            locktime: ftLocktime,
            estDate: futureStartDate,
          }}
        />
      )}
      <SuccessModal
        successModalShow={successModalShow}
        handleSuccessModalClose={handleSuccessModalClose}
        sentTx={sentTx}
      />
      <AddressBookModal
        raptorio={raptorio}
        showAddressBook={showAddressBook}
        handleAddressBookClose={handleAddressBookClose}
      />
    </React.Fragment>
  );
};

export default Send;
