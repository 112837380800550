import React from "react";
import {
  Box,
  ChevronRight,
  CreditCard,
  DollarSign,
  Settings,
  Target,
} from "react-feather";
import { Link } from "react-router-dom";
import { truncate } from "../utils/Formatters";
import { NumericFormat } from "react-number-format";

export const SettingsBreadcrumbs = ({ tabname }) => {
  return (
    <h1 className="mb-3 fs-4 fw-light">
      <Settings size={18} className="align-middle" />
      <ChevronRight size={16} className="align-middle" />
      <span className="align-middle fw-bold">{tabname}</span>
    </h1>
  );
};

export const PortfolioBreadcrumbs = ({ tabname }) => {
  return (
    <h1 className="mb-3 fs-4 fw-light">
      <Target size={18} className="align-middle" />
      <ChevronRight size={16} className="align-middle" />
      <span className="align-middle fw-bold">{tabname}</span>
    </h1>
  );
};

export const WalletBreadcrumbs = ({ tabname }) => {
  return (
    <h1 className="mb-3 fs-4 fw-light">
      <CreditCard size={18} className="align-middle" />
      <ChevronRight size={16} className="align-middle" />
      <span className="fw-bold align-middle">{tabname}</span>
    </h1>
  );
};

export const MarketBreadcrumbs = ({ tabname }) => {
  return (
    <h1 className="mb-3 fs-4 fw-light">
      <DollarSign size={18} className="align-middle text-center" />
      <ChevronRight size={16} className="align-middle" />
      <span className="fw-bold align-middle">{tabname}</span>
    </h1>
  );
};

export const ExplorerBreadcrumbs = ({ tabname }) => {
  return (
    <h1 className="mb-3 fs-4 fw-light">
      <Link
        to={"/explorer/dashboard"}
        className="text-reset align-middle text-center"
      >
        <Box size={20} className="align-middle" />
      </Link>
      <ChevronRight size={16} className="align-middle" />
      <span className="fw-bold align-middle">{tabname}</span>
    </h1>
  );
};

export const ExplorerSubBreadcrumbs = ({
  tabname,
  subname,
  link,
  truncatesub,
  subnum,
}) => {
  return (
    <h1 className="mb-3 fs-4 fw-light">
      <span>
        <Link to={"/explorer/dashboard"} className="text-reset align-middle">
          <Box size={20} className="align-middle" />
        </Link>
        <ChevronRight size={16} className="align-middle" />
        <Link to={"/explorer/" + link} className="text-reset align-middle">
          {tabname}
        </Link>
        <ChevronRight size={16} className="align-middle" />
        {!!truncatesub ? (
          <React.Fragment>
            <span className="d-none d-xl-inline-block fw-bold align-middle">
              {subname}
            </span>
            <span className="d-xl-none fw-bold align-middle">
              {truncate(subname)}
            </span>
          </React.Fragment>
        ) : (
          <span className="fw-bold align-middle">
            {!!subnum ? (
              <NumericFormat
                value={subname}
                displayType={"text"}
                thousandSeparator={true}
              />
            ) : (
              subname
            )}
          </span>
        )}
      </span>
    </h1>
  );
};
