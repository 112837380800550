import React from "react";
import { Button, Card, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
//get our table component
import EntryTable from "./components/EntryTable";

const Apis = ({ name, category, raptorio }) => {
  const addMethods = [{ name: "Add One", value: "1" }];
  const addFields = [
    {
      name: "api",
      placeholder: "API Key",
      type: "text",
      required: true,
      autofocus: true,
    },
    { name: "label", placeholder: "Label", type: "text", required: false },
    {
      name: "service",
      placeholder: "Service",
      value: "iNodez",
      type: "text",
      disabled: true,
    },
  ];
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">
        <FontAwesomeIcon
          icon={faInfoCircle}
          className="text-info me-2"
          fixedWidth
        />
        {name}
      </Popover.Header>
      <Popover.Body>
        <div>
          {`Service API from iNodez can be added to your ${process.env.REACT_APP_NAME} portfolio.`}
          <hr />
          <span className="text-warning">
            {`This is for including the balance of your iNodez shared node service account. `}
            <br />
            {`Currently only supports iNodez.`}
          </span>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <Card key={category + "-card"} className="shadow-lg">
      <Card.Header>
        <div className="float-end">
          <OverlayTrigger
            trigger="click"
            placement="left"
            overlay={popover}
            rootClose={true}
          >
            <Button variant="light" size="sm">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="text-info"
                fixedWidth
              />
            </Button>
          </OverlayTrigger>
        </div>
        <Card.Title tag="h5" className="mb-0">
          {name}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <EntryTable
          name={name}
          category={category}
          addFields={addFields}
          addMethods={addMethods}
          raptorio={raptorio}
        />
      </Card.Body>
    </Card>
  );
};

export default Apis;
