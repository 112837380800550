import React from "react";
import { Card, Spinner } from "react-bootstrap";
import { getData } from "../../../service/Service";
import { FormatChainwork } from "../../../utils/Formatters";

const ChainworkCard = ({ reload }) => {
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const fetchData = React.useCallback(() => {
    const params = {
      service: "statistics",
      method: "chainwork",
    };

    setLoading(true);

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setData(payload);
          }
        } else {
          console.log(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  return (
    <Card className="flex-grow-1 align-self-stretch shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Chainwork {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body className="fs-2">
        {!!data && (
          <React.Fragment>
            <FormatChainwork chwork={String(data.chainwork)} />
            <br />
            <span className="text-secondary fs-6">{data.chainwork}</span>
          </React.Fragment>
        )}
      </Card.Body>
    </Card>
  );
};

export default ChainworkCard;
