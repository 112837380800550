import React from "react";
import { Link } from "react-router-dom";
import { Card, OverlayTrigger, Spinner, Table, Tooltip } from "react-bootstrap";
import { getData } from "../../../service/Service";
import { truncate } from "../../../utils/Formatters";
import { useTranslation } from "react-i18next";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var LocalizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const TransactionList = ({ reload }) => {
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { t } = useTranslation();

  const fetchData = React.useCallback(() => {
    const params = {
      service: "explorer",
      method: "gettransactionlist",
    };

    setLoading(true);

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setData(payload);
          }
        } else {
          console.log(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  return (
    <Card className="flex-grow-1 align-self-stretch shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {t("Latest Transactions")}{" "}
          {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table hover size="sm">
          <tbody>
            {!!data &&
              data.map((tx) => {
                return (
                  <tr key={"latest_t_" + tx.txid}>
                    <td>
                      <Link
                        to={"/explorer/tx/" + tx.txid}
                        className="cursor-zoom-in font-monospace"
                      >
                        <span className="d-none d-xl-inline-block">
                          {tx.txid}
                        </span>
                        <span className="d-xl-none">
                          {truncate(tx.txid, 12)}
                        </span>
                      </Link>
                      <span className="small d-inline-block float-end">
                        <OverlayTrigger
                          key={"tx-tooltip" + tx.txid}
                          placement="top"
                          overlay={
                            <Tooltip id={"tx-tooltip" + tx.txid}>
                              {tx.time_format}
                            </Tooltip>
                          }
                        >
                          <span className="user-select-none">
                            {dayjs.unix(tx.timestamp).fromNow()}
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer>
        <Link
          to={"/explorer/transactions"}
          className="btn btn-info btn-sm float-end"
        >
          View All
        </Link>
      </Card.Footer>
    </Card>
  );
};

export default TransactionList;
