import React, { useState } from "react";
import {
  Badge,
  Card,
  OverlayTrigger,
  Spinner,
  Popover,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { getData } from "../../../service/Service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var LocalizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const RecentTxCard = ({ raptorio, reload }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);

  const fetchData = React.useCallback(() => {
    // Set the loading state
    setLoading(true);

    const getAddresses = raptorio.getAddresses();

    getAddresses
      .then((data) => {
        let walletAddresses = [];
        data.map((wallet) => {
          return walletAddresses.push(wallet.address);
        });
        return {
          addresses: data,
          walletAddresses: walletAddresses,
        };
      })
      .then((walletData) => {
        const params = {
          service: "explorer",
          method: "getRecentAddressTx",
          address: walletData.walletAddresses.join(","),
        };

        const serverData = getData(params);

        serverData
          .then((data) => {
            const status = data.status;
            if (status === "success") {
              if (typeof data[params.method].results !== "undefined") {
                const payload = data[params.method].results;
                let merged = {
                  ...payload,
                  ...walletData.addresses.find(
                    (itmInner) => payload.address === itmInner.address
                  ),
                };

                setData(merged);
              }
            }
          })
          .finally(() => {
            setLoading(false);
          });
      });
  }, [raptorio]);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  const determineStatusClass = (status) => {
    switch (status) {
      case "Incoming":
        return "success";
      case "Outgoing":
        return "warning";
      case "Pending":
        return "danger";
      default:
        return "";
    }
  };

  return (
    <Card className="flex-grow-1 align-self-stretch shadow-lg">
      <Card.Header>
        {data && (
          <div className="float-end">
            <Badge bg={determineStatusClass(data.status)}>{data.status}</Badge>
          </div>
        )}
        <Card.Title tag="h5" className="mb-0">
          Latest Transaction{" "}
          {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {(data && (
          <React.Fragment>
            <div className="d-inline-block fs-1 me-2">
              <OverlayTrigger
                key="lasttx-tooltip"
                placement="top"
                overlay={
                  <Tooltip id={`lasttx-tooltip-top`}>{data.balance}</Tooltip>
                }
              >
                <span className="text-dark">
                  <NumericFormat
                    value={data.balance}
                    defaultValue={0}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </span>
              </OverlayTrigger>
            </div>
            <div className="d-inline-block">
              {data.spent === false ? "" : "("}$
              <NumericFormat
                value={data.priceValue}
                defaultValue={0}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                thousandSeparator={true}
              />
              {data.spent === false ? "" : ")"}
            </div>
            <div className="d-block mb-2">
              {data.spent === false ? (
                <React.Fragment>
                  <FontAwesomeIcon
                    icon={faArrowRightToBracket}
                    fixedWidth
                    className="align-middle text-dark"
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    fixedWidth
                    className="align-middle text-dark"
                  />
                </React.Fragment>
              )}{" "}
              <Link
                to={"/explorer/address/" + data.address}
                className="font-monospace align-middle"
              >
                {data.label && data.label.length > 0 ? (
                  <OverlayTrigger
                    key="lasttx-addr-tooltip"
                    placement="top"
                    overlay={
                      <Popover id={`lasttx-addr-tooltip-top`}>
                        <Popover.Header as="h3">{data.label}</Popover.Header>
                        <Popover.Body>{data.address}</Popover.Body>
                      </Popover>
                    }
                  >
                    <span className="align-middle badge-soft-primary badge">
                      {data.label}
                    </span>
                  </OverlayTrigger>
                ) : (
                  <span className="align-middle badge-soft-primary badge">
                    {data.address}
                  </span>
                )}
              </Link>
            </div>
            <div className="d-block small">
              <span
                className={
                  data.confirmations >= 6
                    ? "text-success fw-bold"
                    : "text-warning"
                }
              >
                {data.confirmations}
              </span>{" "}
              confirmations
            </div>
          </React.Fragment>
        )) ||
          "No transactions on this portfolio!"}
      </Card.Body>
      <Card.Footer>
        {data && (
          <React.Fragment>
            {data.status !== "Pending" ? (
              <div className="float-start small">
                {data.spent === false ? (
                  <Link
                    to={"/explorer/tx/" + data.lasttx + "#output-" + data.index}
                  >
                    View Transaction
                  </Link>
                ) : (
                  <Link
                    to={"/explorer/tx/" + data.lasttx + "#input-" + data.index}
                  >
                    View Transaction
                  </Link>
                )}
              </div>
            ) : (
              <div className="float-start small">Pending Transaction</div>
            )}
            <div className="text-secondary small float-end">
              {dayjs.unix(data.updated_at).fromNow()}
            </div>
          </React.Fragment>
        )}
      </Card.Footer>
    </Card>
  );
};

export default RecentTxCard;
