import React from "react";
import { Button, Card, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
//get our table component
import PortfolioTable from "./components/PortfolioTable";

const Portfolios = ({ name, category, raptorio }) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">
        <FontAwesomeIcon
          icon={faInfoCircle}
          className="text-info me-2"
          fixedWidth
        />
        {name}
      </Popover.Header>
      <Popover.Body>
        <div>
          {`${process.env.REACT_APP_NAME} portfolios track groups of addresses and creates a wallet with private key.`}
          <hr />
          <span className="text-warning">
            {`It is imperitive that you maintain control of your created private keys
        to access funds!`}
          </span>
          <hr />
          <span className="text-danger fw-bold">
            {`Document your keys in a safe physical location!`}
          </span>
          <br />
          <span className="text-danger small">
            {`${process.env.REACT_APP_NAME} is not a guardian of your crypto and cannot be responsible for bad practice.`}
          </span>
          <br />
          <span className="text-danger">
            <em>{`You have been warned!`}</em>
          </span>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <Card key={category + "-card"} className="shadow-lg">
      <Card.Header>
        <div className="float-end">
          <OverlayTrigger
            trigger="click"
            placement="left"
            overlay={popover}
            rootClose={true}
          >
            <Button variant="light" size="sm">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="text-info"
                fixedWidth
              />
            </Button>
          </OverlayTrigger>
        </div>
        <Card.Title tag="h5" className="mb-0">
          {name}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <PortfolioTable name={name} category={category} raptorio={raptorio} />
      </Card.Body>
    </Card>
  );
};

export default Portfolios;
