import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { isMobile } from "react-device-detect";
import { useMediaQuery } from "react-responsive";
import { SettingsBreadcrumbs } from "../../../components/Breadcrumbs";
import {
  Card,
  Col,
  Container,
  Dropdown,
  Fade,
  ListGroup,
  Row,
  Tab,
} from "react-bootstrap";
import Portfolios from "./Portfolios";
import Addresses from "./Addresses";
import CustomAmount from "./CustomAmount";
import Smartnodes from "./Smartnodes";
import Apis from "./Apis";
import Raptorio from "../../../service/Raptorio";

const raptorio = new Raptorio({ coin: "RTM" });

const navItems = [
  {
    key: "portfolios",
    name: "Portfolios",
    tooltip: "Portfolios",
  },
  {
    key: "addresses",
    name: "Addresses",
    tooltip: "Addresses",
  },
  {
    key: "watched",
    name: "Watched",
    tooltip: "Watched",
  },
  {
    key: "addressbook",
    name: "Address Book",
    tooltip: "Address Book",
  },
  {
    key: "apis",
    name: "Service APIs",
    tooltip: "Service APIs",
  },
  {
    key: "smartnodes",
    name: "Smartnodes",
    tooltip: "Smartnodes",
  },
];

const Navigation = ({ setTab, setTabname }) => {
  const [key, setKey] = useState("portfolios");
  const [name, setName] = useState("Portfolios");
  const isSmallScreen = useMediaQuery({ query: "(max-width: 575px)" });
  if (isMobile || isSmallScreen) {
    return (
      <Dropdown
        className="dropdown-block"
        onSelect={(k) => {
          setKey(k);
          setTab(k);
        }}
      >
        <Dropdown.Toggle>{name}</Dropdown.Toggle>
        <Dropdown.Menu>
          {navItems.map((item) => {
            return (
              <Dropdown.Item
                key={`dd-${item.key}`}
                eventKey={item.key}
                active={key === item.key}
                name={item.name}
                onClick={() => {
                  setName(item.name);
                  setTabname(item.name);
                }}
              >
                {item.name}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
  return (
    <Card className="shadow-lg">
      <ListGroup
        variant="flush"
        onSelect={(k) => {
          setKey(k);
          setTab(k);
        }}
      >
        {navItems.map((item) => {
          return (
            <ListGroup.Item
              key={`lg-${item.key}`}
              tag="a"
              eventKey={item.key}
              action
              active={key === item.key}
              onClick={() => {
                setName(item.name);
                setTabname(item.name);
              }}
            >
              {item.name}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </Card>
  );
};

const PortfolioSettings = () => {
  const [tab, setTab] = useState("portfolios");
  const [tabname, setTabname] = useState("Portfolios");
  return (
    <React.Fragment>
      <Helmet title="Portfolio Settings" />
      <Container fluid className="p-0">
        <SettingsBreadcrumbs tabname={tabname} />
        <Tab.Container
          id="left-tabs"
          defaultActiveKey="portfolios"
          transition={Fade}
        >
          <Row>
            <Col sm={4} lg={3} xl={2} className="text-nowrap">
              <Navigation setTab={setTab} setTabname={setTabname} />
            </Col>
            <Col sm={8} lg={9} xl={10}>
              <Tab.Content>
                <Tab.Pane eventKey="portfolios">
                  <Portfolios
                    key="portfolios"
                    category="portfolio"
                    name="Portfolios"
                    raptorio={raptorio}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="addresses">
                  {!!tab && tab === "addresses" && (
                    <Addresses
                      key="native-addresses"
                      category="native"
                      name="Addresses"
                      raptorio={raptorio}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="watched">
                  {!!tab && tab === "watched" && (
                    <Addresses
                      key="watched-addresses"
                      category="watched"
                      name="Watched Addresses"
                      raptorio={raptorio}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="addressbook">
                  {!!tab && tab === "addressbook" && (
                    <Addresses
                      key="addressbook-addresses"
                      category={tab}
                      name="Address Book"
                      raptorio={raptorio}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="apis">
                  {!!tab && tab === "apis" && (
                    <Apis
                      key="apis"
                      category={tab}
                      name="Service APIs"
                      raptorio={raptorio}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="customs">
                  {!!tab && tab === "customs" && (
                    <CustomAmount
                      key="customs"
                      category={tab}
                      name="Custom Amounts"
                      raptorio={raptorio}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="smartnodes">
                  {!!tab && tab === "smartnodes" && (
                    <Smartnodes
                      key="smartnodes"
                      category={tab}
                      name="Smartnodes"
                      raptorio={raptorio}
                    />
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </React.Fragment>
  );
};

export default PortfolioSettings;
