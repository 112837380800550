import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import RefreshButtons from "../../components/live/RefreshTimer";
import { PortfolioBreadcrumbs } from "../../components/Breadcrumbs";
import BalanceCard from "./components/BalanceCard";
import AddressesListCard from "./components/AddressesListCard";
import Raptorio from "../../service/Raptorio";
import RecentTxCard from "./components/RecentTxCard";
import ServiceApiCard from "./components/ServiceApiCard";
import BalanceChartCard from "./components/BalanceChartCard";
import AllocationCard from "./components/AllocationCard";
import NewUserCard from "./components/NewUserCard";
import AddressTxCard from "../explorer/components/AddressTxCard";
const raptorio = new Raptorio({ coin: "RTM" });

const Portfolio = () => {
  const [loading, setLoading] = useState(true);
  const [portfolio, setPortfolio] = useState(false);
  const [portfolioAddresses, setPortfolioAddresses] = React.useState(false);

  const fetchData = React.useCallback(async () => {
    // Set the loading state
    setLoading(true);

    await raptorio
      .getDefaultPortfolio()
      .then((data) => {
        if (data && data.length === 1) {
          setPortfolio(data[0]);
        }
      })
      .then(async () => {
        await raptorio
          .getAddresses()
          .then((wallets) => {
            let walletAddresses = [];
            //setWallets(data);
            wallets.map((wallet) => {
              return walletAddresses.push(wallet.address);
            });
            setPortfolioAddresses(walletAddresses);
          })
          .then(() => {
            setLoading(false);
          });
      });
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <React.Fragment>
      {portfolio ? (
        <Helmet title={portfolio.label} />
      ) : (
        <Helmet title="Portfolio" />
      )}

      <Container fluid className="p-0">
        {!!portfolio ? (
          <React.Fragment>
            <Row>
              <Col xs={8}>
                <PortfolioBreadcrumbs tabname={portfolio.label} />
              </Col>
              <Col className="text-end">
                <RefreshButtons loading={loading} callback={fetchData} />
              </Col>
            </Row>
            <Row xs={1} md={2}>
              <Col className="d-flex">
                <BalanceCard raptorio={raptorio} reload={loading} />
              </Col>
              <Col className="d-flex">
                <RecentTxCard raptorio={raptorio} reload={loading} />
              </Col>
            </Row>
            <Row xs={1} md={2}>
              <Col className="d-flex">
                <BalanceChartCard raptorio={raptorio} reload={loading} />
              </Col>
              <Col className="d-flex">
                <AllocationCard raptorio={raptorio} reload={loading} />
              </Col>
            </Row>
            <Row>
              <Col>
                <AddressesListCard raptorio={raptorio} reload={loading} />
              </Col>
            </Row>
            <Row>
              <Col>
                {!!portfolioAddresses && (
                  <AddressTxCard
                    address={portfolioAddresses.join(",")}
                    reload={loading}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <ServiceApiCard raptorio={raptorio} reload={loading} />
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          !loading && (
            <Row sm="auto" className="justify-content-md-center">
              <Col>
                <NewUserCard />
              </Col>
            </Row>
          )
        )}
      </Container>
    </React.Fragment>
  );
};

export default Portfolio;
