import React from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import DataTable from "../../components/tables/DataTable";
import { ExplorerBreadcrumbs } from "../../components/Breadcrumbs";
import { getData } from "../../service/Service";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var updateLocale = require("dayjs/plugin/updateLocale");
var utc = require("dayjs/plugin/utc");
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "1 minute",
    mm: "%d minutes",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day",
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years",
  },
});

const htmlDecode = (input) => {
  var e = document.createElement("div");
  e.innerHTML = input;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
};

const Messages = () => {
  // We'll start our table without any data
  const [loading, setLoading] = React.useState(false);
  const defaultSortCol = React.useRef("block_height");
  const service = React.useRef("explorer");
  const method = React.useRef("getOpMessages");

  const MessageListCols = React.useMemo(
    () => [
      {
        id: "op_return",
        cell: (info) => info.getValue(),
        header: () => <span>Message</span>,
        footer: (props) => props.column.id,
        accessorFn: (row) => {
          return (
            <div
              className="op-message text-wrap"
              dangerouslySetInnerHTML={{ __html: htmlDecode(row.op_return) }}
            />
          );
        },
      },
      {
        id: "block_height",
        cell: (info) => info.getValue(),
        header: () => <span>Height</span>,
        footer: (props) => props.column.id,
        accessorFn: (row) => {
          return (
            <Link
              to={"/explorer/block/" + row.block_height}
              className="cursor-zoom-in"
            >
              <NumericFormat
                value={row.block_height}
                displayType={"text"}
                thousandSeparator={true}
              />
            </Link>
          );
        },
      },
      {
        id: "hash_this_tx",
        cell: (info) => info.getValue(),
        header: () => <span>TX ID</span>,
        footer: (props) => props.column.id,
        accessorFn: (row) => {
          return (
            <Link to={"/explorer/tx/" + row.txid} className="cursor-zoom-in">
              {row.txid_truncate}
            </Link>
          );
        },
      },
      {
        id: "n_time",
        cell: (info) => info.getValue(),
        header: () => <span>Created</span>,
        footer: (props) => props.column.id,
        accessorFn: (row) => {
          return (
            <OverlayTrigger
              key={`created-${row.block_height}`}
              placement="left"
              delay={{ show: 500, hide: 100 }}
              overlay={
                <Tooltip id="tooltip-left">{row.created_at_format}</Tooltip>
              }
            >
              <span>{dayjs.unix(row.created_at).fromNow()}</span>
            </OverlayTrigger>
          );
        },
      },
    ],
    []
  );

  const fetchData = React.useCallback(
    ({ pageIndex, pageSize, sorting, globalFilter }) => {
      // Set the loading state
      setLoading(true);

      const sortBy = sorting[0]?.id ?? defaultSortCol.current;
      const sortDir =
        sorting[0]?.id !== undefined
          ? sorting[0]?.desc === true
            ? "DESC"
            : "ASC"
          : "DESC";

      const params = {
        service: service.current,
        method: method.current,
        page: pageIndex,
        limit: pageSize,
        dir: sortDir,
        sort: sortBy,
        filter: globalFilter,
      };

      const serverData = getData(params)
        .then((data) => {
          const status = data.status;
          if (status === "success") {
            if (typeof data[params.method].results !== "undefined") {
              const payload = data[params.method].results;
              const count = parseInt(data[params.method].summary.pages);

              return { rows: payload, pageCount: count };
              //setPageCount(parseInt(data[params.method].summary.pages));
            }
          } else {
            throw new Error("didnt get data");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .then((data) => {
          setLoading(false);
          return data;
        });
      return serverData;
    },
    []
  );

  return (
    <React.Fragment>
      <Helmet title="Messages" />
      <Container fluid className="p-0">
        <ExplorerBreadcrumbs tabname="Messages" />
        <Row>
          <Col>
            <DataTable
              queryKey={method.current}
              columns={MessageListCols}
              fetchData={fetchData}
              loading={loading}
              placeholderText="Search (by message text)"
              defaultSortCol={defaultSortCol.current}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Messages;
