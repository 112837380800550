import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Card, Spinner, Table } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { getData } from "../../../service/Service";
import { truncate } from "../../../utils/Formatters";

const AddressesListCard = ({ raptorio, reload }) => {
  const [loading, setLoading] = useState(true);
  const [balances, setBalances] = useState([]);
  const [data, setData] = useState([]);

  const fetchData = React.useCallback(() => {
    // Set the loading state
    setLoading(true);
    const getAddresses = raptorio.getAddresses();

    getAddresses
      .then((wallets) => {
        let walletAddresses = [];
        //setWallets(data);
        wallets.map((wallet) => {
          return walletAddresses.push(wallet.address);
        });
        return {
          addresses: wallets,
          walletAddresses: walletAddresses,
        };
      })
      .then((walletData) => {
        const params = {
          service: "explorer",
          method: "getAddressBalance",
          address: walletData.walletAddresses.join(","),
        };

        const serverData = getData(params);

        serverData
          .then((data) => {
            const status = data.status;
            if (status === "success") {
              if (typeof data[params.method].summary !== "undefined") {
                const payload = data[params.method].results;
                const balances = data[params.method].summary;
                let merged = [];

                for (let i = 0; i < walletData.addresses.length; i++) {
                  merged.push({
                    ...walletData.addresses[i],
                    ...payload.find(
                      (itmInner) =>
                        itmInner.address === walletData.addresses[i].address
                    ),
                  });
                }

                setBalances(balances);
                setData(merged);
              }
            } else {
              //console.log(data);
              setData(walletData.addresses);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      });
  }, [raptorio]);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  return (
    <Card className="shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Addresses {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table size="sm" className="font-monospace mb-2" hover>
          <tbody>
            {data &&
              data.map((address) => (
                <tr key={address.address}>
                  <td>
                    <span className="d-none d-xl-block">{address.label}</span>
                    <span className="d-xl-none">
                      <Link
                        to={"/explorer/address/" + address.address}
                        className="cursor-zoom-in"
                      >
                        {address.label === ""
                          ? truncate(address.address)
                          : address.label}
                      </Link>
                    </span>
                  </td>
                  <td className="text-center d-none d-sm-none d-md-table-cell">
                    <Link
                      to={"/explorer/address/" + address.address}
                      className="cursor-zoom-in"
                    >
                      <span className="d-none d-xl-block">
                        {address.address}
                      </span>
                      <span className="d-xl-none">
                        {truncate(address.address)}
                      </span>
                    </Link>
                  </td>
                  <td className="user-select-none">
                    <Badge
                      bg=""
                      className={
                        address.category === "native"
                          ? "badge-soft-primary"
                          : "badge-soft-warning"
                      }
                    >
                      {address.category}
                    </Badge>
                  </td>
                  <td className="text-end">
                    <NumericFormat
                      value={address.balance > 0 ? address.balance : 0}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      defaultValue={0}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                  <td className="text-end">
                    <NumericFormat
                      value={address.value > 0 ? address.value : 0}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      defaultValue={0}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot className="table-light text-muted">
            <tr>
              <td></td>
              <td className="text-center d-none d-sm-none d-md-table-cell"></td>
              <td></td>
              <td className="text-end">
                <span className="me-4">Total:</span>
                <span className="font-monospace">
                  <NumericFormat
                    value={balances.total_balance ?? 0}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    defaultValue={0}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </span>
              </td>
              <td className="text-end">
                <span className="font-monospace">
                  $
                  <NumericFormat
                    value={balances.total_value ?? 0}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    defaultValue={0}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </span>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default AddressesListCard;
