import React from "react";
import { ButtonGroup, Form, ToggleButton } from "react-bootstrap";
import { validateAddress } from "../../../../utils/CoinUtils";
import { QrReader } from "react-qr-reader";
import _ from "lodash";

const AddForm = ({ setEntry, category, addMethods, addFields }) => {
  const [formData, setFormData] = React.useState(addFields);
  //const [formReady, setFormReady] = React.useState(false);
  const [single, setSingle] = React.useState("");
  //const [label, setLabel] = React.useState("");
  const [multi, setMulti] = React.useState([]);
  //const [entry, setEntry] = React.useState([]);
  const [radioValue, setRadioValue] = React.useState("1");
  const [error, setError] = React.useState(false);
  const mainField = `${addFields[0].name}`;

  const commitEntry = () => {
    if (!error) {
      let entryData = { category: category, created: new Date().getTime() };
      formData.forEach((entry) => {
        Object.assign(entryData, {
          [entry.name]: entry.value,
          value: entry.value,
        });
      });
      setEntry([entryData]);
    } else {
      //console.log("shouldnt be here");
      return false;
    }
  };

  const handleChange = (input) => {
    if (input.target.required && input.target.value === "") {
      setError(`Need your input!`);
    } else {
      setError(false);
    }
    var clean = input.target.value.toString().trim();
    const getField = addFields.map((field) => {
      if (field.name === input.target.name) {
        field.value = clean;
      }
      return field;
    });
    setFormData(getField);
    commitEntry();
    //setSingle(clean);
  };

  const handleMultiChange = (input) => {
    let clean = input.target.value
      .toString()
      .replace(/[__,\s\n]+/g, "_")
      .trim();
    let multi = clean.split("_").filter((n) => n) ?? clean;
    setMulti(multi);
  };

  const commitMultiEntry = () => {
    setError(false);
    let newEntry = [];
    let errorCt = 0;

    multi.forEach((item, i) => {
      if (!validateAddress(item)) {
        errorCt++;
      } else {
        let newObj = {
          //_id: category + "-" + (start + i + 1),
          [mainField]: item,
          label: category + " " + i,
          category: category,
          created: new Date().getTime(),
        };
        const result = _.find(newEntry, newObj);
        if (!result) {
          newEntry.splice(newEntry.length, 0, newObj);
        }
      }
    });

    if (errorCt > 0) {
      return setError(errorCt + " not valid, check input!");
    }

    setEntry(newEntry);
  };

  const handleMultiOnBlur = () => {
    if (multi.length > 0) {
      //setSingle("");
      //setLabel("");
      return commitMultiEntry();
    }
  };

  const handleScan = (result, error) => {
    if (!!result) {
      setSingle(result?.text);
      setRadioValue("1");
    }
  };

  const handleMenuChange = (e) => {
    setRadioValue(e.currentTarget.value);
    setSingle("");
    setMulti([]);
    setFormData(addFields);
    setError(false);
  };

  return (
    <Form
      key={`${category}Form`}
      id={`${category}Form`}
      className="my-3"
      onSubmit={(e) => e.preventDefault()}
    >
      {!!addMethods && addMethods.length > 1 && (
        <ButtonGroup size="sm" className="mb-3">
          {addMethods.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`${category}radio-${idx}`}
              type="radio"
              variant={
                radioValue === radio.value ? "primary" : "outline-primary"
              }
              name="radio"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={handleMenuChange}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
      )}
      {!!radioValue && radioValue === "1" && (
        <Form.Group controlId={`${category}Form.ControlInput1`}>
          {addFields.map((field, idx) => (
            <Form.Control
              key={"entry-single-" + idx}
              type={field.type}
              name={field.name}
              defaultValue={
                idx === 0 && single !== "" ? single : field.defaultValue
              }
              placeholder={field.placeholder}
              autoComplete="off"
              onBlur={handleChange}
              required={field.required}
              disabled={field.disabled}
              autoFocus={field.autofocus}
            />
          ))}
          {!!error && <span className="text-danger">{error}</span>}
        </Form.Group>
      )}
      {!!radioValue && radioValue === "2" && (
        <Form.Group controlId={`${category}Form.ControlTextarea1`}>
          <Form.Control
            as="textarea"
            rows={3}
            onChange={handleMultiChange}
            onBlur={handleMultiOnBlur}
            autoFocus
          />
          {!!error && <span className="text-danger">{error}</span>}
          <Form.Label className="small">
            One per line or separate with commas
          </Form.Label>
        </Form.Group>
      )}
      {!!radioValue && radioValue === "3" && (
        <Form.Group controlId={`${category}Form.ControlQr`}>
          {radioValue === "3" && (
            <QrReader
              scanDelay={300}
              onResult={handleScan}
              style={{ width: "100%" }}
              constraints={{ facingMode: "environment" }}
            />
          )}
        </Form.Group>
      )}
    </Form>
  );
};

export default AddForm;
