import React from "react";
import { Col, Container, Navbar, Nav, Row } from "react-bootstrap";

import useSidebar from "../../hooks/useSidebar";
import NavbarSearch from "./NavbarSearch";

const NavbarComponent = () => {
  const { isOpen, setIsOpen } = useSidebar();

  return (
    <React.Fragment>
      <Navbar variant="light" expand className="navbar-bg">
        <Container fluid className="mx-4">
          <Row className="flex-fill">
            <Col xs="auto" className="p-0">
              <span
                className="sidebar-toggle"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <i className="hamburger align-self-center" />
              </span>
            </Col>
            <Col xs="auto" className="p-0 flex-grow-1">
              <NavbarSearch />
            </Col>
          </Row>

          <Navbar.Collapse>
            <Nav className="navbar-align"></Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

export default NavbarComponent;
