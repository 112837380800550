import React from "react";
import { Alert, Button, Modal } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const DemoModal = () => {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="danger" onClick={handleShow} size="sm" className="ms-4">
        View Disclaimer
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              fixedWidth
              className="me-2 text-danger"
            />
            This is a demonstration build!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="badge-soft-danger">
          <p>
            <strong className="fs-4">
              {process.env.REACT_APP_NAME} is subject to change without notice.
            </strong>
            <br />
            <u>Please do not share, copy or use regularly.</u>
            <br />
            Encrypted data integrity has <u>not</u> been audited.
            <br />
            <br />
            <em>
              By continuing to use this demonstration build, you agree to these
              terms.
            </em>
          </p>
          <hr />
          <p>
            <strong>
              Be sure to back up any created keys, seed phrases and passwords.
            </strong>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            I agree
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const DemoNotice = () => {
  const [show, setShow] = React.useState(true);
  const isDemo = process.env.REACT_APP_BUILD === "DEMO";
  if (isDemo && show) {
    return (
      <Alert
        key="demoalert"
        variant="danger"
        onClose={() => setShow(false)}
        dismissible
      >
        <div className="float-start p-3 m-0 text-center text-danger">
          <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth size="2x" />
        </div>
        <div className="p-3 m-0 float-end w-100">
          <strong>This is a demonstration build!</strong> <DemoModal />
        </div>
      </Alert>
    );
  } else {
    return null;
  }
};

export default DemoNotice;
