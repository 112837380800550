//import jwtDecode from "jwt-decode";
//import { verify, sign } from "jsonwebtoken";
import { isExpired, decodeToken } from "react-jwt";

import axios from "axios";

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const isMyTokenExpired = isExpired(accessToken);
  return isMyTokenExpired;
};

const handleTokenExpired = (exp) => {
  let expiredTimer;

  window.clearTimeout(expiredTimer);
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;
  console.log("time left", timeLeft);
  expiredTimer = window.setTimeout(() => {
    console.log("expired");
    setSession(null);
  }, timeLeft);
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    handleTokenExpired(accessToken);
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

export { decodeToken, isValidToken, setSession };
