import React from "react";
import QRCode from "react-qr-code";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Container,
  Dropdown,
  Modal,
  Table,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { NumericFormat } from "react-number-format";
import { getData } from "../../service/Service";
import { truncate } from "../../utils/Formatters";
import { ExplorerSubBreadcrumbs } from "../../components/Breadcrumbs";
import AddressTxCard from "./components/AddressTxCard";
import { Check, MoreHorizontal, X } from "react-feather";
import NotyfContext from "../../contexts/NotyfContext";
import Raptorio from "../../service/Raptorio";

const raptorio = new Raptorio({ coin: "RTM" });

const AddressQRModal = ({ show, setShow, data }) => {
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{data}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <QRCode value={data} size={300} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AddressWatchModal = ({ show, setShow, data, addToWatch }) => {
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add to Watch List?</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <strong>{data}</strong>
        <p className="small text-info">
          Your watchlist tracks balance and transactions as if it is yours.
        </p>
        Continue?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Cancel
        </Button>
        <Button variant="success" onClick={() => addToWatch()}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AddressBookModal = ({ show, setShow, data, addToBook }) => {
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add to Address Book?</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <strong>{data}</strong>
        <p className="small text-info">
          Your address book is an unwatched list for easier retrieval.
          <br /> Balances and transactions are not watched.
        </p>
        Continue?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Cancel
        </Button>
        <Button variant="success" onClick={() => addToBook()}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AddressHeader = ({ data, loading }) => {
  const [show, setShow] = React.useState(false);
  const [showAddWatch, setShowAddWatch] = React.useState(false);
  const [showAddBook, setShowAddBook] = React.useState(false);
  const [hasPortfolio, setHasPortfolio] = React.useState(false);
  //const [watchedData, setWatchedData] = React.useState(false);
  //const [nativeData, setNativeData] = React.useState(false);
  const [isWatched, setIsWatched] = React.useState(false);
  const [isMine, setIsMine] = React.useState(false);
  const notyf = React.useContext(NotyfContext);

  const fetchData = React.useCallback(() => {
    // Set the loading state

    const portfolios = raptorio.getPortfolios();
    portfolios.then((data) => {
      if (!!data && data.length > 0) {
        setHasPortfolio(true);
      }
    });

    const addresses = raptorio.getAddresses();

    addresses.then((addressesData) => {
      Object.values(addressesData).forEach((entry) => {
        if (entry.address === data.address && entry.category === "native") {
          setIsMine(true);
        }
        if (entry.address === data.address && entry.category === "watched") {
          setIsWatched(true);
        }
      });
    });
  }, [data.address]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const provideNotyf = (message, type, duration = 2000) => {
    notyf.open({
      type,
      message,
      duration,
      dismissible: true,
      ripple: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  const addToWatch = () => {
    let entryData = [
      {
        address: data.address,
        category: "watched",
        label: "added from explorer",
        created: new Date().getTime(),
      },
    ];
    saveEntry(entryData, "watched list");
    setShowAddWatch(false);
    fetchData();
  };

  const addToBook = () => {
    let entryData = [
      {
        address: data.address,
        category: "addressbook",
        label: "added from explorer",
        created: new Date().getTime(),
      },
    ];
    saveEntry(entryData, "address book");
    setShowAddBook(false);
    fetchData();
  };

  const saveEntry = (entry, category) => {
    raptorio
      .saveWatched(entry)
      .then(async (res) => {
        res.map((res, i) => {
          return res.then((res) => {
            if (res.errorCt > 0) {
              if (res.conflictCt > 0) {
                return provideNotyf(
                  `Address [${truncate(
                    data.address
                  )}] already exists on ${category}!`,
                  "warning"
                );
              } else {
                return provideNotyf(
                  `Error encountered, Couldn't add address to ${category}!`,
                  "danger"
                );
              }
            }
            if (res.successCt > 0) {
              return provideNotyf(
                `Address [${truncate(data.address)}] added to ${category}!`,
                "success"
              );
            }
          });
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card className="shadow-lg mb-5 rounded">
            <Card.Header>
              {hasPortfolio && (
                <div className="card-actions float-end">
                  <Dropdown align="end">
                    <Dropdown.Toggle as="a" bsPrefix="-">
                      <MoreHorizontal />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className="small"
                        onClick={() => {
                          setShowAddWatch(true);
                        }}
                      >
                        Add to Watchlist
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="small"
                        onClick={() => {
                          setShowAddBook(true);
                        }}
                      >
                        Add to Address Book
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <AddressWatchModal
                    show={showAddWatch}
                    setShow={setShowAddWatch}
                    data={data.address}
                    addToWatch={addToWatch}
                  />
                  <AddressBookModal
                    show={showAddBook}
                    setShow={setShowAddBook}
                    data={data.address}
                    addToBook={addToBook}
                  />
                </div>
              )}
              <Card.Title tag="h5" className="mb-0">
                Summary {loading && <Spinner animation="grow" size="sm" />}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Table size="sm">
                    <tbody>
                      <tr>
                        <th scope="row">Balance:</th>
                        <td className="font-monospace">
                          <NumericFormat
                            value={data.balance}
                            displayType={"text"}
                            thousandSeparator={true}
                            defaultValue={0}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Hodl %:</th>
                        <td className="font-monospace">
                          {data.perc_holdings}%
                          <span className="small"> of 21 Billion</span>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Created height:</th>
                        <td className="text-break font-monospace">
                          <span className="badge bg-dark text-light">
                            <Link
                              to={
                                "/explorer/block/" + data.created_block_height
                              }
                              className="text-reset cursor-zoom-in"
                            >
                              #
                              <NumericFormat
                                value={data.created_block_height}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </Link>
                          </span>{" "}
                          <span className="small text-secondary">
                            {data.created_at_format}
                          </span>
                        </td>
                      </tr>
                      {data.updated_block_height > 0 && (
                        <tr>
                          <th scope="row">Updated height:</th>
                          <td className="text-break font-monospace">
                            <span className="badge bg-dark text-light">
                              <Link
                                to={
                                  "/explorer/block/" + data.updated_block_height
                                }
                                className="text-reset cursor-zoom-in"
                              >
                                #
                                <NumericFormat
                                  value={data.updated_block_height}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </Link>
                            </span>{" "}
                            <span className="small text-secondary">
                              {data.updated_at_format}
                            </span>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th scope="row">Last Transaction:</th>
                        <td className="font-monospace">
                          <Link
                            to={"/explorer/tx/" + data.lasttx}
                            className="cursor-zoom-in"
                          >
                            {truncate(data.lasttx, 10)}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">QR Code</th>
                        <td>
                          <div className="p-1 bg-static-white d-inline-block">
                            {!!data.address && (
                              <React.Fragment>
                                <QRCode
                                  value={data.address}
                                  size={75}
                                  onClick={() => setShow(true)}
                                  className="cursor-zoom-in"
                                />
                                <AddressQRModal
                                  data={data.address}
                                  show={show}
                                  setShow={setShow}
                                />
                              </React.Fragment>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col>
                  <Table size="sm">
                    <tbody>
                      <tr>
                        <th scope="row" className="col-sm-2 text-nowrap">
                          Script Public Key
                        </th>
                        <td className="font-monospace small">
                          {data.script_pub_key}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="col-sm-2 text-nowrap">
                          Scripthash
                        </th>
                        <td className="font-monospace small">
                          {data.script_hash}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="col-sm-2 text-nowrap">
                          Hash160
                        </th>
                        <td className="font-monospace small">
                          {data.hash_160}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="col-sm-2 text-nowrap">
                          P2SH
                        </th>
                        <td className="font-monospace small">{data.p2sh}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <Table size="sm">
                    <tbody>
                      <tr>
                        <th scope="row" className="col-sm-2">
                          Is Valid?
                        </th>
                        <td>
                          {data.is_valid === 1 ? (
                            <Check size="16" className="text-success" />
                          ) : (
                            <X size="16" className="text-warning" />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="col-sm-2">
                          Is Script?
                        </th>
                        <td>
                          {data.is_script === 1 ? (
                            <Check size="16" className="text-success" />
                          ) : (
                            <X size="16" className="text-warning" />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="col-sm-2">
                          Is Witness?
                        </th>
                        <td>
                          {data.is_witness === 1 ? (
                            <Check size="16" className="text-success" />
                          ) : (
                            <X size="16" className="text-warning" />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="col-sm-2">
                          Is Mine?
                        </th>
                        <td>
                          {isMine ? (
                            <Check size="16" className="text-success" />
                          ) : (
                            <X size="16" className="text-warning" />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="col-sm-2">
                          Is Watch-Only?
                        </th>
                        <td>
                          {isWatched ? (
                            <Check size="16" className="text-success" />
                          ) : (
                            <X size="16" className="text-warning" />
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const Address = () => {
  const { address } = useParams();
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const refreshInterval = 60000;

  const fetchData = React.useCallback(({ address }) => {
    // Set the loading state
    setLoading(true);

    const params = {
      service: "explorer",
      method: "getAddress",
      address: address,
    };

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setData(payload);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    fetchData({ address });
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(() => {
        fetchData({ address });
      }, refreshInterval);
      return () => {
        clearInterval(interval);
      };
    }
  }, [fetchData, address, refreshInterval]);

  return (
    <React.Fragment>
      <Helmet title={"Address " + address} />
      <Container fluid className="p-0">
        <ExplorerSubBreadcrumbs
          tabname="Addresses"
          subname={address}
          link="addresses"
          truncatesub={true}
        />
        {data && <AddressHeader data={data} loading={loading} />}
        {data && (
          <React.Fragment>
            <h4 className="fw-lighter">Transactions</h4>
            <AddressTxCard address={address} />
          </React.Fragment>
        )}
        {!loading && !data && (
          <Alert key="address-alert" variant="info">
            <p className="float-start p-3 m-0 text-center">
              <FontAwesomeIcon icon={faInfoCircle} fixedWidth size="4x" />
            </p>
            <p className="p-3 m-0 float-end">
              <strong>Couldn't find data for this address!</strong>
              <br />
              This address appears to be unused...
            </p>
          </Alert>
        )}
      </Container>
    </React.Fragment>
  );
};

export default Address;
