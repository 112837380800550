export const validateAddress = (address) => {
  try {
    if (address) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

const exportable = { validateAddress };

export default exportable;
