import React, { useContext } from "react";
import { Button, Dropdown } from "react-bootstrap";
import QRCode from "react-qr-code";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { ModalContext } from "../../../../contexts/ModalContext";

export const QRDropdownItem = ({ entry, className }) => {
  const { handleModal } = useContext(ModalContext);
  return (
    <Dropdown.Item
      className={className}
      onClick={() =>
        handleModal({
          content: <QRCode value={entry} size={300} />,
          header: (
            <React.Fragment>
              <FontAwesomeIcon className="me-2" icon={faQrcode} />
              <span className="fw-light">{entry}</span>
            </React.Fragment>
          ),
          footer: null,
          size: "md",
          centered: true,
          fullscreen: true,
          className: "text-center bg-static-white",
        })
      }
    >
      <FontAwesomeIcon className="me-2" icon={faQrcode} fixedWidth />
      QR Code
    </Dropdown.Item>
  );
};

export const QRButton = ({ entry, className }) => {
  const { handleModal } = useContext(ModalContext);
  return (
    <Button
      variant="secondary"
      className={className}
      onClick={() =>
        handleModal({
          content: <QRCode value={entry} size={300} />,
          header: (
            <React.Fragment>
              <FontAwesomeIcon className="me-2" icon={faQrcode} />
              <span className="fw-light">{entry}</span>
            </React.Fragment>
          ),
          footer: null,
          size: "md",
          centered: true,
          fullscreen: true,
          className: "text-center bg-static-white",
        })
      }
    >
      <FontAwesomeIcon className="me-2" icon={faQrcode} fixedWidth />
      QR Code
    </Button>
  );
};

const exportable = { QRDropdownItem, QRButton };

export default exportable;
