import React from "react";
import useModal from "../hooks/useModal";
import ModalComponent from "../components/Modal";

let ModalContext;
let { Provider } = (ModalContext = React.createContext());

let ModalProvider = ({ children }) => {
  let { show, handleModal, options, hideModal } = useModal();
  return (
    <Provider value={{ show, handleModal, options, hideModal }}>
      <ModalComponent show={show} />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
