import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import SignIn from "../../components/auth/SignIn";
import defaultAvatar from "../../assets/img/avatars/avatar-default.png";

const SignInPage = () => {
  const displayName = window.localStorage.getItem("displayName") ?? "";
  const avatarRef = window.localStorage.getItem("avatar") ?? "";
  const greeting = displayName.length > 0 ? "Welcome back," : "Howdy!";
  const avatar = avatarRef.length > 0 ? avatarRef : defaultAvatar;
  return (
    <React.Fragment>
      <Helmet title="Sign In" />
      <div className="text-center mt-4">
        <h2>
          {greeting} {displayName}
        </h2>
        <p className="lead">Sign in to your account to continue</p>
      </div>

      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center">
              <img
                src={avatar}
                alt={displayName}
                className="img-fluid rounded-circle bg-primary"
                width="132"
                height="132"
              />
            </div>
            <SignIn />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SignInPage;
