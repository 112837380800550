import React from "react";
import { Card, Spinner } from "react-bootstrap";
import { getData } from "../../../service/Service";
import { useTranslation } from "react-i18next";

const MaxCoinCard = ({ reload }) => {
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { t } = useTranslation();

  const fetchData = React.useCallback(() => {
    const params = {
      service: "statistics",
      method: "maxsupply",
    };

    setLoading(true);

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setData(payload);
          }
        } else {
          console.log(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  return (
    <Card className="flex-grow-1 align-self-stretch shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {t("Max Supply")}
          {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {!!data && (
          <React.Fragment>
            <span className="fs-1 d-block">{data.max_supply_human}</span>
            <span className="small text-secondary">
              {data.max_supply_format}
            </span>
          </React.Fragment>
        )}
      </Card.Body>
    </Card>
  );
};

export default MaxCoinCard;
