import React, { useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import Chart from "react-apexcharts";
import usePalette from "../../../hooks/usePalette";
import useTheme from "../../../hooks/useTheme";
import { getData } from "../../../service/Service";
import { truncate } from "../../../utils/Formatters";

const AllocationCard = ({ raptorio, reload }) => {
  const palette = usePalette();
  const appTheme = useTheme();
  const [loading, setLoading] = useState(true);
  const [seriesData, setSeriesData] = useState([]);
  const [chartOptions, setChartOptions] = React.useState({
    chart: {
      theme: appTheme.theme,
      foreColor: palette["gray-600"],
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    },
    noData: {
      text: loading ? "Loading..." : "No data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: palette["danger"],
      },
    },
    tooltip: {
      enabled: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return (
          Number(val).toLocaleString("en", {
            maximumFractionDigits: 2,
          }) + "%"
        );
      },
    },
    colors: [
      palette.primary,
      palette.success,
      palette.warning,
      palette.danger,
      palette.info,
    ],
    stroke: {
      width: 0,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
    },
    plotOptions: {
      pie: {
        expandOnClick: true,
        customScale: 0.9,
        dataLabels: {
          offset: 45,
          minAngleToShowLabel: 10,
        },
        donut: {
          background: "transparent",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "14px",
              fontWeight: 100,
              offsetY: -10,
              formatter: (val) => {
                return val.length > 16 ? truncate(val) : val;
              },
            },
            value: {
              show: true,
              fontSize: "14px",
              fontWeight: 100,
              offsetY: 16,
              formatter: (val) => {
                return Number(val).toLocaleString("en", {
                  maximumFractionDigits: 2,
                });
              },
            },
            total: {
              show: true,
              showAlways: false,
              label: "Total",
              fontSize: "16px",
              fontWeight: 200,
              formatter: (w) => {
                return w.globals.seriesTotals
                  .reduce((a, b) => {
                    return a + b;
                  }, 0)
                  .toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
              },
            },
          },
        },
      },
    },
  });

  const fetchData = React.useCallback(() => {
    // Set the loading state
    setLoading(true);

    const getAddresses = raptorio.getAddresses();
    const getApis = raptorio.getPortfolioEntries("apis");

    getAddresses
      .then((data) => {
        let walletAddresses = [];
        data.map((wallet) => {
          return walletAddresses.push(wallet.address);
        });
        return walletAddresses;
      })
      .then((walletAddresses) => {
        const params = {
          service: "explorer",
          method: "getAddressBalance",
          address: walletAddresses.join(","),
        };

        const serverData = getData(params);
        let walletBalances = [];

        const getWalletData = serverData
          .then((data) => {
            const status = data.status;
            if (status === "success") {
              if (typeof data[params.method].results !== "undefined") {
                const payload = data[params.method].results;
                walletBalances.push(payload);
              }
            }
            return walletBalances;
          })
          .catch((err) => {
            console.log(err);
          });
        return getWalletData;
      })
      .then((walletBalances) => {
        let apiBalances = [];
        const getApiData = getApis
          .then(async (res) => {
            if (!res) {
              return apiBalances;
            }
            const result = await Promise.all(
              res.map(async (fetch) => {
                const params = {
                  service: "remote",
                  method: "requestSharedNodes",
                  apiKey: fetch.api,
                  provider: fetch.service,
                  symbol: fetch.symbol,
                };

                const serverData = getData(params);

                return serverData
                  .then((data) => {
                    const status = data.status;
                    if (status === "success") {
                      if (typeof data[params.method].results !== "undefined") {
                        const payload = data[params.method].results;
                        apiBalances.push(payload);
                      }
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            );
            return result;
          })
          .then(() => {
            return { walletBalances: walletBalances, apiBalances: apiBalances };
          });
        return getApiData;
      })
      .then((data) => {
        let seriesData = [];
        let labelData = [];
        if (data.walletBalances[0]?.length > 0) {
          data.walletBalances[0].map((wallet, i) => {
            if (wallet.balance > 0) {
              seriesData.push(wallet.balance);
              labelData.push(wallet.address);
            }
            return true;
          });
        }
        if (data.apiBalances.length > 0) {
          data.apiBalances.map((api) => {
            seriesData.push(api.deposited);
            labelData.push(api.provider);
            return true;
          });
        }
        if (seriesData.length > 0 && labelData.length > 0) {
          chartOptions.labels = labelData;
          const newOptions = {
            ...chartOptions,
            options: {
              //labels: [labelData],
            },
          };
          setChartOptions(newOptions);
          setSeriesData(seriesData);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [raptorio, chartOptions]);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  return (
    <Card className="flex-grow-1 align-self-stretch shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Allocation {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="chart">
          <Chart
            options={chartOptions}
            series={seriesData}
            type="donut"
            height="350"
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default AllocationCard;
