import { createContext, useEffect, useReducer } from "react";
//import axios from "../utils/axios";
import axios from "axios";
import { decodeToken, isValidToken, setSession } from "../utils/jwt";

const endpoint = process.env.REACT_APP_API_ENDPOINT;
const api_key = process.env.REACT_APP_API_KEY;

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  displayName: null,
  role: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        displayName: action.payload.displayName,
        role: action.payload.role,
        avatar: action.payload.avatar,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        displayName: action.payload.displayName,
        role: action.payload.role,
        avatar: action.payload.avatar,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        displayName: null,
        role: null,
        avatar: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        displayName: action.payload.displayName,
        role: action.payload.role,
        avatar: null,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const data = decodeToken(accessToken);

          const userId = typeof data === "object" ? data?.user : "";
          //const user = users.find((_user) => _user.id === userId);
          console.log(userId);
          console.log(api_key);

          const response = await axios
            .get(endpoint + "Users/getAccount/")
            .then((response) => {
              console.log(response);
              if (response.data.status === "error") {
                throw new Error(response.data.msg);
              }
              return response;
            });

          const { user, displayName, role, avatar } =
            response.data.getAccount.results;

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
              displayName,
              role,
              avatar,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
              displayName: null,
              role: null,
              avatar: null,
            },
          });
        }
      } catch (err) {
        console.error(err, "no token found");
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
            displayName: null,
            role: null,
            avatar: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (email, password) => {
    const response = await axios
      .post(endpoint + "Users/signin/", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.status === "error") {
          throw new Error(response.data.msg);
        }
        return response;
      });

    const { accessToken, user, displayName, role, avatar } =
      response.data.signin.results;

    localStorage.setItem("displayName", displayName);
    localStorage.setItem("avatar", avatar);

    setSession(accessToken);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
        displayName,
        role,
        avatar,
      },
    });
  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (email, password) => {
    const response = await axios
      .post(endpoint + "Users/signup/", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.status === "error") {
          throw new Error(response.data.msg);
        }
        return response;
      });

    const { accessToken, user } = response.data.signup.results;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
