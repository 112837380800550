import React from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faCopy, faEdit } from "@fortawesome/free-solid-svg-icons";
import { DeleteDropdownItem } from "./DeleteWidgets";

export const RowControls = ({
  name,
  entry,
  i,
  field,
  editMode,
  setEditMode,
  handleCopy,
  handleDelete,
}) => {
  return (
    <Dropdown align="end">
      <Dropdown.Toggle variant="light" size="sm">
        <span className="pe-2">
          <FontAwesomeIcon icon={faCogs} fixedWidth />
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-1">
        <Dropdown.Item
          className="ps-2 small"
          onClick={() => handleCopy(entry[field])}
        >
          <FontAwesomeIcon className="me-2" icon={faCopy} fixedWidth />
          Copy
        </Dropdown.Item>
        <Dropdown.Item
          className="ps-2 small"
          onClick={() => setEditMode(!!editMode && editMode === i ? false : i)}
          active={editMode === i}
        >
          <FontAwesomeIcon className="me-2" icon={faEdit} fixedWidth />
          Edit Label
        </Dropdown.Item>
        <DeleteDropdownItem
          className="ps-2 small text-danger"
          doc={entry}
          name={name}
          value={entry[field]}
          handleDelete={handleDelete}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

const exportable = {
  RowControls,
};

export default exportable;
