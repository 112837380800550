//init
import Pouch from "pouchdb";
//import some adapter
import find from "pouchdb-find";
import rel from "relational-pouch";
import _ from "lodash";
import { encrypt, decrypt, compare } from "n-krypta"; //For es6
import Promise from "../utils/PromiseWhile";

const raptoreumcore = require("@socialruins/raptoreumcore-lib");
//const bip39 = require("bip39");
//const hdkey = require("hdkey");
//const CoinKey = require("coinkey");
//const ci = require("coininfo");

const verbosey = false;

Pouch
  //.plugin(someadapter)
  .plugin(find)
  .plugin(rel);

//Functions for Portfolio Management
//accepts 'coin', to set the coin address to the specific asset etc
export default class Raptorio {
  constructor(props) {
    this.craptor("Raptorio is running", "info");
    this.props = props;
    //adapter options
    this.db = new Pouch(process.env.REACT_APP_NAME, { auto_compaction: true });
    //schema options
    this.reldb = this.db.setSchema([
      {
        singular: "portfolio",
        plural: "portfolios",
        relations: {
          wallets: {
            hasMany: {
              type: "wallet",
              options: {
                queryInverse: "portfolio",
              },
            },
          },
          smartnodes: {
            hasMany: {
              type: "smartnode",
              options: {
                queryInverse: "portfolio",
              },
            },
          },
          apis: {
            hasMany: {
              type: "api",
              options: {
                queryInverse: "portfolio",
              },
            },
          },
          custom: {
            hasMany: {
              type: "custom",
              options: {
                queryInverse: "portfolio",
              },
            },
          },
        },
      },
      //		  {singular: 'asset', plural: 'assets', relations: {portfolio: {belongsTo: 'portfolio'}, wallets: {hasMany: 'wallet'}}},
      {
        singular: "wallet",
        plural: "wallets",
        relations: {
          portfolio: {
            belongsTo: "portfolio",
          },
          transactions: {
            hasMany: {
              type: "transaction",
              options: {
                queryInverse: "wallet",
              },
            },
          },
        },
      },
      {
        singular: "transaction",
        plural: "transactions",
        relations: {
          wallet: {
            belongsTo: "wallet",
          },
        },
      },
      {
        singular: "smartnode",
        plural: "smartnodes",
        relations: {
          portfolio: {
            belongsTo: "portfolio",
          },
        },
      },
      {
        singular: "api",
        plural: "apis",
        relations: {
          portfolio: {
            belongsTo: "portfolio",
          },
        },
      },
      {
        singular: "custom",
        plural: "customs",
        relations: {
          portfolio: {
            belongsTo: "portfolio",
          },
        },
      },
    ]);

    this.state = {
      coin: this.props.coin, //Generate wallets for this coin
      autorun: this.props.autorun || false, //Create portfolio on first run?
      stacy: this.props.stacy || false, //RTM Stacy bot derivation path?
      isTestnet: this.props.isTestnet || false, //RTM TESTNET flag
      defaultPortfolio: {}, //our default portfolio object
      network: this.props.network,
      errored: false,
    };

    //Have we already been here before?
    this.isReady().then((found) => {
      if (found) {
        this.craptor("Im ready!", "info");
        this.setupPortfolio();
      } else {
        //no, setup a new portfolio
        this.craptor("No portfolios found... what to do?", "info");
        //this.craptor("Setting up first run default portfolio!", "info")
        if (this.state.autorun) {
          this.mnemonic = this.generateMnemonic();
          //createPortfolio(seed, label, pass, isDefault)
          this.createPortfolio(this.mnemonic, false, false, true, false);
          this.setupPortfolio();
        }
      }
    });

    //const password = "raptorio";
    //this.db = new PouchDB('raptorio');
    //this.db.crypto(password);
  }

  //THIS IS OUR CONSOLE LOGGER
  craptor(msg, flag) {
    if (!verbosey) {
      return { msg, flag };
    }
    if (flag === "error") {
      console.log("%c Error: ", "background: #222; color: red", msg);
    } else if (verbosey && flag === "info") {
      console.log("%c Info: ", "background: blue; color: white", msg);
    } else if (verbosey && flag === "success") {
      console.log("%c OK: ", "background: green; color: white", msg);
    } else {
      return false;
    }
    return { msg, flag };
  }

  // This is a simple, *insecure* hash that's short, fast, and has no dependencies.
  // For algorithmic use, where security isn't needed, it's way simpler than sha1 (and all its deps)
  // or similar, and with a short, clean (base 36 alphanumeric) result.
  // Loosely based on the Java version; see
  // https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
  simpleHash(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash &= hash; // Convert to 32bit integer
    }
    return new Uint32Array([hash])[0].toString(36);
  }

  //DATABASE STATUS CHECKS, DO WE HAVE A PORTFOLIO CREATED??
  async isReady() {
    return await this.db.rel
      .find("portfolio")
      .then((res) => {
        if (res.portfolios.length < 1) {
          throw new Error("No portfolios found");
        } else {
          return true;
        }
      })
      .catch((err) => {
        this.craptor(err, "error");
        return false;
      });
  }
  //DATABASE FIND FUNCTIONS
  getInfo() {
    this.craptor("Retrieving info for Raptorio portfolios", "info");
    this.db.info().then((info) => {
      this.craptor(info, "info");
    });
  }

  async getPortfolios() {
    this.craptor("Get portfolios", "info");
    const data = await this.db.rel
      .find("portfolio")
      .then((res) => {
        return res.portfolios;
      })
      .catch((err) => {
        this.craptor(err, "error");
      });
    return data;
  }

  async getDefaultPortfolio() {
    this.craptor("Get default portfolio", "info");
    return await this.db.rel
      .find("portfolio")
      .then((res) => {
        return res.portfolios.filter((portfolio) => {
          if (portfolio.isDefault) {
            //console.log("got a default", portfolio);
            return portfolio;
          } else {
            return false;
          }
        });
      })
      .catch((err) => {
        this.craptor(err, "error");
      });
  }

  getAssets() {
    this.craptor("Get assets", "info");
  }

  async getAddresses(category) {
    const defaultPortfolio = this.getDefaultPortfolio().then((portfolio) => {
      if (portfolio.length === 1) {
        portfolio = portfolio[0];
      }
      this.craptor("Get addresses from " + portfolio.id, "info");
      //let walletAddresses = [];
      const data = this.db.rel
        .findHasMany("wallet", "portfolio", portfolio.id)
        .then((res) => {
          if (category) {
            const wallets = res.wallets.filter((wallet) => {
              if (wallet.category === category) {
                return wallet;
              } else {
                return false;
              }
            });
            return wallets;
          } else {
            const wallets = res.wallets.filter((wallet) => {
              if (wallet.category !== "addressbook") {
                return wallet;
              } else {
                return false;
              }
            });
            return wallets;
          }
        })
        .then((wallets) => {
          let sortWallets = _.orderBy(
            wallets,
            ["category", "created"],
            ["asc", "asc"]
          );
          return sortWallets;
        })
        .catch((err) => {
          this.craptor(err, "error");
        });
      return data;
    });
    return defaultPortfolio;
  }

  getPortfolioEntries(category) {
    const defaultPortfolio = this.getDefaultPortfolio().then((portfolio) => {
      if (portfolio.length === 1) {
        portfolio = portfolio[0];
      }
      this.craptor("Get " + category + " from " + portfolio.id, "info");
      const data = this.db.rel
        .findHasMany(category, "portfolio", portfolio.id)
        .then((entries) => {
          return entries[category];
        })
        .catch((err) => {
          this.craptor(err, "error");
        });
      return data;
    });
    return defaultPortfolio;
  }

  generateMnemonic(entropy = 256) {
    const wordlist = raptoreumcore.Mnemonic(parseInt(entropy)).toString();
    //console.log(wordlist);
    //return bip39.generateMnemonic(entropy);
    return wordlist;
  }

  generateMnemonicFrom(seed) {
    //const papercode = bip39.entropyToMnemonic(privateKey);
    const bufferKey = raptoreumcore.util.buffer.hexToBuffer(seed);
    const papercode = raptoreumcore.Mnemonic(bufferKey).toString();
    return papercode;
  }

  generateKeys(phrase, count, pass, vanityPath) {
    //some error checking
    //correct word list length
    if (
      !(
        phrase.trim().split(/\s+/g).length !== 12 ||
        phrase.trim().split(/\s+/g).length !== 24
      )
    ) {
      this.state.errored = true;
      this.message = "Word list was less than expected!";
      return false;
    }
    this.craptor("phrase length ok", "success");

    //valid mnemonic
    if (!raptoreumcore.Mnemonic.isValid(phrase)) {
      //if (!bip39.validateMnemonic(phrase)) {
      this.state.errored = true;
      this.message = "Word list is invalid!";
      return false;
    }
    this.craptor("mnemonic ok", "success");

    //set variables
    //var seed = bip39.mnemonicToSeedSync(phrase); //.slice(0,32); //creates seed buffer
    const mn = raptoreumcore.Mnemonic(phrase);
    const sd = mn.toHDPrivateKey();
    //console.log(s);
    //const seed = bip39.mnemonicToSeedSync(phrase); //.slice(0,32); //creates seed buffer
    //const root = hdkey.fromMasterSeed(seed);
    //console.log(root);

    //set portfolio items
    /*const portfolioKeys = {
      privateKey: encrypt(root.privateKey.toString("hex"), pass),
      privateExtendedKey: encrypt(root.privateExtendedKey, pass),
      publicKey: root.publicKey.toString("hex"),
      publicExtendedKey: root.publicExtendedKey,
    };*/

    const portfolioKeys = {
      privateKey: encrypt(sd._buffers.privateKey.toString("hex"), pass),
      privateExtendedKey: encrypt(sd.xprivkey, pass),
      publicKey: encrypt(sd.publicKey.hash.toString("hex"), pass),
      publicExtendedKey: encrypt(sd.xpubkey, pass),
    };

    //generate the wallet addresses, etc.
    //const wallets = this.generateAddresses(root, count, pass, vanityPath);
    const wallets = this.generateAddresses(sd, count, pass, vanityPath, 0);

    return {
      portfolio: portfolioKeys,
      wallets: wallets,
    };
  }

  generateAddresses(root, count, pass, vanityPath, inc = 0) {
    this.craptor("using stacy path " + this.state.stacy, "info");
    //set variables
    //const version = ci(this.state.coin).versions;
    const chaincode = this.state.stacy ? 2 : 200; //legacy version

    //Stacy Bot: m/0'/2'/0
    //BIP44 Type 200 (Legacy): m/44'/200'/0'/0/0
    //BIP44 Type 10226 (Newest): m/44'/10226'/0'/0/0
    let ids = [];
    let path = "";

    //console.log(inc, count);

    //for each, set by count
    for (var i = inc; i < count + inc; i++) {
      //set path for stacy or..
      if (this.state.stacy) {
        path = `m/0'/` + chaincode + `'/` + i.toString();
      } else {
        path = `m/44'/` + chaincode + `'/0'/0/` + i.toString();
      }

      //console.log(path);

      //set derived
      const derived = root.deriveChild(path);
      //const derivedKeys = new CoinKey(derived.privateKey, version);

      //add them to our ids array to hold wallet keys
      ids.push({
        derivation_path: path,
        address: derived.privateKey.toAddress().toString(),
        privateKey: encrypt(derived.privateKey.toString(), pass),
        privateWif: encrypt(derived.privateKey.toWIF(), pass),
        privateExtendedKey: encrypt(derived.xprivkey, pass),
        publicKey: encrypt(derived.publicKey.hash.toString("hex"), pass),
        publicExtendedKey: encrypt(derived.xpubkey, pass),
        papercode: encrypt(
          this.generateMnemonicFrom(derived.privateKey.toString()),
          pass
        ),
      });
    }

    if (!!vanityPath) {
      if (Array.isArray(vanityPath)) {
        vanityPath.forEach((vpath, v) => {
          //set path for stacy or..
          if (this.state.stacy) {
            path = `m/0'/` + chaincode + `'/` + vpath.toString();
          } else {
            path = `m/44'/` + chaincode + `'/0'/0/` + vpath.toString();
          }

          //console.log(vpath, path);

          //set derived
          const derived = root.deriveChild(path);
          //const derivedKeys = new CoinKey(derived.privateKey, version);

          //add them to our ids array to hold wallet keys
          ids.push({
            label: "Vanity Address " + (v + 1),
            derivation_path: path,
            address: derived.privateKey.toAddress().toString(),
            privateKey: encrypt(derived.privateKey.toString(), pass),
            privateWif: encrypt(derived.privateKey.toWIF(), pass),
            privateExtendedKey: encrypt(derived.xprivkey, pass),
            publicKey: encrypt(derived.publicKey.hash.toString("hex"), pass),
            publicExtendedKey: encrypt(derived.xpubkey, pass),
            papercode: encrypt(
              this.generateMnemonicFrom(derived.privateKey.toString()),
              pass
            ),
          });
        });
      }
    }
    //ok, return it
    this.craptor("Created (" + ids.length + ") wallets", "success");
    //console.log(ids);
    return ids;
  }

  async getPrivateKeyForSigning(pass, address) {
    if (!pass) {
      return this.craptor("No soup for you", "error");
    }

    const getPrivateKey = this.getDefaultPortfolio()
      .then((portfolio) => {
        portfolio = portfolio[0];

        if (!compare(pass, portfolio.passKey, pass)) {
          return this.craptor("No soup for you", "error");
        }

        return portfolio;
      })
      .then((portfolio) => {
        const data = this.db.rel
          .findHasMany("wallet", "portfolio", portfolio.id)
          .then((res) => {
            const nativeWallets = res.wallets.filter((wallet) => {
              if (wallet.category === "native") {
                return wallet;
              } else {
                return false;
              }
            });

            if (address.length === 1) {
              const wallet = nativeWallets.filter(
                (wallet) => wallet.address === address[0]
              );
              const keys = wallet.map((wallet) => {
                return decrypt(wallet.privateWif, pass);
              });
              return keys;
            } else {
              const keys = nativeWallets.map((wallet) => {
                return decrypt(wallet.privateWif, pass);
              });
              return keys;
            }
          });
        return data;
      });
    return getPrivateKey;
  }

  async generateNewAddress(pass) {
    if (!pass) {
      return this.craptor("No soup for you", "error");
    }
    const defaultPortfolio = this.getDefaultPortfolio().then((portfolio) => {
      if (portfolio.length === 1) {
        portfolio = portfolio[0];
      }
      this.craptor("Get addresses from " + portfolio.id, "info");
      if (!compare(pass, portfolio.passKey, pass)) {
        return this.craptor("No soup for you", "error");
      }

      this.state.stacy = portfolio.isStacy || false;

      const fromPrivateKey = decrypt(portfolio.privateExtendedKey, pass);

      const root = raptoreumcore.HDPrivateKey(fromPrivateKey);

      //let walletAddresses = [];
      const data = this.db.rel
        .findHasMany("wallet", "portfolio", portfolio.id)
        .then((res) => {
          const wallets = res.wallets.filter((wallet) => {
            if (wallet.category === "native") {
              return wallet;
            } else {
              return false;
            }
          });
          return wallets;
        })
        .then((wallets) => {
          const addressCount = wallets.length;
          const newWallet = this.generateAddresses(
            root,
            1,
            pass,
            false,
            addressCount
          );
          if (!newWallet) {
            this.craptor(this.message, "error");
            return false;
          }

          var newwallets = [];
          Object.values(newWallet).forEach((wallet, i) => {
            let acc = addressCount + 1;
            let walletAttr = {
              id: portfolio.id + "-" + wallet.address,
              portfolio: portfolio.id,
              label: portfolio.label + " Wallet " + acc,
              category: "native",
              created: new Date().getTime(),
              symbol: this.state.coin,
            };
            newwallets.push(Object.assign({}, walletAttr, wallet));
          });

          this.saveWallets(newwallets);
          return newwallets;
        })
        .catch((err) => {
          this.craptor(err, "error");
        })
        .finally(() => {
          if (this.state.errored) {
            this.craptor("Error creating address", "error");
          } else {
            this.craptor("Created wallet data", "success");
          }
        });
      return data;
    });
    return defaultPortfolio;
  }

  async generateVanityAddress(phrase, vanity, s, d, offset, isStacy) {
    //Use any alphanumeric string with the exception that the uppercase letter “O”, uppercase letter “I”, lowercase letter “l”, and the number “0”
    //are never used to prevent visual ambiguity.
    if (
      vanity.includes("O") ||
      vanity.includes("I") ||
      vanity.includes("l") ||
      vanity.includes("0")
    ) {
      return {
        error:
          "Can't use uppercase letter “O”, uppercase letter “I”, lowercase letter “l”, and the number “0”",
      };
    }

    //valid mnemonic
    //if (!bip39.validateMnemonic(phrase)) {
    if (!raptoreumcore.Mnemonic.isValid(phrase)) {
      this.state.errored = true;
      this.message = "Word list is invalid!";
      return false;
    }
    //const version = ci(this.state.coin).versions;
    const chaincode = isStacy ? 2 : 200; //legacy version

    //var seed = bip39.mnemonicToSeedSync(phrase); //.slice(0,32); //creates seed buffer
    //const root = hdkey.fromMasterSeed(seed);
    const mn = raptoreumcore.Mnemonic(phrase);
    const sd = mn.toHDPrivateKey();

    let testStr;
    let i;
    let tries = 0;
    let result = false;
    await Promise.loop({
      initialization: function () {
        i = offset;
      },
      condition: function () {
        return i <= offset + 1000;
      },
      body: function () {
        // Exception when 5 is reached
        if (i === offset + 1000) {
          tries = offset + 1000;
          throw Error("Limit Reached!");
        }
        let path;
        //set path for stacy or..
        if (isStacy) {
          path = `m/0'/` + chaincode + `'/` + i.toString();
        } else {
          path = `m/44'/` + chaincode + `'/0'/0/` + i.toString();
        }
        //let path = `m/44'/` + chaincode + `'/0'/0/` + i.toString();
        //set derived
        //const derived = root.derive(path);
        const derived = sd.deriveChild(path);
        //const derivedKeys = new CoinKey(derived.privateKey, version);

        let genaddress = derived.privateKey.toAddress().toString();
        let addressKey = "R";

        // Print "i"
        //console.log(path, i + " - " + genaddress);

        if (s === false) {
          genaddress = genaddress.toLowerCase();
          vanity = vanity.toLowerCase();
          addressKey = "r";
        }

        if (d === "ltr") {
          if (vanity.charAt(0) === "r" || vanity.charAt(0) === "R") {
            vanity = vanity.slice(1);
          }
          testStr = genaddress.startsWith(addressKey + vanity);
        }

        if (d === "rtl") {
          testStr = genaddress.endsWith(vanity);
        }

        if (d === "a") {
          testStr = genaddress.includes(vanity);
          //regex.test(derivedKeys.publicAddress);
        }

        if (testStr) {
          //console.log("match found");
          result = {
            address: derived.privateKey.toAddress().toString(),
            tries: i,
          };
          throw Error("Found a match!");
        }
        return i;
      },
      increment: function () {
        ++i;
      },
    })
      .then(function () {
        //console.log("LOOP FINISHED");
      })
      .catch(function (error) {
        //console.log("EXPECTED ERROR:", error.message);
        return i;
      });

    if (!!result) {
      return result;
    } else {
      return tries;
    }
  }

  //PORTFOLIO CREATION METHODS - CREATE NEW & IMPORT
  //accepts 'isDefault' flag, otherwise is false
  async createPortfolio(
    seed,
    label,
    pass,
    isDefault,
    isTestnet,
    vanityPath,
    countAdd,
    stacy
  ) {
    if (!pass) {
      return this.craptor("Forget something to create portfolio", "error");
    }
    this.craptor("Lets create your portfolio", "info");
    this.state.stacy = stacy || false;
    this.state.isTestnet = isTestnet || false;

    const createTime = new Date().getTime();

    let aggrData = this.generateKeys(seed, countAdd, pass, vanityPath);
    if (!aggrData) {
      this.craptor(this.message, "error");
      return false;
    }

    let portfolioAttr = {
      id: "portfolio-" + createTime,
      label: label,
      created: new Date().getTime(),
      isDefault: false,
      network: this.state.isTestnet ? "testnet" : "mainnet",
      passKey: encrypt(pass, pass),
      isStacy: stacy === "true" ? true : false,
    };

    const portfolio = Object.assign({}, portfolioAttr, aggrData.portfolio);
    var wallets = [];
    Object.values(aggrData.wallets).forEach((wallet, i) => {
      let walletAttr = {
        id: "portfolio-" + createTime + "-" + wallet.address,
        portfolio: "portfolio-" + createTime,
        label: wallet.label || label + " Wallet " + (i + 1),
        category: "native",
        network: this.state.isTestnet ? "testnet" : "mainnet",
        created: new Date().getTime(),
        symbol: this.state.coin,
      };
      wallets.push(Object.assign({}, walletAttr, wallet));
    });

    return this.savePortfolio(portfolio)
      .then((res) => {
        if (!!res.id) {
          this.saveWallets(wallets);
          if (isDefault) {
            //console.log("try to set default on new portfolio", res.id);
            return this.updateDefault(res.id);
          }
        }
        return res;
      })
      .catch((err) => {
        this.craptor(err, "error");
      })
      .finally((res) => {
        if (this.state.errored) {
          this.craptor("Error creating portfolio", "error");
        } else {
          this.craptor("Created portfolio and wallet data", "success");
        }
        return res;
      });
  }

  getPortfolioState() {
    return this.state;
  }

  //PORTFOLIO SETUP METHODS
  setupPortfolio() {
    this.craptor("Portfolio found, lets use it", "info");
    //this.getDefaultPortfolio();

    if (this.state.errored) {
      this.craptor("Error setting up the portfolio", "error");
    }
  }

  //SAVE METHODS
  savePortfolio(portfolio) {
    return this.db.rel
      .save("portfolio", portfolio)
      .then((response) => {
        // handle response
        this.craptor(response, "success");
        return response;
      })
      .catch((err) => {
        this.craptor(err, "error");
      });
  }

  async saveWallet(wallet) {
    let errors = [];
    let conflicts = [];
    let successes = [];
    await this.db.rel
      .save("wallet", wallet)
      .then((response) => {
        successes.push(response);
        return successes;
      })
      .catch((err) => {
        if (err.error) {
          errors.push({ msg: err.message, name: err.name });
          if (err.name === "conflict") {
            conflicts.push({ msg: err.message, name: err.name });
            return conflicts;
          }
          return errors;
        }
      });
    return { errors: errors, conflicts: conflicts, successes: successes };
  }

  saveWallets(wallets) {
    if (_.isEmpty(wallets)) {
      return this.craptor("No data in saveWallets", "error");
    }
    try {
      let conflictCt = 0;
      let errorCt = 0;
      let successCt = 0;
      const itWallets = Object.values(wallets).map(
        async (wallet) => {
          const save = await this.saveWallet(wallet);
          if (save.errors.length > 0) {
            errorCt++;
          }
          if (save.conflicts.length > 0) {
            conflictCt++;
          }
          if (save.successes.length > 0) {
            successCt++;
          }
          return { errorCt, conflictCt, successCt };
        },
        { errorCt, conflictCt, successCt }
      );

      return itWallets;
    } catch (e) {
      console.log(e);
      throw e; // let caller know the promise was rejected with this reason
    }
  }

  saveWatched(entries) {
    if (_.isEmpty(entries)) {
      return this.craptor("No data in saveWatched", "error");
    }
    const newAddress = this.getDefaultPortfolio()
      .then((portfolio) => {
        if (portfolio.length === 1) {
          portfolio = portfolio[0];
        } else {
          return false;
        }

        var wallets = [];
        Object.values(entries).forEach((wallet, i) => {
          let walletAttr = {
            id: portfolio.id + "-" + wallet.address,
            portfolio: portfolio.id,
            symbol: this.state.coin,
          };
          if (wallet.category === "addressbook") {
            walletAttr = {
              id: portfolio.id + "-addressbook-" + wallet.address,
              portfolio: portfolio.id,
              symbol: this.state.coin,
            };
          }

          wallets.push(Object.assign({}, walletAttr, wallet));
        });

        return this.saveWallets(wallets);
        //return wallets;
      })
      .catch((error) => {
        return error;
      });
    return newAddress;
  }

  async saveEntry(entry) {
    let errors = [];
    let conflicts = [];
    let successes = [];
    await this.db.rel
      .save(entry.category, entry)
      .then((response) => {
        successes.push(response);
        return successes;
      })
      .catch((err) => {
        if (err.error) {
          errors.push({ msg: err.message, name: err.name });
          if (err.name === "conflict") {
            conflicts.push({ msg: err.message, name: err.name });
            return conflicts;
          }
          return errors;
        }
      });
    return { errors: errors, conflicts: conflicts, successes: successes };
  }

  saveEntries(entries) {
    if (_.isEmpty(entries)) {
      return this.craptor("No data in saveEntries", "error");
    }
    const defaultPortfolio = this.getDefaultPortfolio()
      .then((portfolio) => {
        if (portfolio.length === 1) {
          portfolio = portfolio[0];
        } else {
          return false;
        }

        var newEntries = [];
        Object.values(entries).forEach((entry, i) => {
          let entryAttr = {
            id: entry.category + "-" + this.simpleHash(entry.value),
            portfolio: portfolio.id,
            symbol: this.state.coin,
          };
          newEntries.push(Object.assign({}, entryAttr, entry));
        });
        return newEntries;
      })
      .then((entries) => {
        try {
          let conflictCt = 0;
          let errorCt = 0;
          let successCt = 0;
          const itEntries = Object.values(entries).map(
            async (entry) => {
              const save = await this.saveEntry(entry);
              if (save.errors.length > 0) {
                errorCt++;
              }
              if (save.conflicts.length > 0) {
                conflictCt++;
              }
              if (save.successes.length > 0) {
                successCt++;
              }
              return { errorCt, conflictCt, successCt };
            },
            { errorCt, conflictCt, successCt }
          );

          return itEntries;
        } catch (e) {
          console.log(e);
          throw e; // let caller know the promise was rejected with this reason
        }
      });
    return defaultPortfolio;
  }

  //UPDATE METHODS
  //UPDATE DEFAULT PORTFOLIO
  async updateDefault(id) {
    //reset the current default portfolio to isDefault: false
    const find = await this.db.rel
      .find("portfolio")
      .then((doc) => {
        Object.values(doc.portfolios).map((portfolio) => {
          portfolio.isDefault = portfolio.id === id;
          //console.log(portfolio);
          return this.savePortfolio(portfolio);
        });
        return this.getDefaultPortfolio().then((res) => {
          return res[0];
        });
      })
      .catch((err) => {
        this.craptor(err, "error");
        return false;
      });

    return find;
  }

  //UPDATE PORTFOLIO LABEL
  async updatePortfolioLabel(id, input) {
    //reset the current default portfolio to isDefault: false
    const find = await this.db.rel
      .find("portfolio", id)
      .then((doc) => {
        const selected = doc.portfolios[0];
        const updated = Object.assign({}, selected, { label: input });

        return this.db.rel
          .save("portfolio", updated)
          .then((response) => {
            // handle response
            this.craptor(response, "success");
            return response;
          })
          .catch((err) => {
            this.craptor(err, "error");
          });
      })
      .catch((err) => {
        this.craptor(err, "error");
        return false;
      });

    return find;
  }

  //UPDATE WALLET LABEL
  async updateWalletLabel(id, input) {
    //reset the current default portfolio to isDefault: false
    const find = await this.db.rel
      .find("wallet", id)
      .then((doc) => {
        const selected = doc.wallets[0];
        const updated = Object.assign({}, selected, { label: input });

        return this.db.rel
          .save("wallet", updated)
          .then((response) => {
            // handle response
            this.craptor(response, "success");
            return response;
          })
          .catch((err) => {
            this.craptor(err, "error");
          });
      })
      .catch((err) => {
        this.craptor(err, "error");
        return false;
      });

    return find;
  }

  //UPDATE ENTRY LABEL
  async updateEntryLabel(id, category, input) {
    //reset the current default portfolio to isDefault: false
    const find = await this.db.rel
      .find(category, id)
      .then((doc) => {
        const selected = doc[category][0];
        const updated = Object.assign({}, selected, { label: input });

        return this.db.rel
          .save(category, updated)
          .then((response) => {
            // handle response
            this.craptor(response, "success");
            return response;
          })
          .catch((err) => {
            this.craptor(err, "error");
          });
      })
      .catch((err) => {
        this.craptor(err, "error");
        return false;
      });

    return find;
  }

  async cleanupDb() {
    return this.db
      .viewCleanup()
      .then(function (result) {
        // handle result
        this.craptor("Cleaned up", "success");
      })
      .catch(function (err) {
        this.craptor("Couldnt clean up", "error");
      });
  }

  async compactDb() {
    return this.db
      .compact()
      .then(function (result) {
        // handle result
        this.craptor("Cleaned up", "success");
      })
      .catch(function (err) {
        this.craptor("Couldnt clean up", "error");
      });
  }

  afterDelete() {
    this.cleanupDb();
    this.compactDb();
    return true;
  }

  //DELETE METHODS
  async deletePortfolio(id) {
    const find = await this.db.rel
      .find("portfolio", id)
      .then((doc) => {
        if (doc.wallets.length > 0) {
          Object.values(doc.wallets).forEach((wallet) => {
            this.db.rel.del("wallet", wallet);
          });
        }
        if (doc.transactions.length > 0) {
          Object.values(doc.transactions).forEach((transaction) => {
            this.db.rel.del("transaction", transaction);
          });
        }
        return this.db.rel
          .del("portfolio", doc.portfolios[0])
          .then((response) => {
            // handle response
            this.craptor(response, "success");
            return response;
          })
          .catch((err) => {
            this.craptor(err, "error");
          });
        //console.log(doc);
      })
      .catch((err) => {
        this.craptor(err, "error");
      });
    return find;
  }

  async deleteWatched(id) {
    const find = await this.db.rel
      .find("wallet", id)
      .then((doc) => {
        return this.db.rel.del("wallet", doc.wallets[0]);
      })
      .catch((err) => {
        this.craptor(err, "error");
        return false;
      });

    return find;
  }

  async deleteEntry(id, category) {
    const find = await this.db.rel
      .find(category, id)
      .then((doc) => {
        return this.db.rel.del(category, doc[category][0]);
      })
      .catch((err) => {
        this.craptor(err, "error");
        return false;
      });

    return find;
  }
}
