import React, { useState } from "react";
import { Card, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { getData } from "../../../service/Service";
import _ from "lodash";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var LocalizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const BalanceCard = ({ raptorio, reload }) => {
  const [loading, setLoading] = useState(true);
  const [walletBalances, setWalletBalances] = useState(false);
  const [totalBalance, setTotalBalance] = useState(false);

  const fetchData = React.useCallback(() => {
    // Set the loading state
    setLoading(true);

    const getAddresses = raptorio.getAddresses();
    const getApis = raptorio.getPortfolioEntries("apis");

    getAddresses
      .then((data) => {
        let walletAddresses = [];
        data.map((wallet) => {
          return walletAddresses.push(wallet.address);
        });
        return walletAddresses;
      })
      .then((walletAddresses) => {
        const params = {
          service: "explorer",
          method: "getAddressBalance",
          address: walletAddresses.join(","),
        };

        const serverData = getData(params);
        let walletBalances = [];

        const getWalletData = serverData
          .then((data) => {
            const status = data.status;
            if (status === "success") {
              if (typeof data[params.method].summary !== "undefined") {
                const payload = data[params.method].summary;
                setWalletBalances(payload);
                walletBalances.push(payload);
              }
            }
            return walletBalances;
          })
          .catch((err) => {
            console.log(err);
          });
        return getWalletData;
      })
      .then((walletBalances) => {
        let apiBalances = [];
        const getApiData = getApis
          .then(async (res) => {
            const result = await Promise.all(
              res.map(async (fetch) => {
                const params = {
                  service: "remote",
                  method: "requestSharedNodes",
                  apiKey: fetch.api,
                  provider: fetch.service,
                  symbol: fetch.symbol,
                };

                const serverData = getData(params);

                return serverData
                  .then((data) => {
                    const status = data.status;
                    if (status === "success") {
                      if (typeof data[params.method].results !== "undefined") {
                        const payload = data[params.method].results;
                        apiBalances.push(payload);
                      }
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
            );
            return result;
          })
          .then(() => {
            return { walletBalances: walletBalances, apiBalances: apiBalances };
          });
        return getApiData;
      })
      .then((data) => {
        const totalWallets =
          _.sumBy(data.walletBalances, (wallet) => {
            return wallet.total_balance;
          }) ?? 0;
        const totalApis =
          _.sumBy(data.apiBalances, (api) => {
            return api.deposited;
          }) ?? 0;
        const totalBalance = totalApis + totalWallets;
        setTotalBalance(totalBalance);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [raptorio]);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  return (
    <Card className="flex-grow-1 align-self-stretch shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Portfolio Balance{" "}
          {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {!!totalBalance ? (
          <div className="d-block fs-1">
            <OverlayTrigger
              key="balance-tooltip"
              placement="top"
              overlay={
                <Tooltip id={`balance-tooltip-top`}>{totalBalance}</Tooltip>
              }
            >
              <span className="text-dark">
                <NumericFormat
                  value={totalBalance}
                  defaultValue={0}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </span>
            </OverlayTrigger>
          </div>
        ) : (
          <div className="d-block fs-1">
            <NumericFormat
              value={0}
              defaultValue={0}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={"text"}
              thousandSeparator={true}
            />
          </div>
        )}
        {totalBalance > 0 && (
          <React.Fragment>
            {"$"}
            <NumericFormat
              value={walletBalances.current_price * totalBalance}
              defaultValue={0}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={"text"}
              thousandSeparator={true}
            />
          </React.Fragment>
        )}
      </Card.Body>
      <Card.Footer className="text-secondary small text-end">
        {!!walletBalances &&
          "Updated " + dayjs.unix(walletBalances.updated_at).fromNow()}
      </Card.Footer>
    </Card>
  );
};

export default BalanceCard;
