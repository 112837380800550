import React from "react";
import { Card, ProgressBar, Spinner } from "react-bootstrap";
import { getData } from "../../../service/Service";
import { useTranslation } from "react-i18next";

const CirculatingCoinCard = ({ reload }) => {
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { t } = useTranslation();

  const fetchData = React.useCallback(() => {
    const params = {
      service: "statistics",
      method: "circulatingsupply",
    };

    setLoading(true);

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setData(payload);
          }
        } else {
          console.log(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  return (
    <Card className="flex-grow-1 align-self-stretch shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {t("Circulating Supply")}
          {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {!!data && (
          <React.Fragment>
            <span className="fs-1 d-block">
              {data.circulating_supply_human}
            </span>
            <div className="small clearfix">
              <span className="text-muted float-start">
                {data.circulating_supply_percent}
              </span>{" "}
              <span className="text-secondary float-end">
                {data.circulating_supply_format}
              </span>
            </div>
          </React.Fragment>
        )}
        <ProgressBar
          variant="primary"
          animated
          style={{ height: 5 }}
          now={data && data.circulating_supply_percent_no}
        />
      </Card.Body>
    </Card>
  );
};

export default CirculatingCoinCard;
