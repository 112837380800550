import React from "react";
import { isMobile } from "react-device-detect";
import { Button, Card, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
//get our table component
import EntryTable from "./components/EntryTable";

const CustomAmount = ({ name, category, raptorio }) => {
  const addMethods = [{ name: "Add One", value: "1" }];
  const addFields = [
    {
      name: "amount",
      placeholder: "Amount",
      validate: "number",
      type: isMobile ? "tel" : "number",
      autofocus: true,
      required: true,
    },
    { name: "label", placeholder: "Label", type: "text" },
  ];
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">
        <FontAwesomeIcon
          icon={faInfoCircle}
          className="text-info me-2"
          fixedWidth
        />
        {name}
      </Popover.Header>
      <Popover.Body>
        <div>
          {`Custom coin amounts can be added to your ${process.env.REACT_APP_NAME} portfolio.`}
          <hr />
          <span className="text-warning">
            {`Useful for tracking coins that are external to your tracked wallets. `}
            <br />
            {`eg. Shared nodes, testing, fun, etc.`}
          </span>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <Card key={category + "-card"} className="shadow-lg">
      <Card.Header>
        <div className="float-end">
          <OverlayTrigger
            trigger="click"
            placement="left"
            overlay={popover}
            rootClose={true}
          >
            <Button variant="light" size="sm">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="text-info"
                fixedWidth
              />
            </Button>
          </OverlayTrigger>
        </div>
        <Card.Title tag="h5" className="mb-0">
          {name}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <EntryTable
          name={name}
          category={category}
          addFields={addFields}
          addMethods={addMethods}
          raptorio={raptorio}
        />
      </Card.Body>
    </Card>
  );
};

export default CustomAmount;
