import React from "react";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup,
  Row,
  Table,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRedoAlt,
  faSlidersH,
  faTimesCircle,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  //PaginationState,
  useReactTable,
  getCoreRowModel,
  //ColumnDef,
  flexRender,
  //getSortedRowModel,
  //SortingState,
} from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";

//import DataTablePagination from "./DataTablePagination";

const getPaginationGenerator = (
  currentPageNumber,
  totalPageNumber,
  offset = 2
) => {
  // By doing this, when we are close to the beginning or end of the pagination, two numbers are generated after/before the current page,
  // but when we are far from these points (in the middle of the pagination), we generate only one number after/before the current page.
  const offsetNumber =
    currentPageNumber <= offset || currentPageNumber > totalPageNumber - offset
      ? offset
      : offset - 1;
  const numbersList = [];
  const numbersListWithDots = [];

  // If itemsPerPage is less than what the user selected with the Select component or if there is no page or only one page:
  if (totalPageNumber <= 1 || totalPageNumber === undefined) return [1];

  // Create list of numbers:
  numbersList.push(1);
  for (
    let i = currentPageNumber - offsetNumber;
    i <= currentPageNumber + offsetNumber;
    i++
  ) {
    if (i < totalPageNumber && i > 1) {
      numbersList.push(i);
    }
  }
  numbersList.push(totalPageNumber);

  // Add three dots to the list of numbers:
  numbersList.reduce((accumulator, currentValue) => {
    if (accumulator === 1) {
      numbersListWithDots.push(accumulator);
    }
    if (currentValue - accumulator !== 1) {
      numbersListWithDots.push("...");
    }
    numbersListWithDots.push(currentValue);

    return currentValue;
  });

  return numbersListWithDots;
};

const DataTablePagination = ({ table }) => {
  const paginatorList = getPaginationGenerator(
    table.getState().pagination.pageIndex + 1,
    table.getPageCount()
  );

  return (
    <React.Fragment>
      <Row className="justify-content-md-center">
        <Col className="p-0 ps-2">
          <ButtonToolbar
            className="d-block text-center"
            aria-label="Pagination Toolbar"
          >
            <ButtonGroup className="me-2" aria-label="Prev group" size="sm">
              <Button
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
                variant="secondary"
              >
                {"<<"}
              </Button>
              <Button
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                variant="secondary"
              >
                {"<"}
              </Button>
            </ButtonGroup>
            <ButtonGroup className="me-2" aria-label="Visible group" size="sm">
              {paginatorList.map((b, i) => {
                return (
                  <Button
                    key={`pg-id-${i}`}
                    variant={
                      table.getState().pagination.pageIndex + 1 === b
                        ? "primary"
                        : "secondary"
                    }
                    onClick={() => table.setPageIndex(b - 1)}
                    disabled={isNaN(b)}
                  >
                    {b}
                  </Button>
                );
              })}
            </ButtonGroup>
            <ButtonGroup className="me-2" aria-label="Next group" size="sm">
              <Button
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                variant="secondary"
              >
                {">"}
              </Button>
              <Button
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
                variant="secondary"
              >
                {">>"}
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <span className="small">
            Page <strong>{table.getState().pagination.pageIndex + 1}</strong> of{" "}
            <strong>{table.getPageCount()}</strong>
          </span>
        </Col>
      </Row>
    </React.Fragment>
  );
};

// Let's add a fetchData method to our Table component that will be used to fetch
// new data when pagination state changes
// We can also add a loading state to let our table know it's loading new data
const DataTable = ({
  columns,
  fetchData,
  reload,
  placeholderText,
  enableSelection,
  queryKey,
  handleSelection,
  selectedRows, //preselected?
}) => {
  //const isFetching = useIsFetching();
  const rerender = React.useReducer(() => ({}), {})[1];
  const [sorting, setSorting] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [rowSelection, setRowSelection] = React.useState(selectedRows ?? {});
  const [{ pageIndex, pageSize }, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const defaultData = React.useMemo(() => [], []);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const fetchDataOptions = {
    pageIndex,
    pageSize,
    sorting,
    globalFilter,
  };

  const { isLoading, isError, error, data, isFetching } = useQuery(
    [queryKey, fetchDataOptions],
    () => fetchData(fetchDataOptions),
    {
      keepPreviousData: true,
    }
  );

  const table = useReactTable({
    data: data?.rows ?? defaultData,
    columns,
    pageCount: data?.pageCount ?? -1,
    state: {
      pagination,
      sorting,
      rowSelection,
    },
    enableRowSelection: enableSelection ?? false,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    manualPagination: true,
    manualFiltering: true,
    getRowId: (row) => row.id,
    // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
    debugTable: false,
  });

  function clearFilter(e) {
    setGlobalFilter("");
    //gotoPage(0);
  }

  function resetAll(e) {
    setGlobalFilter("");
    rerender();
    //fetchData({ pageIndex, pageSize, sortBy });
    //gotoPage(0);
  }

  React.useEffect(() => {
    const selected = table.getSelectedRowModel().flatRows.map((row) => {
      return row.original;
    });
    handleSelection?.({ rows: rowSelection, rowData: selected });
  }, [rowSelection, table, handleSelection, selectedRows]);

  // Render the UI for your table
  return (
    <Card className="shadow-lg">
      <Card.Header className="p-2">
        <Row className="justify-content-between">
          <Col className="col-8 col-md-8 col-lg-6 col-xl-4">
            <InputGroup size="sm">
              <FormControl
                id="filter"
                placeholder={placeholderText}
                aria-label="Filter Input"
                onChange={(e) => setGlobalFilter(String(e.target.value))}
                value={globalFilter ?? ""}
              />
              {globalFilter.length > 0 && (
                <Button
                  variant="outline-secondary"
                  className="text-danger"
                  onClick={clearFilter}
                >
                  <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                </Button>
              )}
            </InputGroup>
          </Col>
          <Col className="col-auto text-end">
            <ButtonGroup>
              <Button
                variant="secondary"
                size="sm"
                onClick={resetAll}
                className="tableControl-refresh"
              >
                <FontAwesomeIcon icon={faRedoAlt} fixedWidth />
              </Button>
              <DropdownButton
                as={ButtonGroup}
                variant="secondary"
                align="end"
                title={<FontAwesomeIcon icon={faSlidersH} fixedWidth />}
                id="dropdown-menu-align-end"
                size="sm"
              >
                <Dropdown.Header className="p-0 mx-2">Limit</Dropdown.Header>
                <Dropdown.ItemText className="p-0 mx-2">
                  <Form.Select
                    size="sm"
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                      table.setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </Form.Select>
                </Dropdown.ItemText>
                <Dropdown.Header className="p-0 mx-2 mt-2">
                  Jump to
                </Dropdown.Header>
                <Dropdown.ItemText className="p-0 mx-2">
                  <Form.Control
                    size="sm"
                    min="1"
                    max={table.getPageCount()}
                    className="d-inline-block"
                    type="number"
                    defaultValue={table.getState().pagination.pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      table.setPageIndex(page);
                    }}
                  />
                </Dropdown.ItemText>
              </DropdownButton>
            </ButtonGroup>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-2 position-relative">
        {isFetching || reload ? (
          <React.Fragment>
            <div
              id="loadingTable"
              className="position-absolute top-50 start-50 translate-middle w-100 h-100 bg-light"
              style={{ opacity: 0.65 }}
            ></div>
            <div
              className="text-center text-primary position-absolute top-50 start-50 translate-middle"
              style={{ opacity: 1 }}
            >
              {isFetching || isLoading ? (
                <Spinner animation="border" variant="primary" />
              ) : isError ? (
                <div>Error: {error.message}</div>
              ) : (
                ""
              )}
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
        <Table
          className="font-monospace fs-medium user-select-none"
          hover
          size="sm"
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: (
                              <FontAwesomeIcon icon={faCaretUp} fixedWidth />
                            ),
                            desc: (
                              <FontAwesomeIcon icon={faCaretDown} fixedWidth />
                            ),
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody style={{ minHeight: 300 }}>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer className="p-2 text-muted">
        <DataTablePagination table={table} />
      </Card.Footer>
    </Card>
  );
};

export default DataTable;
