import React, { useState } from "react";
import { Button, FormControl, InputGroup, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";

const InlineFormControl = ({
  defaultValue,
  field,
  handleLabelChange,
  handleLabelKeyDown,
}) => {
  return (
    <FormControl
      id="inlineFormControl"
      autoFocus={true}
      size="sm"
      type="text"
      placeholder={`Add ${field}`}
      aria-label={`Add ${field}`}
      defaultValue={defaultValue}
      onChange={handleLabelChange}
      onKeyDown={handleLabelKeyDown}
      autoComplete="off"
    />
  );
};

export const InlineEdit = ({
  entry,
  field,
  sibling,
  handleLabel,
  setEditMode,
}) => {
  const [label, setLabel] = useState(entry[field]);
  const [show, setShow] = useState(true);
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1420px)" });
  const [display] = useState(isLargeScreen ? "inline" : "modal");
  const handleClose = () => setShow(false);
  const handleExit = () => setEditMode(false);

  const handleLabelChange = (e) => {
    setLabel(e.target.value);
  };

  const handleLabelKeyDown = (e) => {
    if (e.key === "Enter") {
      setLabel(e.target.value);
      commitLabelChange();
    }
  };

  const commitLabelChange = () => {
    if (entry[field] === label) {
      if (display === "inline") {
        return setEditMode(false);
      }
      return setShow(false);
    }
    entry[field] = label;
    handleLabel(entry);
  };

  if (display === "inline") {
    return (
      <InputGroup>
        <InlineFormControl
          key="inlineformControl"
          field={field}
          defaultValue={entry[field]}
          handleLabelChange={handleLabelChange}
          handleLabelKeyDown={handleLabelKeyDown}
        />
        <Button
          variant="outline-secondary"
          className="text-success"
          disabled={label.length < 1}
          onClick={() => commitLabelChange()}
        >
          <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
        </Button>
      </InputGroup>
    );
  } else if (display === "modal") {
    return (
      <React.Fragment>
        <Modal
          show={show}
          onHide={handleClose}
          onExited={handleExit}
          autoFocus={false}
          enforceFocus={false}
        >
          <Modal.Header closeButton>
            <Modal.Title as="h5">
              <FontAwesomeIcon className="me-2" icon={faEdit} />
              <span className="text-capitalize">{field}</span>
              {" for "}
              <span className="small">{entry[sibling]}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InlineFormControl
              key="modalFormControl"
              field={field}
              defaultValue={entry[field]}
              handleLabelChange={handleLabelChange}
              handleLabelKeyDown={handleLabelKeyDown}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" size="sm" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="success"
              size="sm"
              disabled={false}
              onClick={() => commitLabelChange()}
            >
              <FontAwesomeIcon
                className="me-2"
                icon={faCheckCircle}
                fixedWidth
              />
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        {entry[field]}
      </React.Fragment>
    );
  } else {
    return entry[field];
  }
};

const exportable = {
  InlineEdit,
};

export default exportable;
