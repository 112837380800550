import React from "react";
import ApexCharts from "react-apexcharts";
import { Card, Dropdown, Spinner } from "react-bootstrap";
import { Calendar } from "react-feather";
import { getData } from "../../service/Service";
import usePalette from "../../hooks/usePalette";
import useTheme from "../../hooks/useTheme";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var LocalizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const datasets = [
  { set: "recent", title: "Last 24 Hours", summary: "30 Minute Candles" },
  { set: "month", title: "Last Month", summary: "4 Hour Candles" },
  { set: "history", title: "All Time", summary: "4 Day Candles" },
];

const CandleChart = ({ reload }) => {
  const palette = usePalette();
  const appTheme = useTheme();
  const [chartData, setChartData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [range, setRange] = React.useState(false);
  const [volChart, setVolChart] = React.useState([]);
  const [filter, setFilter] = React.useState("recent");
  const [filterTitle, setFilterTitle] = React.useState("Last 24 Hours");
  const [filterSummary, setFilterSummary] = React.useState("30 Minute Candles");
  const [chartOptions] = React.useState({
    chart: {
      id: "chart2",
      theme: appTheme.theme,
      foreColor: palette["gray-600"],
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
        },
      },
    },
    stroke: {
      width: 1,
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      labels: {
        style: {
          cssClass: "font-monospace smaller",
        },
      },
    },
    colors: [palette.primary, palette.success],
    grid: {
      show: true,
      borderColor: palette["gray-200"],
      strokeDashArray: 0,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
    },
    tooltip: {
      theme: appTheme.theme,
      style: {
        fontSize: "10px",
      },
    },
  });
  const [volChartOptions] = React.useState({
    chart: {
      id: "chart1",
      height: 130,
      type: "bar",
      theme: appTheme.theme,
      foreColor: palette["gray-600"],
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      brush: {
        target: "chart2",
        enabled: true,
        autoScaleYaxis: true,
      },
      selection: {
        enabled: true,
        fill: {
          color: palette["gray-300"],
          opacity: 0.4,
        },
        xaxis: {
          type: "datetime",
        },
      },
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYaxis: true,
      },
    },
    colors: [palette.info],
    stroke: {
      width: 1,
    },
    grid: {
      show: true,
      borderColor: palette["gray-200"],
      strokeDashArray: 0,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      tickAmount: 5,
      labels: {
        style: {
          cssClass: "font-monospace smaller",
        },
        formatter: (val) => {
          return Number(val).toLocaleString("en", {
            maximumFractionDigits: 2,
          });
        },
      },
    },
  });
  const fetchData = React.useCallback((filter) => {
    // Set the loading state
    setLoading(true);

    const params = {
      service: "market",
      method: "getOhlcChart",
      filter: filter,
    };

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setRange(payload.summary);
            setChartData(payload.chart);
            setVolChart(payload.volume);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const loadNew = React.useCallback(
    (data) => {
      setFilter(data.set);
      setFilterTitle(data.title);
      setFilterSummary(data.summary);
      fetchData(data.set);
    },
    [fetchData]
  );

  React.useEffect(() => {
    if (reload) {
      fetchData(filter);
    }
  }, [fetchData, reload, filter]);

  return (
    <Card className="flex-fill shadow-lg">
      <Card.Header>
        <div className="card-actions float-end">
          <Dropdown drop="down-centered">
            <Dropdown.Toggle
              variant="light"
              className="bg-white shadow-sm"
              size="sm"
            >
              <Calendar className="feather align-middle" />{" "}
              <span className="mx-2">{filterTitle}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {datasets.map((data) => {
                return (
                  <Dropdown.Item
                    key={data.set}
                    onClick={() => {
                      loadNew(data);
                    }}
                    active={filter === data.set}
                  >
                    {data.title}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title tag="h5" className="mb-0">
          {filterTitle} {loading && <Spinner animation="border" size="sm" />}
          <br />
          <span className="small text-secondary">{filterSummary}</span>
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          <span className="small text-center">
            {!!range && dayjs.unix(range.x.start).format("L LT")} &gt;{" "}
            {!!range && dayjs.unix(range.x.end).format("L LT")}
          </span>
        </Card.Subtitle>
      </Card.Header>
      <Card.Body>
        {chartData.length > 0 && (
          <div className="chart">
            <ApexCharts
              options={chartOptions}
              series={chartData}
              type="candlestick"
              height="400"
            />
            {volChart.length > 0 && (
              <ApexCharts
                type="bar"
                options={volChartOptions}
                series={volChart}
                height="140"
              />
            )}
          </div>
        )}
      </Card.Body>
      <Card.Footer>
        <span className="text-secondary small">Data provided by CoinGecko</span>
      </Card.Footer>
    </Card>
  );
};

export default CandleChart;
