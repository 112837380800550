import React from "react";
import { Button, Dropdown, ButtonGroup, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";

const refreshTimes = [0, 30, 60, 120];

const RefreshButtons = ({ loading, callback }) => {
  const [refreshInterval, setRefreshInterval] = React.useState(60000);
  const [countdown, setCountdown] = React.useState(refreshInterval / 1000);
  const [reset, shouldReset] = React.useState(false);
  const callbackMethod = React.useCallback(() => {
    if (typeof callback === "undefined") {
      return false;
    } else {
      callback();
    }
  }, [callback]);

  React.useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      let count = refreshInterval / 1000;
      const interval = setInterval(() => {
        if (count > 0) {
          count--;
        }
        if (count === 0) {
          count = refreshInterval / 1000;
          callbackMethod();
        }
        setCountdown(count);
      }, 1000);
      if (reset) {
        setCountdown(refreshInterval / 1000);
        shouldReset(false);
      }
      //if (!document.hasFocus()) {
      //  clearInterval(interval);
      //}
      //window.addEventListener('focus', play);
      return () => {
        clearInterval(interval);
      };
    }
  }, [refreshInterval, callbackMethod, reset]);

  const handleRefresh = () => {
    setCountdown(refreshInterval / 1000);
    shouldReset(true);
    callbackMethod();
  };

  return (
    <Dropdown as={ButtonGroup} className="float-end" key="interval" size="sm">
      {refreshInterval >= 10000 && (
        <Button
          variant="light"
          onClick={() => handleRefresh()}
          className="text-center font-monospace"
          disabled
        >
          <span className="text-info mt-2">{countdown}</span>
        </Button>
      )}
      <Button
        variant="light"
        onClick={() => handleRefresh()}
        className="tableControl-refresh"
      >
        {!loading ? (
          <FontAwesomeIcon icon={faRedoAlt} />
        ) : (
          <Spinner animation="grow" size="sm" className="align-text-bottom" />
        )}
      </Button>
      <Dropdown.Toggle split variant="light" id="dd-interval" />
      <Dropdown.Menu>
        {refreshTimes.map((val, i) => {
          return (
            <Dropdown.Item
              eventKey={i}
              key={`refreshkey-${i}`}
              onClick={() => {
                setRefreshInterval(val * 1000);
              }}
              active={refreshInterval === val * 1000}
              className="small"
            >
              {val === 0 ? "Off" : val + " seconds"}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default RefreshButtons;
