import React from "react";
import { Badge, Card, Spinner } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { getData } from "../../service/Service";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var LocalizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const CurrentPriceCard = ({ reload }) => {
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const fetchData = React.useCallback(() => {
    // Set the loading state
    setLoading(true);

    const params = {
      service: "market",
      method: "getRecentPrice",
    };

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setData(payload);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  return (
    <Card className="flex-grow-1 align-self-stretch shadow-lg">
      <Card.Header>
        {data && (
          <div className="float-end">
            <Badge
              bg={data.price_change_percentage_24h < 0 ? "danger" : "success"}
            >
              {Number(data.price_change_percentage_24h).toLocaleString("en", {
                maximumFractionDigits: 2,
              }) + "%"}
            </Badge>
          </div>
        )}
        <Card.Title tag="h5" className="mb-0">
          <span className="text-uppercase">{data.symbol}</span>
          {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body className="py-4">
        <div className="mb-1 fs-4">
          <strong className="text-dark fs-3">
            $
            <NumericFormat
              value={data.current_price}
              displayType={"text"}
              thousandSeparator={true}
              defaultValue={0}
              decimalScale={8}
              fixedDecimalScale={true}
            />
          </strong>
        </div>
        <div className="fs-5">
          <NumericFormat
            value={data.sats}
            displayType={"text"}
            thousandSeparator={true}
            defaultValue={0}
            decimalScale={2}
            fixedDecimalScale={true}
          />{" "}
          sats
        </div>
        <div className="small">
          Volume: $
          <NumericFormat
            value={data.total_volume}
            displayType={"text"}
            thousandSeparator={true}
            defaultValue={0}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </div>
        <div className="small">
          Market Cap: $
          <NumericFormat
            value={data.market_cap}
            displayType={"text"}
            thousandSeparator={true}
            defaultValue={0}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="text-secondary float-end">
          <span className="small me-2 align-middle">
            Updated {dayjs(data.last_updated).fromNow()}{" "}
          </span>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default CurrentPriceCard;
