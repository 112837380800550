import React from "react";
import Chart from "react-apexcharts";
import { Card, Dropdown, Spinner } from "react-bootstrap";
import { DollarSign } from "react-feather";
import { getData } from "../../service/Service";
import usePalette from "../../hooks/usePalette";
import useTheme from "../../hooks/useTheme";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var LocalizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const datasets = [
  { set: "getRTMPriceChart", title: "Price" },
  { set: "getRTMMarketCapChart", title: "Market Cap" },
  { set: "getRTMVolumeChart", title: "Volume" },
];

const IndicatorsChart = ({ reload }) => {
  const palette = usePalette();
  const appTheme = useTheme();
  const [chartData, setChartData] = React.useState([]);
  const [method, setMethod] = React.useState("getRTMPriceChart");
  const [methodTitle, setMethodTitle] = React.useState("Price");
  const [chartOptions] = React.useState({
    chart: {
      theme: appTheme.theme,
      foreColor: palette["gray-600"],
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      show: true,
      showAlways: true,
      tickAmount: 5,
      labels: {
        style: {
          cssClass: "font-monospace smaller",
        },
        formatter: (val) => {
          return Number(val).toLocaleString("en", {
            maximumFractionDigits: 6,
          });
        },
      },
    },
    tooltip: {
      theme: appTheme.theme,
      style: {
        fontSize: "10px",
      },
      x: {
        formatter: (val) => {
          return dayjs(val).format("L LT");
        },
      },
    },
    grid: {
      show: true,
      borderColor: palette["gray-200"],
      strokeDashArray: 0,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
    },
    colors: [
      palette.primary,
      palette.success,
      palette.warning,
      palette.danger,
      palette.info,
    ],
  });
  const [loading, setLoading] = React.useState(true);

  const fetchData = React.useCallback((method) => {
    // Set the loading state
    setLoading(true);

    const params = {
      service: "market",
      method: method,
    };

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setChartData(payload);
          }
        }
      })
      .finally(() => {
        //console.log(data[0].data[data[0].data.length - 1].x);
        setLoading(false);
      });
  }, []);

  const loadNew = React.useCallback(
    (data) => {
      setMethod(data.set);
      setMethodTitle(data.title);
      fetchData(data.set);
    },
    [fetchData]
  );

  React.useEffect(() => {
    if (reload) {
      fetchData(method);
    }
  }, [fetchData, reload, method]);

  return (
    <Card className="flex-fill shadow-lg">
      <Card.Header>
        <Card.Title tag="h5">
          <div className="card-actions float-end">
            <Dropdown drop="down-centered">
              <Dropdown.Toggle
                variant="light"
                className="bg-white shadow-sm"
                size="sm"
              >
                <DollarSign className="feather align-middle" />{" "}
                <span className="mx-2">{methodTitle}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {datasets.map((data) => {
                  return (
                    <Dropdown.Item
                      key={data.set}
                      onClick={() => {
                        loadNew(data);
                      }}
                      active={method === data.set}
                    >
                      {data.title}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          Raptoreum {methodTitle} History{" "}
          {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {chartData.length > 0 && (
          <div className="chart w-100">
            <Chart
              options={chartOptions}
              series={chartData}
              type="area"
              height="350"
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default IndicatorsChart;
