import React from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { getData } from "../../../service/Service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import { truncate } from "../../../utils/Formatters";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var updateLocale = require("dayjs/plugin/updateLocale");
var utc = require("dayjs/plugin/utc");
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "1 minute",
    mm: "%d minutes",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day",
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years",
  },
});

const AddressRecentCard = ({ sendWallets, addresses, reload }) => {
  // We'll start our table without any data
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getWalletLabel = (address) => {
    const filter = sendWallets.filter((entry) => {
      return entry.address === address;
    });
    if (filter.length === 1) {
      return filter[0].label;
    } else {
      return (
        <React.Fragment>
          <span className="d-none d-xl-inline">{address}</span>
          <span className="d-xl-none">{truncate(address, 10)}</span>
        </React.Fragment>
      );
    }
  };

  const fetchData = React.useCallback(() => {
    // Set the loading state
    setLoading(true);
    const params = {
      service: "explorer",
      method: "getAddressTxList",
      address: addresses.join(","),
      limit: 5,
    };

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setData(payload);
          }
        } else {
          console.log(data);
        }
      })
      .then(() => {
        setLoading(false);
      });
  }, [addresses]);

  React.useEffect(() => {
    if (!!addresses || reload) {
      fetchData();
    }
  }, [addresses, fetchData, reload]);

  return (
    <Card className="border" border="light">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Recent Transactions{" "}
          {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body className="p-0">
        <Table className="mb-0" borderless hover>
          <tbody>
            {!!sendWallets &&
              data.map((tx, i) => {
                return (
                  <tr
                    key={`tr-recent-${i}`}
                    className={
                      tx.status === "Pending"
                        ? "text-dark border-bottom border-light"
                        : "border-bottom border-light"
                    }
                  >
                    <td className="col-sm-1">
                      <FontAwesomeIcon
                        icon={
                          tx.is_spent
                            ? faArrowRightFromBracket
                            : faArrowRightToBracket
                        }
                        fixedWidth
                        className="text-secondary"
                        title={tx.status}
                        size="2x"
                      />
                    </td>
                    <td>
                      <Row>
                        <Col>
                          <OverlayTrigger
                            key={`age-${tx.txid}`}
                            placement="top"
                            delay={{ show: 500, hide: 100 }}
                            overlay={
                              <Tooltip id={`tooltip-age-${tx.txid}`}>
                                {tx.time_format}
                              </Tooltip>
                            }
                          >
                            <span>
                              {dayjs.unix(tx.timestamp).fromNow(false)}
                            </span>
                          </OverlayTrigger>
                        </Col>
                        <Col className="text-end font-monospace">
                          <NumericFormat
                            value={tx.satoshis_format}
                            displayType={"text"}
                            thousandSeparator={true}
                            defaultValue={0}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>{getWalletLabel(tx.address)}</Col>
                      </Row>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default AddressRecentCard;
