import React from "react";
import { VectorMap } from "react-jvectormap";
import { Map } from "react-feather";
import {
  Card,
  Col,
  Dropdown,
  Nav,
  Row,
  Spinner,
  Tab,
  Table,
} from "react-bootstrap";
import usePalette from "../../../hooks/usePalette";
import { getData } from "../../../service/Service";

const maps = [
  { map: "world_mill", title: "World" },
  { map: "africa_mill", title: "Africa" },
  { map: "asia_mill", title: "Asia" },
  { map: "ch_mill", title: "China" },
  { map: "europe_mill", title: "Europe" },
  { map: "north_america_mill", title: "North America" },
  { map: "oceania_mill", title: "Oceania" },
  { map: "south_america_mill", title: "South America" },
  { map: "us_aea", title: "USA" },
];

const NodeMap = ({ reload }) => {
  const palette = usePalette();
  const [markers, setMarkers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [mapFile, setMapFile] = React.useState("world_mill");
  const [mapTitle, setMapTitle] = React.useState("World");
  const [summary, setSummary] = React.useState(false);

  const fetchData = React.useCallback(() => {
    // Set the loading state
    setLoading(true);

    const params = {
      service: "statistics",
      method: "getNodeMapMarkers",
    };

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            const payloadSummary = data[params.method].summary;
            setMarkers(payload);
            setSummary(payloadSummary);
          }
        } else {
          console.log(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Listen for changes in markers and use the state to fetch our new data
  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload, mapFile]);

  const MapDisplay = () => {
    return <VectorMap {...options} markers={markers} />;
  };

  const options = {
    map: mapFile,
    hoverOpacity: 0.7,
    hoverColor: false,
    zoomOnScroll: true,
    zoomAnimate: true,
    regionStyle: {
      initial: {
        fill: palette["gray-200"],
      },
    },
    containerStyle: {
      width: "100%",
      height: "100%",
    },
    markerStyle: {
      initial: {
        r: 3,
        fill: palette.primary,
        "fill-opacity": 0.9,
        stroke: palette.white,
        "stroke-width": 3,
        "stroke-opacity": 0.4,
      },
      //hover: {
      //  fill: palette.primary,
      //  "fill-opacity": 0.9,
      //  stroke: palette.primary,
      //  "stroke-width": 7,
      //  "stroke-opacity": 0.4,
      //},
    },
    backgroundColor: "transparent",
    markers: [],
    series: {
      markers: [
        {
          attribute: "fill",
          scale: {
            ENABLED: palette.success,
            POSE_BANNED: palette.danger,
            UNKNOWN: palette["gray-500"],
          },
          values: markers.reduce(function (p, c, i) {
            p[i] = c.status;
            return p;
          }, {}),
        },
      ],
    },
  };

  return (
    <Card className="flex-grow-1 align-self-stretch shadow-lg">
      <Card.Header>
        <div className="card-actions float-end mb-0">
          <Dropdown drop="down-centered">
            <Dropdown.Toggle
              variant="light"
              className="bg-white shadow-sm"
              size="sm"
            >
              <Map className="feather align-middle" />{" "}
              <span className="mx-2">{mapTitle}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {maps.map((mapItem) => {
                return (
                  <Dropdown.Item
                    key={mapItem.map}
                    onClick={() => {
                      setMapFile(mapItem.map);
                      setMapTitle(mapItem.title);
                    }}
                    active={mapFile === mapItem.map}
                  >
                    {mapItem.title}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title tag="h5" className="mb-0">
          Smartnode Distribution{" "}
          {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Tab.Container id="nodesdata-tabs" defaultActiveKey="first">
          <Row>
            <Col>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  {!!markers && (
                    <div className="map-container" style={{ height: 300 }}>
                      <MapDisplay />
                    </div>
                  )}
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  style={{ height: 300 }}
                  className="overflow-scroll"
                >
                  {!!summary && (
                    <Table size="sm">
                      <tbody>
                        {summary.map((item, i) => {
                          return (
                            <tr key={"nodetable-" + i}>
                              <td>{item.country_name}</td>
                              <td>{item.total}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              {!!summary && (
                <Nav variant="pills" defaultActiveKey="first">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Map</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Table</Nav.Link>
                  </Nav.Item>
                </Nav>
              )}
            </Col>
          </Row>
        </Tab.Container>
      </Card.Body>
    </Card>
  );
};

export default NodeMap;
