import React from "react";
import { Card, Spinner } from "react-bootstrap";
import { getData } from "../../../service/Service";

const ChainsizeCard = ({ reload }) => {
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const fetchData = React.useCallback(() => {
    const params = {
      service: "statistics",
      method: "chain",
    };

    setLoading(true);

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setData(payload);
          }
        } else {
          console.log(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  return (
    <Card className="flex-grow-1 align-self-stretch shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Chain Size {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {!!data && (
          <React.Fragment>
            <span className="fs-2">{data.size_on_disk_format}</span>
            <br />
            <span className="text-secondary small">
              {data.pruned ? "Pruned: true" : "Pruned: false"}
            </span>
          </React.Fragment>
        )}
      </Card.Body>
    </Card>
  );
};

export default ChainsizeCard;
