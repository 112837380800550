import axios from "axios";

export const endpoint = process.env.REACT_APP_API_ENDPOINT;

async function loadEndpoint({ ...params }) {
  //endpoint, page, limit, dir, sort, sortBy, filter
  let query_str = endpoint + params.service + "/" + params.method + "/?";
  let query_params = [];
  if (typeof params.page !== "undefined" && params.page >= 0) {
    query_params.push("page=" + parseInt(params.page + 1));
  }

  if (typeof params.limit !== "undefined" && params.limit > 0) {
    query_params.push("limit=" + parseInt(params.limit));
  }

  if (typeof params.dir !== "undefined" && params.dir !== "") {
    query_params.push("dir=" + params.dir);
  }

  if (typeof params.sort !== "undefined" && params.sort !== "") {
    query_params.push("sort=" + params.sort);
  }

  if (typeof params.filter !== "undefined" && params.filter !== "") {
    query_params.push("filter=" + params.filter);
  }

  if (typeof params.height !== "undefined" && params.height !== "") {
    query_params.push("height=" + parseInt(params.height));
  }

  if (typeof params.tx !== "undefined" && params.tx !== "") {
    query_params.push("tx=" + params.tx);
  }

  if (typeof params.address !== "undefined" && params.address !== "") {
    query_params.push("address=" + params.address);
  }

  if (typeof params.addresses !== "undefined" && params.addresses !== "") {
    query_params.push("addresses=" + JSON.stringify(params.addresses));
  }

  if (typeof params.range !== "undefined" && params.range !== "") {
    query_params.push("range=" + params.range);
  }

  if (typeof params.from !== "undefined" && params.from !== "") {
    query_params.push("from=" + params.from);
  }

  if (typeof params.to !== "undefined" && params.to !== "") {
    query_params.push("to=" + params.to);
  }

  if (typeof params.node !== "undefined" && params.node !== "") {
    query_params.push("node=" + params.node);
  }

  if (typeof params.provider !== "undefined" && params.provider !== "") {
    query_params.push("provider=" + params.provider);
  }

  if (typeof params.apiKey !== "undefined" && params.apiKey !== "") {
    query_params.push("apiKey=" + params.apiKey);
  }

  if (typeof params.symbol !== "undefined" && params.symbol !== "") {
    query_params.push("symbol=" + params.symbol);
  }

  query_str += query_params.join("&");

  return axios
    .get(query_str)
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
    })
    .then((response) => {
      return response;
    });
}

export const getData = ({ ...params }) => {
  //page, limit, dir, sort, sortBy, filter
  return loadEndpoint({ ...params });
};

export const postData = async ({ ...params }) => {
  let endpoint_str = endpoint + params.service + "/" + params.method + "/";
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
  return axios
    .post(endpoint_str, params.data, axiosConfig)
    .then((response) => response.data)
    .catch((err) => {
      console.log(err);
    })
    .then((response) => {
      return response;
    });
};

const exportedObject = { endpoint, getData, postData };

export default exportedObject;
