import React from "react";
import { Card, ProgressBar, Spinner } from "react-bootstrap";
import { getData } from "../../../service/Service";
import { useTranslation } from "react-i18next";

const MempoolCard = ({ reload }) => {
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { t } = useTranslation();

  const fetchData = React.useCallback(() => {
    const params = {
      service: "explorer",
      method: "getMempool",
    };

    setLoading(true);

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setData(payload);
          }
        } else {
          console.log(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  return (
    <Card className="flex-grow-1 align-self-stretch shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {t("Transaction Mempool")}
          {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {!!data && (
          <React.Fragment>
            <div className="d-block">
              <span className="fs-1">{data.unconfirmed}</span>{" "}
              <span className="fs-4 ms-1">TXs</span>
            </div>
            <span className="text-muted">{data.usage_format}</span>
            <div className="small">
              <span className="text-muted">{data.mempool_percent}</span>{" "}
              <span className="text-secondary">{data.maxmempool_format}</span>
            </div>
          </React.Fragment>
        )}
        <ProgressBar
          variant="primary"
          animated
          style={{ height: 5 }}
          now={!!data && data.mempool_percent}
        />
      </Card.Body>
    </Card>
  );
};

export default MempoolCard;
