import React from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import spaceman from "../../../assets/img/illustrations/spaceman.png";

const NewUserCard = () => {
  return (
    <Card className="text-center shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Hey! You look new around here.
        </Card.Title>
      </Card.Header>
      <Card.Body className="p-0 text-bg-info">
        <img
          src={spaceman}
          alt="welcome spaceman"
          className="float-end"
          width="100%"
        />
      </Card.Body>
      <Card.Footer>
        <Link to={"/settings/portfolio"} className="text-reset">
          <Button variant="outline-info" size="lg" className="w-100">
            Let's set up a portfolio.
          </Button>
        </Link>
      </Card.Footer>
    </Card>
  );
};

export default NewUserCard;
