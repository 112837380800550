import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Nav, Row, Spinner, Tab } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
//import { Transaction } from "raptoreumcore-lib";
import { WalletBreadcrumbs } from "../../components/Breadcrumbs";
import Raptorio from "../../service/Raptorio";
import { getData } from "../../service/Service";

import Send from "./Send";
//import Receive from "./Receive";
import Addresses from "../settings/portfolio/Addresses";
//import AddressTxCard from "./components/AddressTxCard";
import AddressTxCard from "../explorer/components/AddressTxCard";
import AddressRecentCard from "./components/AddressRecentCard";
const raptorio = new Raptorio({ coin: "RTM" });

const Wallet = () => {
  const [loading, setLoading] = React.useState(false);
  const [portfolio, setPortfolio] = React.useState(false);
  const [refreshInterval] = React.useState(10000);

  const [changeWallet, setChangeWallet] = React.useState(false); //as in where you keep your pocket change
  const [sendWallets, setSendWallets] = React.useState(false);
  const [addresses, setAddresses] = React.useState(false);
  const [addressLabels, setAddressLabels] = React.useState(false);

  const [balances, setBalances] = React.useState(false);
  const [addressBook, setAddressBook] = React.useState(false);

  const fetchPortfolio = async () => {
    return raptorio.getDefaultPortfolio().then((data) => {
      if (data && data.length === 1) {
        setPortfolio(data[0]);
      }
    });
  };

  const fetchAddresses = async () => {
    return raptorio.getAddresses("native").then((wallets) => {
      let walletAddresses = [];
      let walletLabels = [];
      //setWallets(data);
      wallets.map((wallet) => {
        let labels = {};
        labels.address = wallet.address;
        labels.label = wallet.label;
        walletLabels.push(labels);
        walletAddresses.push(wallet.address);
        return true;
      });
      setChangeWallet(walletAddresses[0]);
      setAddresses(walletAddresses);
      setAddressLabels(walletLabels);
    });
  };

  const fetchAddressBook = async () => {
    return raptorio.getAddresses("addressbook").then((wallets) => {
      if (wallets.length > 0) {
        let addressbook = [];
        //setWallets(data);
        wallets.map((wallet) => {
          return addressbook.push(wallet);
        });
        setAddressBook(addressbook);
      }
    });
  };

  const fetchBalances = React.useCallback(async () => {
    const params = {
      service: "explorer",
      method: "getAddressBalance",
      address: addresses.join(","),
    };

    return getData(params).then((data) => {
      if (data.status === "success") {
        if (typeof data[params.method].summary !== "undefined") {
          const payload = data[params.method].results;
          const balances = data[params.method].summary;
          const addressesWBal = payload.map((add) => add.address);
          setBalances(balances);
          setSendWallets(addressesWBal);
          //setSelectedWallet(merged[0].address);
          //setChangeWallet(merged[0].address);
        }
      } else {
        setBalances(0);
        //console.log(data);
        //setSendWallets(walletData.walletAddresses);
      }
    });
  }, [addresses]);

  React.useEffect(() => {
    const fetchData = () => {
      if (!portfolio) {
        // Set the loading state
        setLoading(true);
        fetchPortfolio();
        fetchAddresses();
        //fetchAddresses();
        fetchAddressBook();
        //fetchBalances();
        setLoading(false);
      }
    };

    if (!portfolio) {
      fetchData();
    }
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(fetchData, refreshInterval);
      return () => {
        clearInterval(interval);
      };
    }
  }, [portfolio, refreshInterval]);

  React.useEffect(() => {
    if (!!addresses) {
      fetchBalances();
    }
  }, [addresses, fetchBalances]);

  return (
    <React.Fragment>
      <Helmet title="Wallet" />
      {!portfolio && !loading ? (
        "Need to create portfolio"
      ) : (
        <Container fluid className="p-0">
          <Row>
            <Col>
              <WalletBreadcrumbs tabname="Wallet" />
            </Col>
            <Col className="text-end">
              Currently using portfolio{" "}
              <em className="text-primary">{portfolio.label}</em>
            </Col>
          </Row>
          <div className="wallet-ui">
            <Tab.Container id="wallet-tabs" defaultActiveKey="overview">
              <Nav variant="tabs" fill>
                <Nav.Item>
                  <Nav.Link eventKey="overview">Overview</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="send">Send</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="receive">Receive</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="transactions">Transactions</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="overview">
                  <Row className="p-4">
                    <Col sm="4">
                      <Card className="border" border="light">
                        <Card.Header>
                          <Card.Title tag="h5" className="mb-0">
                            Balances{" "}
                            {loading && (
                              <Spinner animation="border" size="sm" />
                            )}
                          </Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <Row>
                            <Col>Available:</Col>
                            <Col className="text-end font-monospace">
                              <NumericFormat
                                value={balances.total_balance}
                                displayType={"text"}
                                thousandSeparator={true}
                                defaultValue={0}
                                decimalScale={8}
                                fixedDecimalScale={true}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>Pending:</Col>
                            <Col className="text-end font-monospace">
                              <NumericFormat
                                value={balances.pending_balance}
                                displayType={"text"}
                                thousandSeparator={true}
                                defaultValue={0}
                                decimalScale={8}
                                fixedDecimalScale={true}
                              />
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col>Total:</Col>
                            <Col className="text-end font-monospace">
                              <NumericFormat
                                value={
                                  balances.total_balance +
                                  balances.pending_balance
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                defaultValue={0}
                                decimalScale={8}
                                fixedDecimalScale={true}
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <AddressRecentCard
                        sendWallets={sendWallets}
                        addresses={addresses}
                        reload={loading}
                      />
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="send">
                  <Send
                    raptorio={raptorio}
                    sendWallets={sendWallets}
                    addressLabels={addressLabels}
                    addresses={addresses}
                    portfolio={portfolio}
                    changeWallet={changeWallet}
                    addressBook={addressBook}
                    balances={balances}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="receive" className="p-0">
                  <Addresses
                    key="native-addresses"
                    category="native"
                    name="Addresses"
                    raptorio={raptorio}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="transactions" className="p-2">
                  {!!addresses && (
                    <div>
                      <AddressTxCard
                        address={addresses.join(",")}
                        reload={loading}
                      />
                    </div>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Container>
      )}
    </React.Fragment>
  );
};

export default Wallet;
