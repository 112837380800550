import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Col, Row } from "react-bootstrap";

import Statistics from "./Statistics";
import Raptorio from "../../service/Raptorio";
import Header from "./Header";
import PortfolioDetailsCard from "../portfolio/components/PortfolioDetailsCard";

const raptorio = new Raptorio({ coin: "RTM" });

const Default = () => {
  const [loading, setLoading] = React.useState(true);
  const [portfolio, setPortfolio] = React.useState(false);
  const refreshInterval = React.useRef(60000);

  const fetchData = React.useCallback(() => {
    // Set the loading state
    setLoading(true);

    const getPortfolio = raptorio.getDefaultPortfolio();

    getPortfolio.then((data) => {
      if (data && data.length === 1) {
        setPortfolio(data[0]);
      }
    });
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  React.useEffect(() => {
    fetchData();

    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(fetchData, refreshInterval);
      return () => {
        clearInterval(interval);
      };
    }
  }, [fetchData, refreshInterval]);
  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Container fluid className="p-0">
        <Header portfolio={portfolio} reload={loading} />
        <Statistics
          portfolio={portfolio}
          reload={loading}
          raptorio={raptorio}
        />
        {!!portfolio && (
          <Row>
            <Col>
              <PortfolioDetailsCard raptorio={raptorio} />
            </Col>
          </Row>
        )}
      </Container>
    </React.Fragment>
  );
};

export default Default;
