var Promise = require("promise");

// Promise.loop([properties: object]): Promise()
//
//  Execute a loop based on promises. Object 'properties' is an optional
//  argument with the following fields:
//
//  initialization: function(): Promise() | any, optional
//
//      Function executed as part of the initialization of the loop. If
//      it returns a promise, the loop will not begin to execute until
//      it is resolved.
//
//      Any exception occurring in this function will finish the loop
//      with a rejected promise. Similarly, if this function returns a
//      promise, and this promise is reject, the loop finishes right
//      away with a rejected promise.
//
//  condition: function(): Promise(result: bool) | bool, optional
//
//      Condition evaluated in the beginning of each iteration of the
//      loop. The function should return a boolean value, or a promise
//      object that resolves with a boolean data value.
//
//      Any exception occurring during the evaluation of the condition
//      will finish the loop with a rejected promise. Similarly, it this
//      function returns a promise, and this promise is rejected, the
//      loop finishes right away with a rejected promise.
//
//      If no condition function is provided, an infinite loop is
//      executed.
//
//  body: function(): Promise() | any, optional
//
//      Function acting as the body of the loop. If it returns a
//      promise, the loop will not proceed until this promise is
//      resolved.
//
//      Any exception occurring in this function will finish the loop
//      with a rejected promise. Similarly, if this function returns a
//      promise, and this promise is reject, the loop finishes right
//      away with a rejected promise.
//
//  increment: function(): Promise() | any, optional
//
//      Function executed at the end of each iteration of the loop. If
//      it returns a promise, the condition of the loop will not be
//      evaluated again until this promise is resolved.
//
//      Any exception occurring in this function will finish the loop
//      with a rejected promise. Similarly, if this function returns a
//      promise, and this promise is reject, the loop finishes right
//      away with a rejected promise.
//
Promise.loop = function (properties) {
  // Default values
  properties = properties || {};
  properties.initialization = properties.initialization || function () {};
  properties.condition =
    properties.condition ||
    function () {
      return true;
    };
  properties.body = properties.body || function () {};
  properties.increment = properties.increment || function () {};

  // Start
  return new Promise(function (resolve, reject) {
    var runInitialization = function () {
      Promise.resolve()
        .then(function () {
          return properties.initialization();
        })
        .then(function () {
          process.nextTick(runCondition);
        })
        .catch(function (error) {
          reject(error);
        });
    };

    var runCondition = function () {
      Promise.resolve()
        .then(function () {
          return properties.condition();
        })
        .then(function (result) {
          if (result) process.nextTick(runBody);
          else resolve();
        })
        .catch(function (error) {
          reject(error);
        });
    };

    var runBody = function () {
      Promise.resolve()
        .then(function () {
          return properties.body();
        })
        .then(function () {
          process.nextTick(runIncrement);
        })
        .catch(function (error) {
          reject(error);
        });
    };

    var runIncrement = function () {
      Promise.resolve()
        .then(function () {
          return properties.increment();
        })
        .then(function () {
          process.nextTick(runCondition);
        })
        .catch(function (error) {
          reject(error);
        });
    };

    // Start running initialization
    process.nextTick(runInitialization);
  });
};

// Promise.delay(time: double): Promise()
//
//  Returns a promise that resolves after the given delay in seconds.
//
Promise.delay = function (time) {
  return new Promise(function (resolve) {
    setTimeout(resolve, time * 1000);
  });
};

export default Promise;
