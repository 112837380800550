import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Search: "Search ",
      "Welcome back": "Welcome back",
      "Good Morning": "Good Morning",
      "Good Afternoon": "Good Afternoon",
      "Good Evening": "Good Evening",
      Explorer: "Explorer",
      Dashboard: "Dashboard",
      Blocks: "Blocks",
      Addresses: "Addresses",
      Transactions: "Transactions",
      "Max Supply": "Max Supply",
      "Circulating Supply": "Circulating Supply",
      "Locked Supply": "Locked Supply",
      Hashrate: "Hashrate",
      Difficulty: "Difficulty",
      "Latest Blocks": "Latest Blocks",
      "Latest Transactions": "Latest Transactions",
    },
  },
  fr: {
    translation: {
      Search: "Rechercher ",
      "Welcome back": "Bon retour",
      "Good Morning": "Bonjour",
      "Good Afternoon": "Bonne après-midi",
      "Good Evening": "Bonsoir",
    },
  },
  de: {
    translation: {
      Search: "Suchen ",
      "Welcome back": "Willkommen zurück",
      "Good Morning": "Guten Morgen",
      "Good Afternoon": "Guten Tag",
      "Good Evening": "Guten Abend",
    },
  },
  nl: {
    translation: {
      Search: "Zoeken ",
      "Welcome back": "Welkom terug",
      "Good Morning": "Goedemorgen",
      "Good Afternoon": "Goedemiddag",
      "Good Evening": "Goedeavond",
    },
  },
  ru: {
    translation: {
      Search: "Поиск ",
      "Welcome back": "Добро пожаловать обратно",
      "Good Morning": "Доброе утро",
      "Good Afternoon": "Добрый день",
      "Good Evening": "Добрый вечер",
      Explorer: "Исследователь",
      Dashboard: "Приборная доска",
      Blocks: "Блоки",
      Addresses: "Адреса",
      Transactions: "Транзакции",
      "Max Supply": "Максимальный запас",
      "Circulating Supply": "Циркуляционное снабжение",
      "Locked Supply": "Заблокированная поставка",
      Hashrate: "Хешрейт",
      Difficulty: "Сложность",
      "Latest Blocks": "Последние блоки",
      "Latest Transactions": "Последние транзакции",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
