import React from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import {
  Alert,
  Badge,
  Card,
  Container,
  Table,
  Row,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { ExplorerSubBreadcrumbs } from "../../components/Breadcrumbs";
import { NumericFormat } from "react-number-format";
import { getData } from "../../service/Service";
import { VectorMap } from "react-jvectormap";
import usePalette from "../../hooks/usePalette";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var updateLocale = require("dayjs/plugin/updateLocale");
var utc = require("dayjs/plugin/utc");
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

const statusOptions = [
  {
    name: "ENABLED",
    value: "success",
  },
  {
    name: "POSE_BANNED",
    value: "danger",
  },
  {
    name: "UNKNOWN",
    value: "secondary",
  },
];

const statusClass = (data) => {
  let set = statusOptions.find((option) => {
    let value;
    if (data.status === option.name) {
      value = option.value;
      if (data.status === "ENABLED" && !parseInt(data.reachable)) {
        value = "warning";
      }
    }
    return value;
  });
  return set;
};

const poseClass = (data) => {
  let set = statusOptions.find((option) => {
    let value;
    if (data.status === option.name) {
      value = option.value;
      if (data.status === "ENABLED" && parseInt(data.pose_penalty) > 0) {
        value = "warning";
      }
    }
    return value;
  });
  return set;
};

const NodeLocation = ({ data }) => {
  const palette = usePalette();
  const options = {
    map: data.map_file,
    hoverOpacity: 0.7,
    hoverColor: false,
    zoomOnScroll: false,
    regionStyle: {
      initial: {
        fill: palette["gray-200"],
      },
    },
    containerStyle: {
      width: "100%",
      height: "100%",
    },
    markerStyle: {
      initial: {
        r: 9,
        fill: palette.primary,
        "fill-opacity": 0.9,
        stroke: palette.white,
        "stroke-width": 7,
        "stroke-opacity": 0.4,
      },
      hover: {
        fill: palette.primary,
        "fill-opacity": 0.9,
        stroke: palette.primary,
        "stroke-width": 7,
        "stroke-opacity": 0.4,
      },
    },
    backgroundColor: "transparent",
    markers: [
      {
        name: data.service,
        latLng: data.coords,
      },
    ],
  };
  return (
    <Card className="shadow-lg rounded match-height">
      <Card.Header className="bg-light bg-gradient p-2">
        <Card.Title tag="h6" className="m-0">
          Location
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="map-container" style={{ height: 294 }}>
          {data.map_file && <VectorMap {...options} />}
        </div>
      </Card.Body>
    </Card>
  );
};

const NodeLocationDetails = ({ data }) => {
  return (
    <Card className="shadow-lg rounded match-height">
      <Card.Header className="p-2">
        <Card.Title tag="h6" className="m-0">
          Locale
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table className="font-monospace fs-medium card-table" size="sm">
          <tbody>
            <tr>
              <th>Country:</th>
              <td>
                {data.country_name} (code: {data.country_code})
              </td>
            </tr>
            {!!data.region_name && (
              <tr>
                <th>Region:</th>
                <td>
                  {data.region_name} (code: {data.region_code})
                </td>
              </tr>
            )}
            {!!data.city && (
              <tr>
                <th>City/Zip:</th>
                <td>
                  {data.city} (code: {data.metro_code}) {data.zip_code}
                </td>
              </tr>
            )}
            <tr>
              <th>Timezone:</th>
              <td>{data.time_zone}</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const NodeService = ({ data }) => {
  return (
    <Card className="shadow-lg rounded match-height">
      <Card.Header className="p-2">
        <Card.Title tag="h6" className="m-0">
          Service
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table className="font-monospace fs-medium card-table" size="sm">
          <tbody>
            <tr>
              <th>IP:</th>
              <td>
                {data.service} Port {data.port}
              </td>
            </tr>
            <tr>
              <th>Status:</th>
              <td>
                {data.status && (
                  <Badge bg={statusClass(data).value}>{data.status}</Badge>
                )}
              </td>
            </tr>
            <tr>
              <th>Reachable:</th>
              <td>
                {parseInt(data.reachable) > 0 ? (
                  <span className="text-success">Yes</span>
                ) : (
                  <span className="text-danger">No</span>
                )}
              </td>
            </tr>
            <tr>
              <th>PoSe Penalty:</th>
              <td>
                {data.pose_penalty && (
                  <span className={"text-" + poseClass(data).value}>
                    {data.pose_penalty}
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <th>Collateral:</th>
              <td>
                <NumericFormat
                  value={data.collateral_amount}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </td>
            </tr>
            <tr>
              <th>Need to Upgrade?:</th>
              <td>
                {parseInt(data.need_to_upgrade) > 0 ? (
                  <span className="text-danger">Yes</span>
                ) : (
                  <span className="text-success">No</span>
                )}
              </td>
            </tr>
            <tr>
              <th>ProTX:</th>
              <td>
                <Link
                  to={"/explorer/tx/" + data.protx_hash}
                  className="cursor-zoom-in"
                >
                  {data.protx_hash}
                </Link>
              </td>
            </tr>
            <tr>
              <th>Operator Pubkey:</th>
              <td>{data.p2sh}</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const NodeHistory = ({ data }) => {
  return (
    <Card className="shadow-lg rounded match-height">
      <Card.Header className="p-2">
        <Card.Title tag="h6" className="m-0">
          History
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table className="font-monospace fs-medium card-table" size="sm">
          <tbody>
            {parseInt(data.revocation_reason) > 0 && (
              <tr>
                <th>Revocation Reason:</th>
                <td>{data.revocation_reason}</td>
              </tr>
            )}
            <tr>
              <th>Registered Block:</th>
              <td>
                <Badge bg="dark" className="text-light">
                  <Link
                    to={"/explorer/block/" + data.registered_block}
                    className="text-reset cursor-zoom-in"
                  >
                    #
                    <NumericFormat
                      value={data.registered_block}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </Link>
                </Badge>
              </td>
            </tr>
            {parseInt(data.created_at) > 0 && (
              <tr>
                <th>Created:</th>
                <td>
                  <span>
                    {dayjs.unix(data.created_at).format("MM/DD/YYYY H:mm")}
                    {" UTC "}
                  </span>
                  <span className="small">
                    ({dayjs.unix(data.created_at).fromNow()})
                  </span>
                </td>
              </tr>
            )}
            {parseInt(data.updated_at) > 0 && (
              <tr>
                <th>Updated:</th>
                <td>
                  <span>
                    {dayjs.unix(data.updated_at).format("MM/DD/YYYY H:mm")}
                    {" UTC "}
                  </span>
                  <span className="small">
                    ({dayjs.unix(data.updated_at).fromNow()})
                  </span>
                </td>
              </tr>
            )}
            {parseInt(data.deleted_at) > 0 && (
              <tr>
                <th>Deleted:</th>
                <td>
                  <span>
                    {dayjs.unix(data.deleted_at).format("MM/DD/YYYY H:mm")}
                    {" UTC "}
                  </span>
                  <br />
                  <span>({dayjs.unix(data.deleted_at).fromNow()})</span>
                </td>
              </tr>
            )}
            {parseInt(data.pose_ban_height) > 0 && (
              <tr>
                <th>PoSe Ban Block:</th>
                <td>
                  <Badge bg="dark" className="text-light">
                    <Link
                      to={"/explorer/block/" + data.pose_ban_height}
                      className="text-reset cursor-zoom-in"
                    >
                      #
                      <NumericFormat
                        value={data.pose_ban_height}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </Link>
                  </Badge>
                </td>
              </tr>
            )}
            {parseInt(data.pose_revived_height) > 0 && (
              <tr>
                <th>PoSe Revived Block:</th>
                <td>
                  <Badge bg="dark" className="text-light">
                    <Link
                      to={"/explorer/block/" + data.pose_revived_height}
                      className="text-reset cursor-zoom-in"
                    >
                      #
                      <NumericFormat
                        value={data.pose_revived_height}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </Link>
                  </Badge>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const NodeAddresses = ({ data }) => {
  return (
    <Card className="shadow-lg rounded match-height">
      <Card.Header className="p-2">
        <Card.Title tag="h6" className="m-0">
          Addresses
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table className="font-monospace fs-medium card-table" size="sm">
          <tbody>
            <tr>
              <th>Collateral:</th>
              <td>
                {data.collateral_address && (
                  <Link
                    to={"/explorer/address/" + data.collateral_address}
                    className="cursor-zoom-in"
                  >
                    {data.collateral_address}
                  </Link>
                )}
              </td>
            </tr>
            <tr>
              <th>Payee:</th>
              <td>
                {data.payee_address && (
                  <Link
                    to={"/explorer/address/" + data.payee_address}
                    className="cursor-zoom-in"
                  >
                    {data.payee_address}
                  </Link>
                )}
              </td>
            </tr>
            <tr>
              <th>Owner:</th>
              <td>
                {data.owner_address && (
                  <Link
                    to={"/explorer/address/" + data.owner_address}
                    className="cursor-zoom-in"
                  >
                    {data.owner_address}
                  </Link>
                )}
              </td>
            </tr>
            <tr>
              <th>Voting:</th>
              <td>
                {data.voting_address && (
                  <Link
                    to={"/explorer/address/" + data.voting_address}
                    className="cursor-zoom-in"
                  >
                    {data.voting_address}
                  </Link>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const NodeRewards = ({ data }) => {
  return (
    <Card className="shadow-lg rounded match-height">
      <Card.Header className="p-2">
        <Card.Title tag="h6" className="m-0">
          Rewards
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table className="font-monospace fs-medium card-table" size="sm">
          <caption className="small fw-lighter">
            <sup>*</sup>
            Intended for reference only. Not a guarantee of future results.
          </caption>
          <tbody className="border-top-0">
            <tr>
              <th>Operator Reward:</th>
              <td>
                <NumericFormat
                  value={parseInt(data.operator_reward) || 1000}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </td>
            </tr>
            <tr>
              <th>Last Reward:</th>
              <td>
                {data.prev_paid_time > 0 && (
                  <React.Fragment>
                    <span>
                      {dayjs
                        .unix(data.prev_paid_time)
                        .format("MM/DD/YYYY H:mm")}
                      {" UTC "}
                    </span>
                    <span className="small">
                      ({dayjs.unix(data.prev_paid_time).fromNow()})
                    </span>
                  </React.Fragment>
                )}
              </td>
            </tr>
            <tr>
              <th>Last Reward Block:</th>
              <td>
                {data.prev_paid_block > 0 && (
                  <span className="badge bg-dark text-light">
                    <Link
                      to={"/explorer/block/" + data.prev_paid_block}
                      className="text-reset cursor-zoom-in"
                    >
                      #
                      <NumericFormat
                        value={data.prev_paid_block}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </Link>
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <th>
                Next Reward:
                <sup>*</sup>
              </th>
              <td>
                {data.next_paid_time > 0 && (
                  <React.Fragment>
                    <span>
                      {dayjs
                        .unix(data.next_paid_time)
                        .format("MM/DD/YYYY H:mm")}
                      {" UTC "}
                    </span>
                    <span className="small">
                      ({dayjs.unix(data.next_paid_time).fromNow()})
                    </span>
                  </React.Fragment>
                )}
              </td>
            </tr>
            <tr>
              <th>
                Next Reward Block:
                <sup>*</sup>
              </th>
              <td>
                {data.next_paid_block > 0 && (
                  <span className="badge bg-dark text-light">
                    #
                    <NumericFormat
                      value={data.next_paid_block}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const Node = () => {
  const { node } = useParams();
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const fetchData = React.useCallback(({ node }) => {
    // Set the loading state
    setLoading(true);

    const params = {
      service: "explorer",
      method: "getNodeByService",
      node: node,
    };

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setData(payload);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    fetchData({ node });
  }, [fetchData, node]);

  return (
    <React.Fragment>
      <Helmet title={"Node " + node} />
      <Container fluid className="p-0">
        <ExplorerSubBreadcrumbs
          tabname="Smartnodes"
          link="nodes"
          subname={node}
        />
        {!loading && data && (
          <React.Fragment>
            <Row>
              <Col>
                <NodeLocation data={data} />
              </Col>
              <Col>
                <NodeService data={data} />
              </Col>
            </Row>
            <Row>
              <Col>
                <NodeRewards data={data} />
              </Col>
              <Col>
                <NodeHistory data={data} />
              </Col>
            </Row>
            <Row>
              <Col>
                <NodeAddresses data={data} />
              </Col>
              <Col>
                <NodeLocationDetails data={data} />
              </Col>
            </Row>
          </React.Fragment>
        )}
        {!loading && !data && (
          <Alert key="tx-alert" variant="info">
            <p className="float-start p-3 m-0 text-center">
              <FontAwesomeIcon icon={faInfoCircle} fixedWidth size="4x" />
            </p>
            <p className="p-3 m-0 float-end">
              <strong>Couldn't find data for this smartnode!</strong>
              <br />
              This smartnode doesn't seem to exist yet...
            </p>
          </Alert>
        )}
      </Container>
    </React.Fragment>
  );
};

export default Node;
