import React from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import {
  Alert,
  Card,
  Container,
  Table,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { getData } from "../../service/Service";
import { NumericFormat } from "react-number-format";
import {
  truncate,
  FormatDifficulty,
  FormatChainwork,
} from "../../utils/Formatters";
import { ExplorerSubBreadcrumbs } from "../../components/Breadcrumbs";
import ScrollToTop from "react-scroll-to-top";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLockOpen,
  faLock,
  faChevronUp,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

const BlockHeader = ({ data, loading }) => {
  return (
    <React.Fragment>
      <Row>
        <Col>
          {!!data.fun && (
            <Alert variant="info" className="p-4">
              {data.fun}
            </Alert>
          )}
          <Card className="shadow-lg mb-5 rounded">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                Block
              </Card.Title>
              <h6 className="card-subtitle text-muted font-monospace">
                {loading ? (
                  <Spinner animation="grow" size="sm" />
                ) : (
                  data.block_hash
                )}
              </h6>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Table className="font-monospace fs-medium" size="sm">
                    <tbody>
                      <tr>
                        <th>Previous Block: </th>
                        <td className="text-break">
                          {data.hash_prev_block}
                          <br />
                          <span className="badge bg-dark text-light">
                            <Link
                              to={"/explorer/block/" + data.prev_block_height}
                              className="text-reset cursor-zoom-in"
                            >
                              #
                              <NumericFormat
                                value={data.prev_block_height}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </Link>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>Timestamp:</th>
                        <td>{data.time_format}</td>
                      </tr>
                      <tr>
                        <th>Transactions:</th>
                        <td>
                          <span className="me-1">{data.transactions}</span>
                          <span className="small">
                            (In: {data.vins} Out: {data.vouts})
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>Total Fees:</th>
                        <td>{data.total_fee}</td>
                      </tr>
                      <tr>
                        <th>Average Fee:</th>
                        <td>{data.avg_fee}</td>
                      </tr>
                      <tr>
                        <th>Size:</th>
                        <td>
                          <NumericFormat
                            value={data.n_size}
                            displayType={"text"}
                            thousandSeparator={true}
                          />{" "}
                          ({data.n_size_format})
                        </td>
                      </tr>
                      <tr>
                        <th>Confirmations:</th>
                        <td>
                          <span>{data.confirmations}</span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col>
                  <Table className="font-monospace fs-medium" size="sm">
                    <tbody>
                      <tr>
                        <th>Next Block:</th>
                        <td className="text-break">
                          {data.hash_next_block}
                          <br />
                          <span className="badge bg-dark text-light">
                            <Link
                              to={"/explorer/block/" + data.next_block_height}
                              className="text-reset cursor-zoom-in"
                            >
                              #
                              <NumericFormat
                                value={data.next_block_height}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </Link>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>Difficulty:</th>
                        <td>
                          <FormatDifficulty dif={parseFloat(data.difficulty)} />
                        </td>
                      </tr>
                      <tr>
                        <th>Version:</th>
                        <td>
                          <span className="me-1">{data.version_hex}</span>
                          <span className="text-black-50 small">
                            (decimal: {data.n_version})
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>Nonce:</th>
                        <td>{data.nonce}</td>
                      </tr>
                      <tr>
                        <th>Bits:</th>
                        <td>{data.n_bits}</td>
                      </tr>
                      <tr>
                        <th>Merkle Root:</th>
                        <td className="text-break">{data.hash_merkle_root}</td>
                      </tr>
                      <tr>
                        <th>Chainwork:</th>
                        <td>
                          <FormatChainwork chwork={String(data.chainwork)} />{" "}
                          <small className="text-secondary">
                            {data.chainwork}
                          </small>
                        </td>
                      </tr>
                      <tr>
                        <th>Miner:</th>
                        <td>
                          <span className="me-1">{data.miner_name}</span>
                          <span className="text-black-50 small">
                            {"("}
                            <Link
                              to={"/explorer/address/" + data.miner}
                              className="cursor-zoom-in"
                            >
                              {data.miner}
                            </Link>
                            {")"}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const TransactionsHeader = ({ txdata, txloading }) => {
  return txdata.length > 0 ? (
    <React.Fragment>
      <Row>
        <Col>
          <Card className="shadow-lg mb-5 rounded">
            <Card.Header>
              <Card.Title tag="h6">Transactions</Card.Title>
              <h6 className="card-subtitle text-muted font-monospace">
                {txloading ? (
                  <Spinner animation="grow" size="sm" />
                ) : (
                  txdata.length
                )}
              </h6>
            </Card.Header>
            <Card.Body>
              {txdata.map((tx) => (
                <Card className="shadow-lg mb-5 rounded" key={tx.txid}>
                  <Card.Header className="p-2">
                    <Card.Title tag="h6" className="font-monospace">
                      #{txdata.indexOf(tx) + 1} -{" "}
                      <Link
                        to={"/explorer/tx/" + tx.txid}
                        className="text-reset cursor-zoom-in"
                      >
                        {tx.txid}
                      </Link>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className="p-1">
                    <Row>
                      <Col>
                        <Table className="font-monospace fs-medium" size="sm">
                          <tbody>
                            {Object.keys(tx.vin.results).map(
                              (key, index) =>
                                (tx.vin.results[key].address && (
                                  <tr key={"vin" + index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <Link
                                        to={
                                          "/explorer/address/" +
                                          tx.vin.results[key].address
                                        }
                                        className="cursor-zoom-in"
                                      >
                                        {tx.vin.results[key].address}
                                      </Link>
                                      <p className="small">
                                        via{" "}
                                        <Link
                                          to={
                                            "/explorer/tx/" +
                                            tx.vin.results[key].txid +
                                            "#output-" +
                                            tx.vin.results[key].n_vout
                                          }
                                          className="text-reset cursor-zoom-in"
                                        >
                                          {truncate(
                                            tx.vin.results[key].txid,
                                            10
                                          )}
                                        </Link>{" "}
                                        [{tx.vin.results[key].n_vout}]
                                      </p>
                                    </td>
                                    <td className="text-end">
                                      {tx.vin.results[key].satoshis_format}
                                    </td>
                                  </tr>
                                )) ||
                                (tx.vin.results[key].coinbase && (
                                  <tr key={"vin" + index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <span className="badge bg-info text-dark">
                                        coinbase
                                      </span>{" "}
                                      Newly minted coins
                                    </td>
                                    <td className="text-end">5,000.00</td>
                                  </tr>
                                ))
                            )}
                            <tr>
                              <td colSpan="3" className="text-end fw-bolder">
                                {tx.vin.results.summary.valueIn_format}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                      <Col>
                        <Table className="font-monospace fs-medium" size="sm">
                          <tbody>
                            {Object.keys(tx.vout.results).map(
                              (key, index) =>
                                tx.vout.results[key].satoshis_format && (
                                  <tr key={"vout" + index}>
                                    <td className="col-xs-1">{index + 1}</td>
                                    <td className="text-start col-xs-1">
                                      {tx.vout.results[key].spent === "spent" ||
                                      tx.vout.results[key].op_return ? (
                                        <FontAwesomeIcon
                                          icon={faLockOpen}
                                          fixedWidth
                                          className="text-secondary"
                                          title="Spent output"
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          icon={faLock}
                                          fixedWidth
                                          className="text-success"
                                          title="Unspent output"
                                        />
                                      )}
                                    </td>
                                    <td>
                                      {tx.vout.results[key].address && (
                                        <Link
                                          to={
                                            "/explorer/address/" +
                                            tx.vout.results[key].address
                                          }
                                          className="cursor-zoom-in"
                                        >
                                          {tx.vout.results[key].address}
                                        </Link>
                                      )}
                                      {tx.vout.results[key].op_return && (
                                        <span>
                                          {tx.vout.results[key].op_return}
                                        </span>
                                      )}
                                    </td>
                                    <td className="text-end">
                                      {tx.vout.results[key].satoshis_format}
                                    </td>
                                  </tr>
                                )
                            )}
                            <tr>
                              <td colSpan="4" className="text-end fw-bolder">
                                {tx.vout.results.summary.valueOut_format}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ScrollToTop
        smooth
        className="bg-dark"
        component={
          <FontAwesomeIcon
            icon={faChevronUp}
            fixedWidth
            size="lg"
            className="text-primary"
            title="Up"
          />
        }
        style={{ bottom: 50, right: 15 }}
      />
    </React.Fragment>
  ) : (
    ""
  );
};

const Block = () => {
  const { height } = useParams();
  const [data, setData] = React.useState(false);
  const [txdata, setTxdata] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [txloading, setTxloading] = React.useState(true);

  const fetchTxData = React.useCallback(({ height }) => {
    setTxloading(true);

    const params = {
      service: "explorer",
      method: "getBlockTransactionsByHeight",
      height: height,
    };

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setTxdata(payload);
          }
        } else {
          console.log(data);
        }
      })
      .finally(() => {
        setTxloading(false);
      });
  }, []);

  const fetchData = React.useCallback(
    ({ height }) => {
      // Set the loading state
      setLoading(true);

      const params = {
        service: "explorer",
        method: "getBlockByHeight",
        height: height,
      };

      const serverData = getData(params);

      serverData
        .then((data) => {
          const status = data.status;
          if (status === "success") {
            if (typeof data[params.method].results !== "undefined") {
              const payload = data[params.method].results;
              setData(payload);
            }
          } else {
            console.log(data);
          }
        })
        .finally(() => {
          setLoading(false);
          fetchTxData({ height });
        });
    },
    [fetchTxData]
  );

  React.useEffect(() => {
    fetchData({ height });
  }, [fetchData, fetchTxData, height]);

  return (
    <React.Fragment>
      <Helmet title={"Block #" + height} />
      <Container fluid className="p-0">
        <ExplorerSubBreadcrumbs
          tabname="Blocks"
          subname={height}
          link="blocks"
          subnum={true}
        />
        {data && <BlockHeader loading={loading} data={data} />}
        {data && <TransactionsHeader txloading={txloading} txdata={txdata} />}
        {!loading && !data && (
          <Alert key="address-alert" variant="info">
            <p className="float-start p-3 m-0 text-center">
              <FontAwesomeIcon icon={faInfoCircle} fixedWidth size="4x" />
            </p>
            <p className="p-3 m-0 float-end">
              <strong>Couldn't find data for this block!</strong>
              <br />
              This block doesn't seem to exist yet...
            </p>
          </Alert>
        )}
      </Container>
    </React.Fragment>
  );
};

export default Block;
