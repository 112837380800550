import React from "react";
import axios from "axios";
import { Card, Col, Row } from "react-bootstrap";

const LocalMiner = () => {
  const [data, setData] = React.useState(false);
  const [refreshInterval] = React.useState(5000);
  const getMinerData = () => {
    axios.get("http://127.0.0.1:42069/2/summary").then((res) => {
      setData(res.data);
    });
  };

  React.useEffect(() => {
    getMinerData();
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(getMinerData, refreshInterval);
      return () => {
        clearInterval(interval);
      };
    }
  }, [refreshInterval]);

  return (
    <React.Fragment>
      <Row>
        <Col>Mining to: {data?.connection?.pool ?? "Idle"}</Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                Uptime
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <h3>{data?.uptime ?? 0}</h3>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                Hashrate
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <h3>{data.hashrate?.total[0] ?? 0} H/s</h3>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                Accepted Shares
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <h3>
                {data?.results?.shares_good ?? 0} /{" "}
                {data?.results?.shares_total ?? 0}
              </h3>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                Difficulty
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <h3>{data?.results?.diff_current ?? 0}</h3>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                Raw Miner Output
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <code>
                <pre>{JSON.stringify(data, null, 2)}</pre>
              </code>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default LocalMiner;
