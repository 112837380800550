import React from "react";
import { Alert, Card, Spinner, Table } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { ModalProvider } from "../../../../contexts/ModalContext";
import { entriesToCsv } from "../../../../utils/Utilities";
import { copyEntry } from "../../../../utils/interactions";
import NotyfContext from "../../../../contexts/NotyfContext";
import { HeaderControls, AddButton } from "./EntryTableHeaderControls";
import { RowControls } from "./EntryTableRowControls";
import { InlineEdit } from "./EditWidgets";

const EntryTable = ({ name, category, addFields, addMethods, raptorio }) => {
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const notyf = React.useContext(NotyfContext);
  const [hasPortfolio, setHasPortfolio] = React.useState(false);

  const provideNotyf = (message, type, duration = 2000) => {
    notyf.open({
      type,
      message,
      duration,
      dismissible: true,
      ripple: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  const fetchData = React.useCallback(
    ({ category }) => {
      // Set the loading state
      setLoading(true);

      const portfolios = raptorio.getPortfolios();
      portfolios.then((data) => {
        if (!!data && data.length > 0) {
          setHasPortfolio(true);
        }
      });

      const getEntries = raptorio.getPortfolioEntries(category);
      getEntries
        .then((data) => {
          setData(data);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [raptorio]
  );

  React.useEffect(() => {
    fetchData({ category });
  }, [fetchData, category]);

  const handleDelete = (doc) => {
    if (!!doc) {
      const entryToDelete = doc.id;
      raptorio.deleteEntry(entryToDelete, category).then((res) => {
        if (res.deleted) {
          provideNotyf("Deleted entry!", "success");
        } else {
          provideNotyf("Couldn't delete entry!", "danger");
        }
        fetchData({ category });
      });
    }
  };

  const handleAdd = (entry) => {
    if (!!entry && entry.length > 0) {
      raptorio
        .saveEntries(entry)
        .then(async (res) => {
          res.map((res, i) => {
            return res.then((res) => {
              if (res.errorCt > 0) {
                if (res.conflictCt > 0) {
                  return provideNotyf(
                    `Entry already exists in ${category}!`,
                    "warning"
                  );
                } else {
                  return provideNotyf(
                    "Error encountered, Couldn't add entry!",
                    "danger"
                  );
                }
              }
              if (res.successCt > 0) {
                fetchData({ category });
                return provideNotyf(`Entry added to ${category}!`, "success");
              }
            });
          });
        })
        .catch((error) => {
          //console.log(error);
        });
    } else {
      provideNotyf("Form data not found!", "danger");
    }
  };

  const handleLabel = (entry) => {
    raptorio.updateEntryLabel(entry.id, category, entry.label).then((res) => {
      if (!!res.rev) {
        provideNotyf("Updated label!", "success");
      } else {
        provideNotyf("Couldn't update label!", "danger");
      }
      setEditMode(false);
      fetchData({ category });
    });
  };

  const handleCopy = (entry) => {
    return copyEntry(entry, provideNotyf);
  };

  const handleCopyAll = () => {
    let getField = "";
    if (category === "apis") {
      getField = "api";
    }
    if (category === "customs") {
      getField = "amount";
    }
    if (category === "smartnodes") {
      getField = "ip";
    }
    const csv = entriesToCsv(
      data.map((entry) => entry),
      getField
    );
    return copyEntry(csv, provideNotyf);
  };

  return (
    <React.Fragment>
      <ModalProvider>
        {(!!data && data.length > 0 && (
          <Table className="fs-medium user-select-none" hover size="sm">
            <thead>
              <tr>
                {!!addFields &&
                  addFields.map((field, i) => {
                    let classStr = "";
                    if (i === 0) {
                      classStr = "col-sm-4";
                    }
                    return (
                      <th className={classStr} key={"h-" + i}>
                        {field.placeholder}
                      </th>
                    );
                  })}
                <th className="text-end">
                  <HeaderControls
                    name={name}
                    category={category}
                    size="sm"
                    variant="light"
                    handleAdd={handleAdd}
                    copyAll={handleCopyAll}
                    addFields={addFields}
                    addMethods={addMethods}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan="3" className="text-center">
                    <Spinner animation="border" variant="primary" size="sm" />
                  </td>
                </tr>
              )}
              {data.map((row, i) => (
                <tr
                  key={row.id}
                  className={editMode === String(i) ? "table-light" : ""}
                >
                  {!!addFields &&
                    addFields.map((field, x) => {
                      if (field.name === "label") {
                        return (
                          <td
                            key={"td-" + x}
                            className="font-monospace"
                            onDoubleClick={() => {
                              return setEditMode(String(i));
                            }}
                          >
                            {(!!editMode && editMode === String(i) && (
                              <InlineEdit
                                key={`inlineEdit-${i}`}
                                entry={row}
                                field={field.name}
                                sibling={addFields[0].name}
                                handleLabel={handleLabel}
                                setEditMode={setEditMode}
                              />
                            )) ||
                              row[field.name]}
                          </td>
                        );
                      }
                      return (
                        <td key={"td-" + x} className="font-monospace">
                          {isNaN(row[field.name]) ? (
                            row[field.name]
                          ) : (
                            <NumericFormat
                              value={row[field.name]}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          )}
                        </td>
                      );
                    })}
                  <td className="text-end">
                    <RowControls
                      entry={row}
                      i={String(i)}
                      field={addFields[0].name}
                      name={name}
                      category={category}
                      editMode={editMode}
                      setEditMode={setEditMode}
                      handleCopy={handleCopy}
                      handleDelete={handleDelete}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )) ||
          (loading ? (
            <Spinner animation="border" variant="primary" size="sm" />
          ) : (
            (hasPortfolio && (
              <Card bg="light">
                <Card.Body>
                  <Alert variant="info" className="p-4">
                    <strong>
                      {`We didn't find anything saved, go ahead and add something!`}
                    </strong>
                  </Alert>
                  <AddButton
                    name={name}
                    category={category}
                    size="sm"
                    handleAdd={handleAdd}
                    variant="success"
                    addFields={addFields}
                    addMethods={addMethods}
                  />
                </Card.Body>
              </Card>
            )) || (
              <Alert variant="info" className="p-4">
                <strong>{`We didn't find a portfolio, create one!`}</strong>
              </Alert>
            )
          ))}
      </ModalProvider>
    </React.Fragment>
  );
};

export default EntryTable;
