import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import AddForm from "./AddForm";

const NativeModal = ({ nativeModalShow, setNativeModalShow, handleAction }) => {
  const [pass, setPass] = useState(false);
  const handleClose = () => setNativeModalShow(false);
  const handleSave = () => {
    if (!!pass && pass.length > 1) {
      handleAction(pass);
      setPass(false);
      handleClose();
      //console.log("use password: " + pass);
    }
  };
  return (
    <Modal show={nativeModalShow} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Unlock Portfolio</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Control
            type="password"
            placeholder="Password"
            onBlur={(e) => setPass(e.target.value)}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Unlock
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const AddButton = ({
  name,
  category,
  addMethods,
  addFields,
  size,
  variant,
  className,
  handleAdd,
  handleNativeAdd,
}) => {
  const [show, setShow] = useState(false);
  const [nativeModalShow, setNativeModalShow] = useState(false);
  const [entry, setEntry] = useState([]);
  //const [needPassword, setNeedPassword] = useState(category === "native");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSave = () => {
    handleAdd(entry);
    handleClose();
  };

  return category === "native" ? (
    <React.Fragment>
      <Button
        variant={variant}
        size={size}
        onClick={() => setNativeModalShow(true)}
        className={className}
      >
        <FontAwesomeIcon icon={faPlusCircle} fixedWidth />
      </Button>
      <NativeModal
        nativeModalShow={nativeModalShow}
        setNativeModalShow={setNativeModalShow}
        handleAction={handleNativeAdd}
      />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Button
        variant={variant}
        size={size}
        onClick={handleShow}
        className={className}
      >
        <FontAwesomeIcon icon={faPlusCircle} fixedWidth />
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <FontAwesomeIcon className="me-2" icon={faPlusCircle} fixedWidth />
            <span className="fw-light">{`Add to ${name}`}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddForm
            category={category}
            setEntry={setEntry}
            addMethods={addMethods}
            addFields={addFields}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            type="submit"
            size="sm"
            onClick={handleSave}
          >
            <FontAwesomeIcon className="me-2" icon={faCheck} fixedWidth />
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default AddButton;
