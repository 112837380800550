import React from "react";
import {
  Badge,
  Col,
  Card,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
} from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { getData } from "../../service/Service";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var LocalizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const Tickers = ({ reload }) => {
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const fetchData = React.useCallback(() => {
    // Set the loading state
    setLoading(true);

    const params = {
      service: "market",
      method: "getTopCGCoins",
    };

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setData(payload);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  return (
    <Row md="6" xl>
      {!!data &&
        data.map((entry, i) => {
          return (
            <Col
              key={"tickersrow-" + i}
              className={i > 1 ? "d-none d-xl-block" : "d-xs-block"}
            >
              <Card
                className={
                  (entry.symbol === "rtm"
                    ? "border border-primary "
                    : "border border-muted ") + "flex-fill shadow-lg"
                }
                bg={entry.symbol === "rtm" ? "light" : ""}
              >
                <Card.Body className="py-1 px-2">
                  <div className="float-end">
                    <Badge
                      bg={
                        entry.price_change_percentage_24h < 0
                          ? "danger"
                          : "success"
                      }
                    >
                      {Number(entry.price_change_percentage_24h).toLocaleString(
                        "en",
                        {
                          maximumFractionDigits: 2,
                        }
                      ) + "%"}
                    </Badge>
                  </div>
                  <h5 className="mb-2 text-uppercase mt-1">{entry.symbol}</h5>
                  <div className="mb-1 d-block">
                    {loading && <Spinner animation="border" size="sm" />}
                    <strong>
                      $
                      <NumericFormat
                        value={entry.current_price}
                        displayType={"text"}
                        thousandSeparator={true}
                        defaultValue={0}
                        decimalScale={8}
                        fixedDecimalScale={true}
                      />
                    </strong>
                  </div>
                  <div className="small d-block">
                    Volume:{" "}
                    <NumericFormat
                      value={entry.total_volume}
                      displayType={"text"}
                      thousandSeparator={true}
                      defaultValue={0}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </div>
                  <div className="text-end d-block">
                    <OverlayTrigger
                      key={"ticker-lastupdated" + i}
                      trigger="click"
                      placement="left"
                      overlay={
                        <Popover id={"ticker-lastupdated" + i}>
                          <Popover.Header as="h3">Last Updated</Popover.Header>
                          <Popover.Body>
                            {dayjs(entry.last_updated).fromNow()}
                          </Popover.Body>
                        </Popover>
                      }
                      rootClose={true}
                    >
                      <span>
                        <FontAwesomeIcon
                          icon={faClock}
                          className="text-secondary"
                          fixedWidth
                        />
                      </span>
                    </OverlayTrigger>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
    </Row>
  );
};

export default Tickers;
