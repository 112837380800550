import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import Chart from "react-apexcharts";
import { getData } from "../../../service/Service";
import usePalette from "../../../hooks/usePalette";
import useTheme from "../../../hooks/useTheme";

const SmartnodesList = ({ reload }) => {
  const palette = usePalette();
  const appTheme = useTheme();
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [chartData, setChartData] = React.useState([]);
  const [chartOptions] = React.useState({
    chart: {
      theme: appTheme.theme,
      foreColor: palette["gray-600"],
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    },
    tooltip: {
      enabled: false,
    },
    stroke: {
      width: 0,
    },
    labels: ["Enabled", "Banned", "Upgrade", "Unknown"],
    colors: [
      palette.success,
      palette.danger,
      palette.warning,
      palette.secondary,
    ],
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return (
          Number(val).toLocaleString("en", {
            maximumFractionDigits: 2,
          }) + "%"
        );
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: true,
        customScale: 0.9,
        dataLabels: {
          offset: 45,
          minAngleToShowLabel: 10,
        },
        donut: {
          background: "transparent",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "14px",
              fontWeight: 100,
              offsetY: -10,
            },
            value: {
              show: true,
              fontSize: "14px",
              fontWeight: 100,
              offsetX: 16,
              formatter: (val) => {
                return Number(val).toLocaleString("en", {
                  maximumFractionDigits: 2,
                });
              },
            },
            total: {
              show: true,
              showAlways: false,
              label: "Total",
              fontSize: "16px",
              fontWeight: 200,
              formatter: (w) => {
                return w.globals.seriesTotals
                  .reduce((a, b) => {
                    return a + b;
                  }, 0)
                  .toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
              },
            },
          },
        },
      },
    },
  });

  const fetchData = React.useCallback(() => {
    const params = {
      service: "statistics",
      method: "nodes",
    };

    setLoading(true);

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setData(payload);
            setChartData([
              Number(payload.enabled),
              Number(payload.pose_banned),
              Number(payload.c_upgrade),
              Number(payload.unknown),
            ]);
          }
        } else {
          console.log(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  return (
    <Card className="flex-grow-1 align-self-stretch shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          <Link to={"/explorer/nodes"} className="text-reset">
            Smartnode Status
          </Link>
          {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs="auto">
            {!!data && <span className="fs-2">{data.total} Active</span>}
          </Col>
          <Col xl="11">
            <div className="chart">
              {chartData.length > 0 && (
                <Chart
                  options={chartOptions}
                  series={chartData}
                  type="donut"
                  height="350"
                />
              )}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SmartnodesList;
