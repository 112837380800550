import React from "react";
import { Button, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import { isInteger } from "lodash";
import NotyfContext from "../../../../contexts/NotyfContext";
import Raptorio from "../../../../service/Raptorio";
const raptorio = new Raptorio({ coin: "RTM" });

const DifficultyModal = () => {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="warning" onClick={handleShow} size="sm">
        Difficulty Info
      </Button>

      <Modal show={show} onHide={handleClose} className="bg-dark bg-opacity-50">
        <Modal.Header closeButton>
          <Modal.Title>Vanity Generator Difficulty</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="small">
            Given that the difficulty increases exponentially the longer your
            vanity is, so does the average time required to find that vanity.
            The table below shows how an increasingly complex vanity affects the
            difficulty and average time required to find a match only for that
            vanity, let alone the full address, for a machine capable of looking
            through one million keys per second.
          </p>
          <p className="text-danger">
            This generator utilizes javascript vs. native compiled software. It
            will be slow and painful. Table provided for reference.
          </p>
          <Table size="sm" bordered hover className="small">
            <tbody>
              <tr>
                <th> vanity </th>
                <th> difficulty </th>
                <th> average time</th>
              </tr>
              <tr>
                <td> 1B </td>
                <td> 22 </td>
                <td> &lt; 1s</td>
              </tr>
              <tr>
                <td> 1Bi </td>
                <td> 1,330 </td>
                <td> &lt; 1s</td>
              </tr>
              <tr>
                <td> 1Bit </td>
                <td> 77,178 </td>
                <td> &lt; 1s</td>
              </tr>
              <tr>
                <td> 1Bitc </td>
                <td> 4,476,342 (4.48E+6)</td>
                <td> &lt; 10s</td>
              </tr>
              <tr>
                <td> 1Bitco </td>
                <td> 259,627,881 (2.6E+8)</td>
                <td> 3 minutes</td>
              </tr>
              <tr>
                <td> 1Bitcoi </td>
                <td> 15,058,417,127 (1.506E+10) </td>
                <td> 3 hours</td>
              </tr>
              <tr>
                <td> 1Bitcoin </td>
                <td> 8.7339E+11 </td>
                <td> 1 week</td>
              </tr>
              <tr>
                <td> 1BitcoinE </td>
                <td> 5.0657E+13 </td>
                <td> 1 year</td>
              </tr>
              <tr>
                <td> 1BitcoinEa </td>
                <td> 2.9381E+15 </td>
                <td> 60 years</td>
              </tr>
              <tr>
                <td> 1BitcoinEat </td>
                <td> 1.7041E+17 </td>
                <td> 3,500 years</td>
              </tr>
              <tr>
                <td> 1BitcoinEate </td>
                <td> 9.8837E+18 </td>
                <td> 200,000 years</td>
              </tr>
              <tr>
                <td> 1BitcoinEater </td>
                <td> 5.7325E+20 </td>
                <td> 11,700,000 years</td>
              </tr>
              <tr>
                <td> 1BitcoinEaterAddressDontSend </td>
                <td> 1.6209E+47 </td>
                <td> 3.3E+33 or 3.3 decillion years.</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const VanityAddress = ({ mnemonic, setVanityPath, isStacy }) => {
  const notyf = React.useContext(NotyfContext);
  const [vanity, setVanity] = React.useState(false);
  const [offset, setOffset] = React.useState(0);
  const [result, setResult] = React.useState(false);
  const [sensitive, setSensitive] = React.useState(false);
  const [direction, setDirection] = React.useState("ltr");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [keepResults, setKeepResults] = React.useState([]);
  const [keepAddresses, setKeepAddresses] = React.useState([]);

  const provideNotyf = (message, type, duration = 2000) => {
    notyf.open({
      type,
      message,
      duration,
      dismissible: true,
      ripple: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  const setVanityAddress = (e) => {
    let value = e.target.value;
    if (value !== "") {
      setVanity(value.trim());
    }
  };

  const handleGenerate = async () => {
    if (!!vanity && mnemonic.length >= 12) {
      setLoading(true);
      setError(false);
      setResult(false);

      //console.log("trying... " + vanity);
      raptorio
        .generateVanityAddress(
          mnemonic.join(" "),
          vanity,
          sensitive,
          direction,
          offset,
          isStacy
        )
        .then((res) => {
          //console.log(res);
          if (isInteger(res)) {
            //console.log("took too long with " + res + " tries");
            setOffset(res);
          } else {
            if (typeof res?.error !== "undefined") {
              setError(res.error);
            }
            if (typeof res?.address !== "undefined") {
              setResult(res);
              setOffset(res.tries + 1);
            }
          }
          setLoading(false);
          return true;
        });
    }
  };

  const keepVanity = () => {
    //console.log("keep", result.tries);
    if (keepResults.includes(result.tries)) {
      return provideNotyf("Already added to collection!", "warning");
    }
    setKeepResults([
      // with a new array
      ...keepResults, // that contains all the old items
      result.tries, // and one new item at the end
    ]);
    setKeepAddresses([...keepAddresses, result.address]);
    setVanityPath([
      // with a new array
      ...keepResults, // that contains all the old items
      result.tries, // and one new item at the end
    ]);

    //console.log("keep", result);

    return provideNotyf("Added to collection!", "success");

    //console.log("keep", keepResults);
  };

  const resetForm = () => {
    setVanity(false);
    setOffset(0);
    setResult(false);
    setError(false);
  };

  const handleSensitive = (e) => {
    setSensitive(e.target.checked ? true : false);
    setOffset(0);
    setResult(false);
    setError(false);
  };

  const handleDirection = (e) => {
    setDirection(e.target.value);
    setOffset(0);
    setResult(false);
    setError(false);
  };

  return (
    <Form>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder="Enter your desired vanity"
              onBlur={setVanityAddress}
              onChange={resetForm}
            />
            <Form.Text className="text-muted">
              <span className="text-info">
                Use any alphanumeric string with the exception that the
                uppercase letter “O”, uppercase letter “I”, lowercase letter
                “l”, and the number “0” are never used to prevent visual
                ambiguity.
              </span>
              <br />
              <span>
                Strings over 3 characters get harder to find, check the
                difficulty info for more details.
              </span>
            </Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <div key={`inline-radio`} className="mb-3">
              <Form.Check
                inline
                label="Start with"
                name="group1"
                type="radio"
                value="ltr"
                defaultChecked
                id={`inline-radio-1`}
                onClick={(e) => handleDirection(e)}
              />
              <Form.Check
                inline
                label="End with"
                name="group1"
                type="radio"
                value="rtl"
                id={`inline-radio-2`}
                onClick={(e) => handleDirection(e)}
              />
              <Form.Check
                inline
                label="Anywhere"
                name="group1"
                type="radio"
                value="a"
                id={`inline-radio-3`}
                onClick={(e) => handleDirection(e)}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              type="switch"
              label="Case Sensitive"
              defaultChecked={false}
              onClick={(e) => handleSensitive(e)}
              className="float-start"
            />
            {sensitive ? (
              <span className="small text-warning ms-2">
                Increases difficulty!
              </span>
            ) : (
              ""
            )}
          </Form.Group>
        </Col>
        <Col xs="auto">
          <DifficultyModal />
        </Col>
      </Row>

      {!!error ? (
        <Row>
          <Col>
            <p className="text-danger">{error}</p>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {keepResults.length > 0 ? (
        <Row>
          <Col>
            <p className="text-success">
              You collected {keepResults.length} vanity addresses for your
              portfolio!
            </p>
            <Form.Control
              as="textarea"
              defaultValue={keepAddresses.toString()}
              rows={3}
            ></Form.Control>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {!!result ? (
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Generated</Form.Label>
              <Form.Control
                type="text"
                readOnly
                value={result ? result.address : ""}
                size="sm"
              />
            </Form.Group>
          </Col>
          <Col>
            <div className="text-success mb-2">
              Found something! in {offset} tries!
            </div>
            <Button variant="success" size="sm" onClick={() => keepVanity()}>
              Add this address to portfolio!
            </Button>
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Row>
        <Col>
          <Button
            variant="primary"
            size="lg"
            onClick={() => {
              handleGenerate();
            }}
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : offset > 0 && !loading ? (
              "Try again!"
            ) : (
              "Try"
            )}
          </Button>
          <span className="ms-3 text-warning">
            {offset > 0 && !result ? offset + " tries" : ""}
          </span>
        </Col>
      </Row>
    </Form>
  );
};

export default VanityAddress;
