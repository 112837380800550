import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { store } from "./redux/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "./i18n";
import routes from "./routes";

import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";

import { AuthProvider } from "./contexts/JWTContext";
// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";

const queryClient = new QueryClient();

const App = () => {
  const content = useRoutes(routes);

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | Raptorio" defaultTitle="Raptorio" />
      <Provider store={store}>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <SidebarProvider>
              <LayoutProvider>
                <AuthProvider>{content}</AuthProvider>
              </LayoutProvider>
            </SidebarProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
