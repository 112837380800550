import React from "react";
import Chart from "react-apexcharts";
import { Card, Spinner } from "react-bootstrap";
import { getData } from "../../../service/Service";
import { FormatDifficulty } from "../../../utils/Formatters";
import { useTranslation } from "react-i18next";
import usePalette from "../../../hooks/usePalette";
import useTheme from "../../../hooks/useTheme";

const DifficultyCard = ({ reload }) => {
  const palette = usePalette();
  const appTheme = useTheme();
  const [data, setData] = React.useState(false);
  const [chartData, setChartData] = React.useState([]);
  const [chartOptions] = React.useState({
    chart: {
      theme: appTheme.theme,
      foreColor: palette["gray-600"],
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      width: "100%",
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      show: false,
    },
    fill: {
      type: "solid",
      opacity: 0.35,
    },
    xaxis: {
      type: "category",
    },
    tooltip: {
      theme: appTheme.theme,
      style: {
        fontSize: "10px",
      },
      x: {
        formatter: (val) => {
          return "Block " + val;
        },
      },
      y: {
        formatter: (val) => {
          return Number(val).toLocaleString("en", {
            maximumFractionDigits: 6,
          });
        },
      },
    },
    colors: [palette.danger],
  });
  const [loading, setLoading] = React.useState(true);
  const { t } = useTranslation();

  const fetchData = React.useCallback(() => {
    const params = {
      service: "statistics",
      method: "difficulty",
    };

    setLoading(true);

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setData(payload);
            setChartData(payload.chart.series);
          }
        } else {
          console.log(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  return (
    <Card className="flex-fill shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {t("Difficulty")}
          {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body className="p-0" style={{ minHeight: 125 }}>
        {!!data && (
          <div className="p-4">
            <span className="fs-2">
              <FormatDifficulty dif={parseFloat(data.difficulty)} />
            </span>
          </div>
        )}
        {chartData.length > 0 && (
          <div className="position-absolute bottom-0 w-100">
            <Chart
              options={chartOptions}
              series={chartData}
              type="area"
              height={100}
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default DifficultyCard;
