import React, { useState, useContext } from "react";
import { Alert, Card, Table, Spinner } from "react-bootstrap";
import { ModalProvider } from "../../../../contexts/ModalContext";
import { truncate } from "../../../../utils/Formatters";
import { entriesToCsv } from "../../../../utils/Utilities";
import { copyEntry } from "../../../../utils/interactions";
import getTransactions from "../../../../utils/GetTransactions";
import NotyfContext from "../../../../contexts/NotyfContext";
import { HeaderControls, AddButton } from "./AddressTableHeaderControls";
import { RowControls } from "./AddressTableRowControls";
import { InlineEdit } from "./EditWidgets";

const AddressTable = ({ name, category, addFields, addMethods, raptorio }) => {
  const [editMode, setEditMode] = useState(false);
  const notyf = useContext(NotyfContext);
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [hasPortfolio, setHasPortfolio] = React.useState(false);

  const fetchData = React.useCallback(
    ({ category }) => {
      // Set the loading state
      setLoading(true);

      const portfolios = raptorio.getPortfolios();
      portfolios.then((data) => {
        if (!!data && data.length > 0) {
          setHasPortfolio(true);
        }
      });

      const rows = raptorio.getAddresses(category);

      rows
        .then((data) => {
          setData(data);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [raptorio]
  );

  React.useEffect(() => {
    fetchData({ category });
  }, [fetchData, category]);

  const provideNotyf = (message, type, duration = 2000) => {
    notyf.open({
      type,
      message,
      duration,
      dismissible: true,
      ripple: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  const handleDelete = (doc) => {
    if (!!doc) {
      const addressToDelete = doc.id;
      raptorio.deleteWatched(addressToDelete).then((res) => {
        if (res.deleted) {
          provideNotyf("Deleted address!", "success");
        } else {
          provideNotyf("Couldn't delete address!", "danger");
        }
        fetchData({ category });
      });
    }
  };

  const handleAdd = (entry) => {
    console.log(entry);
    raptorio
      .saveWatched(entry)
      .then(async (res) => {
        res.map((res, i) => {
          return res.then((res) => {
            if (res.errorCt > 0) {
              if (res.conflictCt > 0) {
                return provideNotyf(
                  `Address [${truncate(entry[i].address)}] already exists!`,
                  "warning"
                );
              } else {
                return provideNotyf(
                  "Error encountered, Couldn't make address!",
                  "danger"
                );
              }
            }
            if (res.successCt > 0) {
              fetchData({ category });
              return provideNotyf(
                `Address [${truncate(entry[i].address)}] added!`,
                "success"
              );
            }
          });
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const handleNativeAdd = (pass) => {
    if (!pass || pass.length < 1) {
      provideNotyf("Need your password!", "danger");
      return false;
    } else {
      raptorio.generateNewAddress(pass).then((res) => {
        if (typeof res === "undefined") {
          provideNotyf(
            "Couldn't make address! Make sure you know your password!",
            "danger"
          );
          return false;
        }
        if (!!res[0].id) {
          provideNotyf("Created address!", "success");
        } else {
          provideNotyf("Couldn't make address!", "danger");
        }
        fetchData({ category });
      });
    }
  };

  const handleLabel = (entry) => {
    raptorio.updateWalletLabel(entry.id, entry.label).then((res) => {
      if (!!res.rev) {
        provideNotyf("Updated label!", "success");
        setEditMode(false);
      } else {
        provideNotyf("Couldn't update label!", "danger");
      }
      fetchData({ category });
    });
  };

  const handleRefresh = (address) => {
    address = !address
      ? entriesToCsv(
          data.map((entry) => entry.doc),
          "address"
        )
      : address;
    provideNotyf("Fetching...", "default", 0);
    const getTxs = getTransactions(address);
    getTxs.then((result) => {
      if (result.status === "Done") {
        setTimeout(() => {
          notyf.dismissAll();
          provideNotyf(
            "Loading " + result.results + " transactions!",
            "success"
          );
        }, 2500);
      }
    });
  };

  const handleCopy = (entry) => {
    return copyEntry(entry, provideNotyf);
  };

  const handleCopyAll = () => {
    const csv = entriesToCsv(
      data.map((entry) => entry),
      "address"
    );
    return copyEntry(csv, provideNotyf);
  };

  return (
    <React.Fragment>
      <ModalProvider>
        {(!!data && data.length > 0 && (
          <Table className="fs-medium user-select-none" hover size="sm">
            <thead>
              <tr>
                <th className="col-sm-4">Address</th>
                <th>Label</th>
                <th className="text-end">
                  <HeaderControls
                    name={name}
                    category={category}
                    size="sm"
                    variant="light"
                    handleAdd={handleAdd}
                    handleNativeAdd={handleNativeAdd}
                    copyAll={handleCopyAll}
                    handleRefresh={handleRefresh}
                    addFields={addFields}
                    addMethods={addMethods}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan="3" className="text-center">
                    <Spinner animation="border" variant="primary" size="sm" />
                  </td>
                </tr>
              )}
              {data.map((row, i) => (
                <tr
                  key={row.id}
                  className={editMode === String(i) ? "table-light" : ""}
                >
                  <td className="font-monospace">
                    <span className="d-none d-xl-block">{row.address}</span>
                    <span className="d-xl-none">{truncate(row.address)}</span>
                  </td>
                  <td
                    className="font-monospace"
                    onDoubleClick={() => {
                      return setEditMode(String(i));
                    }}
                  >
                    {(!!editMode && editMode === String(i) && (
                      <InlineEdit
                        key={`inlineEdit-${i}`}
                        entry={row}
                        field="label"
                        sibling="address"
                        handleLabel={handleLabel}
                        setEditMode={setEditMode}
                      />
                    )) ||
                      row.label}
                  </td>
                  <td className="text-end">
                    <RowControls
                      entry={row}
                      i={String(i)}
                      field="address"
                      name={name}
                      category={category}
                      editMode={editMode}
                      setEditMode={setEditMode}
                      handleCopy={handleCopy}
                      handleRefresh={handleRefresh}
                      handleDelete={handleDelete}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )) ||
          (loading ? (
            <Spinner animation="border" variant="primary" size="sm" />
          ) : (
            (hasPortfolio &&
              (category === "watched" || category === "addressbook") && (
                <Card bg="light">
                  <Card.Body>
                    <Alert variant="info" className="p-4">
                      <strong>
                        {`We didn't find anything saved, go ahead and add something!`}
                      </strong>
                    </Alert>
                    <AddButton
                      name={name}
                      category={category}
                      size="sm"
                      handleAdd={handleAdd}
                      variant="success"
                      addFields={addFields}
                      addMethods={addMethods}
                    />
                  </Card.Body>
                </Card>
              )) ||
            (!hasPortfolio && !loading && (
              <Alert variant="info" className="p-4">
                <strong>{`We didn't find a portfolio, create one!`}</strong>
              </Alert>
            ))
          ))}
      </ModalProvider>
    </React.Fragment>
  );
};

export default AddressTable;
