import React from "react";
import { Col, Row } from "react-bootstrap";
import BalanceCard from "../portfolio/components/BalanceCard";
import RecentTxCard from "../portfolio/components/RecentTxCard";
import CurrentPriceCard from "../market/CurrentPriceCard";

const Statistics = ({ reload, portfolio, raptorio }) => {
  return portfolio ? (
    <Row>
      <Col md="6" xl className="d-flex">
        <BalanceCard raptorio={raptorio} reload={reload} />
      </Col>
      <Col md="6" xl className="d-flex">
        <RecentTxCard raptorio={raptorio} reload={reload} />
      </Col>
      <Col md="6" xl className="d-flex">
        <CurrentPriceCard reload={reload} />
      </Col>
    </Row>
  ) : (
    ""
  );
};

export default Statistics;
