import React, { useState } from "react";
import { Card, Spinner, Table } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { getData } from "../../../service/Service";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var LocalizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const ServiceApiCard = ({ raptorio, reload }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  //const [balances, setBalances] = useState(false);

  const fetchData = React.useCallback(() => {
    // Set the loading state
    setLoading(true);

    const getApis = raptorio.getPortfolioEntries("apis");

    getApis.then(async (res) => {
      if (res.length < 1) {
        setLoading(false);
        return false;
      }
      const results = await Promise.all(
        res.map(async (fetch) => {
          const params = {
            service: "remote",
            method: "requestSharedNodes",
            apiKey: fetch.api,
            provider: fetch.service,
            symbol: fetch.symbol,
          };

          const serverData = getData(params);

          return serverData
            .then((data) => {
              const status = data.status;
              if (status === "success") {
                if (typeof data[params.method].results !== "undefined") {
                  const payload = data[params.method].results;
                  setData([{ provider: fetch.service, data: payload }]);
                }
              }
            })
            .finally(() => {
              setLoading(false);
            });
        })
      );
      return results;
    });
  }, [raptorio]);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  return !!data ? (
    <Card className="shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Service APIs {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table size="sm" hover>
          <thead>
            <tr>
              <th>Service</th>
              <th>Collateral</th>
              <th>Deposited</th>
            </tr>
          </thead>
          <tbody>
            {data.map((service, i) => {
              return (
                <tr key={"provider" + i}>
                  <td>{service.provider}</td>
                  <td className="font-monospace">
                    <NumericFormat
                      value={service.data.collateral}
                      defaultValue={0}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                  <td className="font-monospace">
                    <NumericFormat
                      value={service.data.deposited}
                      defaultValue={0}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  ) : (
    ""
  );
};

export default ServiceApiCard;
