import { hasJsonStructure, safeJsonParse } from "./Utilities";

export const getTransactions = async (addresses) => {
  if (!addresses) return;

  return await fetch(
    process.env.REACT_APP_API_ENDPOINT +
      "explorer/getAddressTxDump/?address=" +
      addresses
  )
    .then((response) => response.body)
    .then((rb) => {
      const reader = rb.getReader();
      return new ReadableStream({
        start(controller) {
          // The following function handles each data chunk
          function push() {
            // "done" is a Boolean and value a "Uint8Array"
            reader.read().then(({ done, value }) => {
              // If there is no more data to read
              if (done) {
                //console.log("done", done);
                controller.close();
                return;
              }
              // Get the data and send it to the browser via the controller
              controller.enqueue(value);
              // Check chunks by logging to the console
              //console.log(done, value);
              push();
            });
          }
          push();
        },
      });
    })
    .then((stream) => {
      // Respond with our stream
      return new Response(stream, {
        headers: { "Content-Type": "text/html" },
      }).text();
    })
    .then((result) => {
      // Do things with result
      let payload = result.split(/(\r?\n)/);
      payload.forEach((entry) => {
        if (hasJsonStructure(entry)) {
          const [err, result] = safeJsonParse(entry);
          if (err) {
            console.log("Failed to parse JSON: " + err.message);
          } else {
            if (result.id) {
              //console.log(result);
            }
          }
        }
      });
      return { results: payload.length, status: "Done" };
    });
};

export default getTransactions;
