import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { getData } from "../../service/Service";
import { NumericFormat } from "react-number-format";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var LocalizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const SyncStatus = () => {
  const [data, setData] = React.useState([]);

  const fetchData = React.useCallback(() => {
    const params = {
      service: "node",
      method: "getShortStatus",
    };

    const serverData = getData(params);

    serverData.then((data) => {
      const status = data.status;
      if (status === "success") {
        if (typeof data[params.method].results !== "undefined") {
          const payload = data[params.method].results;
          setData(payload);
        }
      }
    });
  }, []);

  React.useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [fetchData]);

  const determineClass = () => {
    if (!!data) {
      if (data.isSyncing) {
        return "text-warning";
      }
      if (data.isStalled) {
        return "text-danger";
      }
      if (data.isSynced) {
        return "text-success";
      }
    }
  };

  return (
    data && (
      <OverlayTrigger
        trigger="click"
        rootClose
        key="status-tooltip"
        placement="top"
        overlay={
          <Popover id="status-tooltip">
            <Popover.Header as="h3">
              {data.status_title}
              {data.isRunning || data.isStalled ? ` ${data.percent}%` : ""}
            </Popover.Header>
            <Popover.Body>
              Blocks{" "}
              <NumericFormat
                value={data.dbBlocks}
                displayType={"text"}
                thousandSeparator={true}
              />
              <br />
              {data.isRunning && (
                <span>{dayjs.unix(data.this_block).fromNow(true)} behind</span>
              )}
            </Popover.Body>
          </Popover>
        }
      >
        <div className={"d-inline-block user-select-none " + determineClass()}>
          <FontAwesomeIcon icon={faCircle} className="fa-xs" />
        </div>
      </OverlayTrigger>
    )
  );
};
export default SyncStatus;
