const locales = [
  {
    value: "ar-SA",
    desc: "Arabic (Saudi Arabia)",
  },
  {
    value: "bn-BD",
    desc: "Bangla (Bangladesh)",
  },
  {
    value: "bn-IN",
    desc: "Bangla (India)",
  },
  {
    value: "cs-CZ",
    desc: "Czech (Czech Republic)",
  },
  {
    value: "da-DK",
    desc: "Danish (Denmark)",
  },
  {
    value: "de-AT",
    desc: "Austrian German",
  },
  {
    value: "de-CH",
    desc: "'Swiss' German",
  },
  {
    value: "de-DE",
    desc: "Standard German (as spoken in Germany)",
  },
  {
    value: "el-GR",
    desc: "Modern Greek",
  },
  {
    value: "en-AU",
    desc: "Australian English",
  },
  {
    value: "en-CA",
    desc: "Canadian English",
  },
  {
    value: "en-GB",
    desc: "British English",
  },
  {
    value: "en-IE",
    desc: "Irish English",
  },
  {
    value: "en-IN",
    desc: "Indian English",
  },
  {
    value: "en-NZ",
    desc: "New Zealand English",
  },
  {
    value: "en-US",
    desc: "US English",
  },
  {
    value: "en-ZA",
    desc: "English (South Africa)",
  },
  {
    value: "es-AR",
    desc: "Argentine Spanish",
  },
  {
    value: "es-CL",
    desc: "Chilean Spanish",
  },
  {
    value: "es-CO",
    desc: "Colombian Spanish",
  },
  {
    value: "es-ES",
    desc: "Castilian Spanish (as spoken in Central-Northern Spain)",
  },
  {
    value: "es-MX",
    desc: "Mexican Spanish",
  },
  {
    value: "es-US",
    desc: "American Spanish",
  },
  {
    value: "fi-FI",
    desc: "Finnish (Finland)",
  },
  {
    value: "fr-BE",
    desc: "Belgian French",
  },
  {
    value: "fr-CA",
    desc: "Canadian French",
  },
  {
    value: "fr-CH",
    desc: "'Swiss' French",
  },
  {
    value: "fr-FR",
    desc: "Standard French (especially in France)",
  },
  {
    value: "he-IL",
    desc: "Hebrew (Israel)",
  },
  {
    value: "hi-IN",
    desc: "Hindi (India)",
  },
  {
    value: "hu-HU",
    desc: "Hungarian (Hungary)",
  },
  {
    value: "id-ID",
    desc: "Indonesian (Indonesia)",
  },
  {
    value: "it-CH",
    desc: "'Swiss' Italian",
  },
  {
    value: "it-IT",
    desc: "Standard Italian (as spoken in Italy)",
  },
  {
    value: "ja-JP",
    desc: "Japanese (Japan)",
  },
  {
    value: "ko-KR",
    desc: "Korean (Republic of Korea)",
  },
  {
    value: "nl-BE",
    desc: "Belgian Dutch",
  },
  {
    value: "nl-NL",
    desc: "Standard Dutch (as spoken in The Netherlands)",
  },
  {
    value: "no-NO",
    desc: "Norwegian (Norway)",
  },
  {
    value: "pl-PL",
    desc: "Polish (Poland)",
  },
  {
    value: "pt-BR",
    desc: "Brazilian Portuguese",
  },
  {
    value: "pt-PT",
    desc: "European Portuguese (as written and spoken in Portugal)",
  },
  {
    value: "ro-RO",
    desc: "Romanian (Romania)",
  },
  {
    value: "ru-RU",
    desc: "Russian (Russian Federation)",
  },
  {
    value: "sk-SK",
    desc: "Slovak (Slovakia)",
  },
  {
    value: "sv-SE",
    desc: "Swedish (Sweden)",
  },
  {
    value: "ta-IN",
    desc: "Indian Tamil",
  },
  {
    value: "ta-LK",
    desc: "Sri Lankan Tamil",
  },
  {
    value: "th-TH",
    desc: "Thai (Thailand)",
  },
  {
    value: "tr-TR",
    desc: "Turkish (Turkey)",
  },
  {
    value: "zh-CN",
    desc: "Mainland China, simplified characters",
  },
  {
    value: "zh-HK",
    desc: "Hong Kong, traditional characters",
  },
  {
    value: "zh-TW",
    desc: "Taiwan, traditional characters",
  },
];

export default locales;
