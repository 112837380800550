import React, { useEffect } from "react";

import {
  Badge,
  Button,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";

import {
  SIDEBAR_POSITION,
  SIDEBAR_BEHAVIOR,
  LAYOUT,
  THEME,
} from "../../constants";

import useTheme from "../../hooks/useTheme";
import useSidebar from "../../hooks/useSidebar";
import useLayout from "../../hooks/useLayout";

import Brand from "../../components/sidebar/Brand";
import Cube from "../../components/Cube";

const Navigation = () => (
  <Navbar expand="md" className="landing-navbar">
    <Container>
      <Brand />
      <Badge bg="" className="badge-soft-primary p-1">
        v{process.env.REACT_APP_VERSION}
      </Badge>
      <Nav className="ms-auto" navbar>
        <Nav.Item className="d-none d-md-inline-block">
          <Nav.Link
            href="https://discordapp.com/users/816338464383041566"
            active
            className="text-lg px-lg-3"
          >
            Support
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Container>
  </Navbar>
);

const Intro = () => (
  <section className="landing-intro landing-bg pt-5 pt-lg-6 pb-5 pb-lg-7">
    <Container className="landing-intro-content">
      <Row className="align-items-center pb-5 pb-lg-7">
        <Col lg="5" className="mx-auto pb-7">
          <h1 className="my-4">{process.env.REACT_APP_TAGLINE}</h1>
          <p className="text-lg">
            One tool to rule them all.
            <br />
            Track your mining operations, smartnodes & wallets.
          </p>

          <div className="my-4">
            <Button
              href="/dashboard"
              variant="success"
              className="ms-2"
              size="lg"
            >
              Get Started
            </Button>
          </div>
        </Col>
        <Col
          lg="7"
          className="d-none d-lg-flex justify-content-center text-end"
        >
          <Cube />
        </Col>
      </Row>
    </Container>
  </section>
);

const Landing = () => {
  const { setTheme } = useTheme();
  const { setPosition, setBehavior } = useSidebar();
  const { setLayout } = useLayout();

  useEffect(() => {
    setTheme(THEME.DARK);
    setPosition(SIDEBAR_POSITION.LEFT);
    setBehavior(SIDEBAR_BEHAVIOR.STICKY);
    setLayout(LAYOUT.FLUID);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Navigation />
      <Intro />
    </React.Fragment>
  );
};

export default Landing;
