import React from "react";
import Chart from "react-apexcharts";
import { Card, Dropdown, Form, Spinner } from "react-bootstrap";
import { Calendar, Menu } from "react-feather";
import usePalette from "../../../hooks/usePalette";
import useTheme from "../../../hooks/useTheme";
import { getData } from "../../../service/Service";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var LocalizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const datasets = [
  { set: "1,days", title: "Yesterday" },
  { set: "6,days", title: "Last Week" },
  { set: "6,months", title: "Last 6 Months" },
  { set: "2,years", title: "Last Year" },
];

const BalanceChartCard = ({ raptorio, reload }) => {
  const palette = usePalette();
  const appTheme = useTheme();
  const [loading, setLoading] = React.useState(true);
  const [walletAddresses, setWalletAddresses] = React.useState([]);
  const [selectedAddresses, setSelectedAddresses] = React.useState([]);
  const [filter, setFilter] = React.useState("6,days");
  const [title, setTitle] = React.useState("Past Week");
  const [chartData, setChartData] = React.useState([]);
  const [chartOptions] = React.useState({
    chart: {
      stacked: true,
      theme: appTheme.theme,
      foreColor: palette["gray-600"],
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: "linear",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    noData: {
      text: loading ? "Loading..." : "Nothing for this time period",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: palette["danger"],
      },
    },
    grid: {
      show: true,
      borderColor: palette["gray-200"],
      strokeDashArray: 0,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        borderRadiusApplication: "around",
        borderRadiusWhenStacked: "all",
        columnWidth: "33%",
      },
    },
    stroke: {
      width: 0,
      colors: ["#fff"],
    },
    xaxis: {
      type: "category",
      tickPlacement: "on",
      formatter: (val) => {
        return val;
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
      labels: {
        formatter: (val) => {
          return val.toLocaleString(undefined, { maximumFractionDigits: 2 });
        },
      },
    },
    tooltip: {
      theme: appTheme.theme,
      y: {
        formatter: (val) => {
          return val.toLocaleString(undefined, { maximumFractionDigits: 2 });
        },
      },
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
    colors: [palette.success, palette.warning],
  });

  const fetchData = React.useCallback(
    (filter) => {
      // Set the loading state
      setLoading(true);

      const getAddresses = raptorio.getAddresses();

      getAddresses
        .then(async (data) => {
          let walletAddresses = [];
          data.map((wallet) => {
            return walletAddresses.push(wallet.address);
          });
          setWalletAddresses(walletAddresses);
          if (selectedAddresses.length === 0) {
            setSelectedAddresses(walletAddresses);
          }

          return walletAddresses;
        })
        .then(async (walletAddresses) => {
          if (selectedAddresses.length > 0) {
            walletAddresses = selectedAddresses;
          }
          const params = {
            service: "statistics",
            method: "getAddressBalanceChart",
            address: walletAddresses.join(","),
            filter: filter,
          };

          const serverData = getData(params);

          await serverData
            .then(async (res) => {
              const status = res.status;
              if (status === "success") {
                if (typeof res[params.method].results !== "undefined") {
                  setChartData(res[params.method].results);
                }
              }
            })
            .finally(() => {
              setLoading(false);
            });
        });
    },
    [raptorio, selectedAddresses]
  );

  const loadNew = React.useCallback(
    (data) => {
      setFilter(data.set);
      setTitle(data.title);
      fetchData(data.set);
    },
    [fetchData]
  );

  React.useEffect(() => {
    if (reload) {
      fetchData(filter);
    }
  }, [fetchData, reload, filter]);

  const handleSelected = (e, address) => {
    e.target.classList.toggle("selected");
    const index = selectedAddresses.indexOf(address);
    if (index > -1) {
      // only splice array when item is found
      selectedAddresses.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      selectedAddresses.push(address);
    }
    fetchData(filter);
  };

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Dropdown.Toggle
      variant="light"
      className="bg-white shadow-sm"
      size="sm"
      ref={ref}
    >
      {children}
    </Dropdown.Toggle>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = React.useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  const AddressTitle = () => {
    if (selectedAddresses.length === 0) {
      return "";
    }
    if (selectedAddresses.length === walletAddresses.length) {
      return <span className="small d-block">All Addresses</span>;
    }

    if (
      selectedAddresses.length > 1 &&
      selectedAddresses.length < walletAddresses.length
    ) {
      return (
        <span className="small d-block">
          ({selectedAddresses.length}) Addresses
        </span>
      );
    }

    if (selectedAddresses.length === 1) {
      return <span className="small d-block">{selectedAddresses[0]}</span>;
    }
  };

  return (
    <Card className="flex-grow-1 align-self-stretch shadow-lg">
      <Card.Header>
        <div className="card-actions float-end">
          <Dropdown
            align={{ lg: "end" }}
            className="d-inline me-2"
            autoClose="outside"
          >
            <Dropdown.Toggle as={CustomToggle}>
              <Menu className="align-middle" />{" "}
              <span className="mx-2">Addresses</span>
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {walletAddresses.map((address, i) => {
                return (
                  <Dropdown.Item
                    key={`selected-dd-${i}`}
                    className={
                      selectedAddresses.includes(address) ? "selected" : ""
                    }
                    onClick={(e) => handleSelected(e, address)}
                  >
                    {address}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown align={{ lg: "end" }} className="d-inline me-2">
            <Dropdown.Toggle
              variant="light"
              className="bg-white shadow-sm"
              size="sm"
            >
              <Calendar className="feather align-middle" />{" "}
              <span className="mx-2">{title}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {datasets.map((data) => {
                return (
                  <Dropdown.Item
                    key={data.set}
                    onClick={() => {
                      loadNew(data);
                    }}
                    active={filter === data.set}
                  >
                    {data.title}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title tag="h5" className="mb-0">
          Movements {loading && <Spinner animation="border" size="sm" />}
          <AddressTitle />
        </Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        <div className="align-self-center w-100">
          <div className="chart chart-lg">
            <Chart
              options={chartOptions}
              series={chartData}
              type="bar"
              height="350"
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BalanceChartCard;
