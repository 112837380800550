import React from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  Alert,
  Badge,
  Card,
  Container,
  Table,
  Row,
  Col,
} from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { getData } from "../../service/Service";
import { truncate } from "../../utils/Formatters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLockOpen,
  faLock,
  faChevronUp,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import ScrollToTop from "react-scroll-to-top";
import { ExplorerSubBreadcrumbs } from "../../components/Breadcrumbs";

const ExtraTypeName = (type) => {
  //https://dashcore.readme.io/docs/core-ref-transactions-special-transactions
  switch (type) {
    case 0:
      return "Standard Transaction";
    case 1:
      return "Masternode Registration";
    case 2:
      return "Update Masternode Service";
    case 3:
      return "Update Masternode Operator";
    case 4:
      return "Masternode Operator Revocation";
    case 5:
      return "Coinbase";
    //return "Masternode List Merkle Proof";
    case 6:
      return "Long-Living Masternode Quorum Commitment";
    case 7:
      //return "Masternode hard fork signal";
      return "Future";
    case 8:
      return "Asset";
    case 9:
      return "Update Asset";
    default:
      return "Unknown Type";
  }
};

const ExtraTypeClass = (type) => {
  //https://dashcore.readme.io/docs/core-ref-transactions-special-transactions
  // see above for transaction types
  switch (type) {
    case 0:
      return "success";
    case 1:
      return "info";
    case 2:
      return "info";
    case 3:
      return "info";
    case 4:
      return "info";
    case 5:
      return "primary";
    case 6:
      return "info";
    case 7:
      return "danger";
    case 8:
      return "warning";
    case 9:
      return "warning";
    default:
      return "secondary";
  }
};

const TransactionHeader = ({ data, loading }) => {
  const { pathname, hash, key } = useLocation();
  const [didScroll, setDidScroll] = React.useState(false);
  React.useEffect(() => {
    // if not a hash link, scroll to top
    if (hash !== "") {
      if (!loading && !didScroll) {
        setTimeout(() => {
          const id = hash.replace("#", "");
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
            element.parentElement.parentElement.classList = "table-active";
          }
          setDidScroll(true);
        }, 0);
      }
    }
  }, [pathname, hash, key, loading, didScroll, setDidScroll]); // do this on route change
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card className="shadow-lg mb-5 rounded">
            <Card.Header>
              <Card.Title tag="h6">Transaction</Card.Title>
              <h6 className="card-subtitle text-muted font-monospace">
                {data.txid}
              </h6>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Table className="font-monospace fs-medium" size="sm">
                    <tbody>
                      <tr>
                        <th>Block: </th>
                        <td className="text-break">
                          {data.block_hash}
                          <br />
                          <span className="badge bg-dark text-light">
                            <Link
                              to={"../block/" + data.block_height}
                              className="text-reset cursor-zoom-in"
                            >
                              #
                              <NumericFormat
                                value={data.block_height}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </Link>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>Timestamp:</th>
                        <td>{data.time_format}</td>
                      </tr>
                      <tr>
                        <th>Version:</th>
                        <td>{data.n_version}</td>
                      </tr>
                      <tr>
                        <th>Size:</th>
                        <td>
                          <NumericFormat
                            value={data.n_size}
                            displayType={"text"}
                            thousandSeparator={true}
                          />{" "}
                          ({data.n_size_format})
                        </td>
                      </tr>
                      <tr>
                        <th>Locktime:</th>
                        <td>
                          Spendable in block{" "}
                          <Link
                            to={"../block/" + data.locktime}
                            className="text-info cursor-zoom-in"
                          >
                            <NumericFormat
                              value={data.locktime}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </Link>{" "}
                          or later
                        </td>
                      </tr>
                      <tr>
                        <th>Confirmations:</th>
                        <td>
                          <span
                            className={
                              data.confirmations >= 6
                                ? "text-success"
                                : "text-warning"
                            }
                          >
                            <NumericFormat
                              value={data.confirmations}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>Fees Collected:</th>
                        <td>{data.fee_collected_format}</td>
                      </tr>
                      <tr>
                        <th>Fees Paid:</th>
                        <td>{data.fee_paid_format}</td>
                      </tr>
                      <tr>
                        <th>Fee Rate:</th>
                        <td>{data.fee_rate_format}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col>
                  <Table className="font-monospace fs-medium" size="sm">
                    <tbody>
                      <tr>
                        <th>Type:</th>
                        <td>
                          <Badge bg={ExtraTypeClass(data.n_type)}>
                            {ExtraTypeName(data.n_type)}
                          </Badge>
                        </td>
                      </tr>
                      {data.extra_payload_size > 0 && (
                        <React.Fragment>
                          <tr>
                            <th>Extra Size:</th>
                            <td>{data.extra_payload_size}</td>
                          </tr>
                          <tr>
                            <th>Extra Payload:</th>
                            <td>{truncate(data.extra_payload, 10)}</td>
                          </tr>
                        </React.Fragment>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {data.vin && !!data.vin.results[0]?.coinbase && (
        <Row>
          <Col>
            <Card className="shadow-lg mb-5 rounded">
              <Card.Header>
                <Card.Title tag="h6">Coinbase</Card.Title>
              </Card.Header>
              <Card.Body>
                <Card className="shadow-sm rounded">
                  <Card.Header className="p-0 m-0">
                    <Card.Title tag="h6">Hex</Card.Title>
                  </Card.Header>
                  <Card.Body className="p-0 bg-light font-monospace">
                    {data.vin.results[0].coinbase}
                  </Card.Body>
                </Card>
                <Card className="shadow-sm rounded">
                  <Card.Header className="p-0 m-0">
                    <Card.Title tag="h6">Decoded</Card.Title>
                  </Card.Header>
                  <Card.Body className="p-0 bg-light font-monospace">
                    {data.vin.results[0].coinbase_decode}
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {data.vout && data.vout.results.summary.op_return && (
        <Row>
          <Col>
            <Card className="shadow-lg mb-5 rounded">
              <Card.Header>
                <Card.Title tag="h6">Message</Card.Title>
              </Card.Header>
              <Card.Body className="font-monospace">
                {data.vout.results.summary.op_return}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {data.vin && (
        <Row>
          <Col>
            <Card className="shadow-lg mb-5 rounded">
              <Card.Header>
                <Card.Title tag="h6">Transactions</Card.Title>
                <h6 className="card-subtitle text-muted font-monospace">
                  <span>
                    {Object.keys(data.vin.results).length - 1} Input,{" "}
                    {Object.keys(data.vout.results).length - 1} Output
                  </span>
                </h6>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Table className="font-monospace" size="sm" hover>
                      <tbody>
                        {Object.keys(data.vin.results).map(
                          (key, index) =>
                            (data.vin.results[key].address && (
                              <tr key={"vin" + index}>
                                <td className="col-xs-1">{index + 1}</td>
                                <td>
                                  <Link
                                    to={
                                      "/explorer/address/" +
                                      data.vin.results[key].address
                                    }
                                    className="cursor-zoom-in"
                                    id={"input-" + index}
                                  >
                                    <span className="d-none d-xl-inline-block">
                                      {data.vin.results[key].address}
                                    </span>
                                    <span className="d-xl-none">
                                      {truncate(
                                        data.vin.results[key].address,
                                        10
                                      )}
                                    </span>
                                  </Link>
                                  <p className="small">
                                    via{" "}
                                    <Link
                                      to={
                                        "/explorer/tx/" +
                                        data.vin.results[key].txid +
                                        "#output-" +
                                        data.vin.results[key].n_vout
                                      }
                                      className="text-reset cursor-zoom-in"
                                    >
                                      {truncate(data.vin.results[key].txid, 10)}
                                    </Link>{" "}
                                    [{data.vin.results[key].n_vout}]
                                  </p>
                                </td>
                                <td className="text-end">
                                  {data.vin.results[key].satoshis_format}
                                </td>
                              </tr>
                            )) ||
                            (data.vin.results[key].coinbase && (
                              <tr key={"vin" + index}>
                                <td>{index + 1}</td>
                                <td>
                                  <span className="badge bg-info text-dark">
                                    coinbase
                                  </span>{" "}
                                  Newly minted coins
                                </td>
                                <td className="text-end">5,000.00</td>
                              </tr>
                            ))
                        )}
                        <tr>
                          <td colSpan="3" className="text-end fw-bolder">
                            {data.vin.results.summary.valueIn_format}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col>
                    <Table className="font-monospace" size="sm" hover>
                      <tbody>
                        {Object.keys(data.vout.results).map(
                          (key, index) =>
                            data.vout.results[key].satoshis_format && (
                              <tr key={"vout" + index}>
                                <td className="col-xs-1">{index + 1}</td>
                                <td className="text-start col-xs-1">
                                  {data.vout.results[key].spent === "spent" ||
                                  data.vout.results[key].op_return !== "" ? (
                                    <FontAwesomeIcon
                                      icon={faLockOpen}
                                      fixedWidth
                                      className="text-secondary"
                                      title="Spent output"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faLock}
                                      fixedWidth
                                      className="text-success"
                                      title="Unspent output"
                                    />
                                  )}
                                </td>
                                <td>
                                  {data.vout.results[key].address ? (
                                    <Link
                                      to={
                                        "/explorer/address/" +
                                        data.vout.results[key].address
                                      }
                                      className="cursor-zoom-in"
                                      id={"output-" + index}
                                    >
                                      <span className="d-none d-xl-inline-block">
                                        {data.vout.results[key].address}
                                      </span>
                                      <span className="d-xl-none">
                                        {truncate(
                                          data.vout.results[key].address,
                                          10
                                        )}
                                      </span>
                                    </Link>
                                  ) : (
                                    data.vout.results[key].op_return
                                  )}
                                </td>
                                <td className="text-end">
                                  {data.vout.results[key].satoshis_format}{" "}
                                </td>
                              </tr>
                            )
                        )}
                        <tr>
                          <td colSpan="4" className="text-end fw-bolder">
                            {data.vout.results.summary.valueOut_format}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <ScrollToTop
        smooth
        className="bg-dark"
        component={
          <FontAwesomeIcon
            icon={faChevronUp}
            fixedWidth
            size="lg"
            className="text-primary"
            title="Up"
          />
        }
        style={{ bottom: 50, right: 15 }}
      />
    </React.Fragment>
  );
};

const Transaction = () => {
  const { tx } = useParams();
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const refreshInterval = 60000;
  const fetchData = React.useCallback(({ tx }) => {
    // Set the loading state
    setLoading(true);

    const params = {
      service: "explorer",
      method: "getTransaction",
      tx: tx,
    };

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setData(payload);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    fetchData({ tx });
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(() => {
        fetchData({ tx });
      }, refreshInterval);
      return () => {
        clearInterval(interval);
      };
    }
  }, [fetchData, tx, refreshInterval]);

  return (
    <React.Fragment>
      <Helmet title={"TX " + tx} />
      <Container fluid className="p-0">
        <ExplorerSubBreadcrumbs
          tabname="Transactions"
          subname={tx}
          link="transactions"
          truncatesub={true}
        />
        {data && <TransactionHeader data={data} loading={loading} />}
        {!loading && !data && (
          <Alert key="tx-alert" variant="info">
            <p className="float-start p-3 m-0 text-center">
              <FontAwesomeIcon icon={faInfoCircle} fixedWidth size="4x" />
            </p>
            <p className="p-3 m-0 float-end">
              <strong>Couldn't find data for this transaction!</strong>
              <br />
              This transaction doesn't seem to exist yet...
            </p>
          </Alert>
        )}
      </Container>
    </React.Fragment>
  );
};

export default Transaction;
