import React from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import LocalMiner from "./LocalMiner";
import Raptorio from "../../service/Raptorio";
const raptorio = new Raptorio({ coin: "RTM" });

const minerConfig = {
  api: {
    id: null,
    "worker-id": null,
  },
  http: {
    enabled: true,
    host: "0.0.0.0",
    port: 42069,
    "access-token": null,
    restricted: true,
  },
  autosave: true,
  background: false,
  colors: true,
  title: true,
  randomx: {
    init: -1,
    "init-avx2": -1,
    mode: "auto",
    "1gb-pages": false,
    rdmsr: true,
    wrmsr: true,
    cache_qos: false,
    numa: true,
    scratchpad_prefetch_mode: 1,
  },
  cpu: {
    enabled: true,
    "huge-pages": true,
    "huge-pages-jit": false,
    "hw-aes": null,
    priority: null,
    "memory-pool": false,
    yield: true,
    "max-threads-hint": 100,
    asm: true,
    "argon2-impl": null,
    "cn/0": false,
    "cn-lite/0": false,
  },
  opencl: {
    enabled: false,
    cache: true,
    loader: null,
    platform: "AMD",
    adl: true,
    "cn/0": false,
    "cn-lite/0": false,
  },
  cuda: {
    enabled: false,
    loader: null,
    nvml: true,
    "cn/0": false,
    "cn-lite/0": false,
  },
  "donate-level": 1,
  "donate-over-proxy": 1,
  "log-file": null,
  pools: [
    {
      algo: "ghostrider",
      coin: null,
      url: "usa-east-1.raptoreum.zone:4444",
      user: "YOUR_WALLET_ADDRESS",
      pass: "x123",
      "rig-id": null,
      nicehash: false,
      keepalive: false,
      enabled: true,
      tls: true,
      "tls-fingerprint": null,
      daemon: false,
      socks5: null,
      "self-select": null,
      "submit-to-origin": false,
    },
  ],
  "print-time": 60,
  "health-print-time": 60,
  dmi: true,
  retries: 5,
  "retry-pause": 5,
  syslog: false,
  tls: {
    enabled: false,
    protocols: null,
    cert: null,
    cert_key: null,
    ciphers: null,
    ciphersuites: null,
    dhparam: null,
  },
  dns: {
    ipv6: false,
    ttl: 30,
  },
  "user-agent": null,
  verbose: 0,
  watch: true,
  "pause-on-battery": false,
  "pause-on-active": false,
};

const DownloadMiner = ({ setMinerDir }) => {
  const isWin = window.envVars.platform === "win32";
  // Listen on this channel for an incoming test.
  window.ipcRender.receive("xmrig-ready", (dir) => {
    setMinerDir(dir);
  });

  const handleDownload = async () => {
    let releaseUrl = "";
    await axios
      .get("https://api.github.com/repos/xmrig/xmrig/releases")
      .then((res) => {
        const latest = res.data[0].assets;
        const release = latest.filter((asset) => {
          if (isWin) {
            return asset.name.includes("gcc-win64");
          } else {
            if (window.envVars.platform === "darwin") {
              return asset.name.includes("macos-x64");
            } else {
              return asset.name.includes("linux-static-x64");
            }
          }
        });

        releaseUrl = release[0].browser_download_url;

        //var downloadURL = isWin ? latest.zipball_url : latest.tarball_url;
        //console.log(downloadURL);
      });
    window.ipcRender.send("download-xmrig", { url: releaseUrl });
    //return releaseUrl;

    //downloadXmrig(release[0].browser_download_url);
  };

  return (
    <Button onClick={handleDownload}>
      Get miner for {window.envVars.platform}
    </Button>
  );
};

const Miner = () => {
  const [minerDir, setMinerDir] = React.useState(false);
  //const [configDir, setConfigDir] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  //const [portfolio, setPortfolio] = React.useState(false);
  const [refreshInterval] = React.useState(5000);
  const [payoutAddress, setPayoutAddress] = React.useState(false);
  const [rigName, setRigName] = React.useState(false);

  const [addresses, setAddresses] = React.useState(false);

  const [xmrigReady, setXmrigReady] = React.useState(false);
  const [isXmrigRunning, setIsXmrigRunning] = React.useState(false);

  const checkConfig = () => {
    const xmrigExists = window.ipcRender.sendSync("check-xmrig", "ok");
    setXmrigReady(xmrigExists);
  };

  const isMining = () => {
    const checkMining = window.ipcRender.sendSync("is-mining", "ok");
    setIsXmrigRunning(checkMining);
  };

  const fetchAddresses = async () => {
    return raptorio.getAddresses().then((wallets) => {
      let walletAddresses = [];
      let walletLabels = [];
      //setWallets(data);
      wallets.map((wallet) => {
        let labels = {};
        labels.address = wallet.address;
        labels.label = wallet.label;
        walletLabels.push(labels);
        walletAddresses.push(wallet.address);
        return true;
      });
      setAddresses(walletAddresses);
    });
  };

  const handleAddressChange = (e) => {
    setPayoutAddress(e.target.value);
  };

  const handleRigName = (e) => {
    setRigName(e.target.value);
  };

  const setConfig = () => {
    //window.ipcRender.receive("config-exists", (dir) => {
    //setConfigDir(dir);
    //});
    minerConfig.pools.map((pool) => {
      pool.user = payoutAddress;
      pool["rig-id"] = rigName;
      return pool;
    });

    //console.log(minerConfig);
    window.ipcRender.send("save-config", {
      dir: minerDir,
      config: minerConfig,
    });
  };

  const startMining = () => {
    const startMining = window.ipcRender.sendSync("start-mining", "start");
    setIsXmrigRunning(startMining);
    //setRefreshInterval(5000);
  };

  const stopMining = () => {
    window.ipcRender.send("stop-mining", "stop");
    setIsXmrigRunning(false);
    //setRefreshInterval(0);
  };

  React.useEffect(() => {
    const fetchData = () => {
      // Set the loading state
      setLoading(true);
      if (!xmrigReady) {
        fetchAddresses();
        checkConfig();
      } else {
        isMining();
      }

      setLoading(false);
    };

    fetchData();
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(fetchData, refreshInterval);
      return () => {
        clearInterval(interval);
      };
    }
  }, [refreshInterval, xmrigReady]);

  return (
    <React.Fragment>
      <Helmet title="Mine RTM!" />
      <Container fluid className="p-0">
        <span>Mine RTM! {loading ? "loading" : ""}</span>
        {!xmrigReady && (
          <React.Fragment>
            <DownloadMiner setMinerDir={setMinerDir} />
            Downloaded to {minerDir}
            Mine with address
            <Form.Select onChange={handleAddressChange}>
              <option key="0">Choose Payout Address</option>
              {!!addresses &&
                addresses.map((address, i) => {
                  return (
                    <option key={`address-${i}`} value={address}>
                      {address}
                    </option>
                  );
                })}
            </Form.Select>
            <Form.Control
              type="text"
              id="RigName"
              aria-describedby="Rig Name"
              onChange={handleRigName}
            />
            <Button onClick={setConfig}>Save Config</Button>
          </React.Fragment>
        )}
        {!!xmrigReady && (
          <React.Fragment>
            {!isXmrigRunning && (
              <Button onClick={startMining} disabled={isXmrigRunning}>
                Start Mining!
              </Button>
            )}
            {isXmrigRunning && (
              <Button
                variant="danger"
                onClick={stopMining}
                disabled={!isXmrigRunning}
              >
                Stop Mining!
              </Button>
            )}
            {isXmrigRunning && <LocalMiner />}
          </React.Fragment>
        )}
        <Row>
          <Col>
            <Card>
              <Card.Header>Information</Card.Header>
              <Card.Body>
                <p>
                  {process.env.REACT_APP_NAME} will prompt you for a password in
                  order to run the miner with elevated privileges for improved
                  hashrates.
                </p>
                <p>
                  The dialog is your OS password prompt and is not a part of{" "}
                  {process.env.REACT_APP_NAME}. Passwords are not saved. <br />
                  Because the miner runs with elevated privileges, you will need
                  to re-enter your password to stop the miner as well.
                </p>
                <p>
                  The miner will run until you specifically click the button
                  "Stop Mining!". The miner <em>will</em> continue after closing
                  out of {process.env.REACT_APP_NAME}.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Miner;
