import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Card, Image, Row } from "react-bootstrap";

import illustration from "../../assets/img/illustrations/spaceman.png";
import beer from "../../assets/img/illustrations/beer.png";
import NotyfContext from "../../contexts/NotyfContext";
import { copyEntry } from "../../utils/interactions";
import NewUserCard from "../portfolio/components/NewUserCard";

const Header = ({ reload, portfolio }) => {
  const { t } = useTranslation();
  const notyf = React.useContext(NotyfContext);

  const provideNotyf = (message, type, duration = 2000) => {
    notyf.open({
      type,
      message,
      duration,
      dismissible: true,
      ripple: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };
  const copyCallback = (msg, status) => {
    provideNotyf(msg, status);
  };
  const copyTipJar = () => {
    copyEntry(process.env.REACT_APP_TIPJAR, copyCallback);
  };

  return !reload && portfolio ? (
    <Row>
      <Col md="auto">
        <Card className="text-bg-info shadow-lg">
          <Card.Body className="p-0">
            <div className="float-start p-3 text-static-white">
              <p className="fs-4">{t("Welcome back")}!</p>
              <p className="mt-3 mb-0">
                {process.env.REACT_APP_NAME} Dashboard
              </p>
            </div>
            <div className="float-end">
              <Image
                src={illustration}
                fluid
                rounded
                style={{ width: 100, height: 100 }}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="auto">
        <Card className="text-bg-warning shadow-lg">
          <Card.Body className="p-0">
            <div className="float-end p-3 text-static-white">
              <p className="fs-5 m-0">Fill up the beer fund!</p>
              <p className="mb-0 small text-wrap">
                {process.env.REACT_APP_NAME} works better with your support!
                <br />
                <span
                  className="font-monospace smaller user-select-all"
                  onClick={() => copyTipJar()}
                >
                  {process.env.REACT_APP_TIPJAR}
                </span>
              </p>
            </div>
            <div className="float-start">
              <Image
                src={beer}
                fluid
                rounded
                style={{ width: 100, height: 100 }}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  ) : (
    !reload && (
      <Row sm="auto" className="justify-content-md-center">
        <Col>
          <NewUserCard />
        </Col>
      </Row>
    )
  );
};

export default Header;
