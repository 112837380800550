import {
  Box,
  Cpu,
  CreditCard,
  DollarSign,
  Settings,
  Sliders,
  Target,
} from "react-feather";

const dashboardSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboard",
  },
];

const portfolioSection = [
  {
    href: "/portfolio",
    icon: Target,
    title: "Portfolio",
  },
];

const walletSection = [
  {
    href: "/wallet",
    icon: CreditCard,
    title: "Wallet",
  },
];

const minerSection = [
  {
    href: "/miner",
    icon: Cpu,
    title: "Miner",
  },
];

const settingsSection = [
  {
    href: "/settings",
    icon: Settings,
    title: "Settings",
    children: [
      {
        href: "/settings/portfolio",
        title: "Portfolio",
      },
      {
        href: "/settings/preferences",
        title: "Preferences",
      },
    ],
  },
];

const toolsSection = [
  {
    href: "/market",
    icon: DollarSign,
    title: "Market",
  },
  {
    href: "/explorer",
    icon: Box,
    title: "Explorer",
    children: [
      {
        href: "/explorer/dashboard",
        title: "Dashboard",
      },
      {
        href: "/explorer/blocks",
        title: "Blocks",
      },
      {
        href: "/explorer/transactions",
        title: "Transactions",
      },
      {
        href: "/explorer/addresses",
        title: "Addresses",
      },
      {
        href: "/explorer/nodes",
        title: "Smartnodes",
      },
      {
        href: "/explorer/messages",
        title: "Messages",
      },
    ],
  },
];

const navItems = [
  {
    title: "",
    pages: dashboardSection,
  },
  {
    title: "",
    pages: portfolioSection,
  },
  {
    title: "",
    pages: walletSection,
  },
  {
    title: "Raptorio",
    pages: settingsSection,
  },
  {
    title: "Tools",
    pages: toolsSection,
  },
];

if (typeof window.envVars !== "undefined") {
  // Electron-specific code
  //console.log("is electron");
  let electronNav = {
    title: "Mine RTM!",
    pages: minerSection,
  };
  navItems.push(electronNav);
}

export default navItems;
