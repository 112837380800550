import React from "react";
import { Card, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getData } from "../../../service/Service";
import { truncate } from "../../../utils/Formatters";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var LocalizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const htmlDecode = (input) => {
  var e = document.createElement("div");
  e.innerHTML = input;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
};

const BlockchainMsgCard = ({ reload }) => {
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const fetchData = React.useCallback(() => {
    const params = {
      service: "statistics",
      method: "getRecentOPMessage",
    };

    setLoading(true);

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setData(payload);
          }
        } else {
          console.log(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  return (
    <Card className="flex-grow-1 align-self-stretch shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Recent Messages {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {!!data && (
          <div>
            <blockquote className="blockquote mb-3">
              <p
                dangerouslySetInnerHTML={{ __html: htmlDecode(data.op_return) }}
              />
              <footer className="blockquote-footer">
                <cite title="Source Title">Someone famous probably</cite>
              </footer>
            </blockquote>
            <div>
              <Link to={"/explorer/tx/" + data.txid}>
                <span className="d-none d-xl-block">{data.txid}</span>
                <span className="d-xl-none">{truncate(data.txid, 10)}</span>
              </Link>
            </div>
            <div>
              <span className="small text-secondary">
                {data && dayjs.unix(data.created_at).fromNow()}{" "}
                {data && dayjs.unix(data.created_at).format("L LT")}
              </span>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default BlockchainMsgCard;
