import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";

import { Search } from "react-feather";
import NotyfContext from "../../contexts/NotyfContext";
import { useNavigate } from "react-router-dom";
import { getData } from "../../service/Service";

const NavbarSearch = () => {
  const { t } = useTranslation();
  const notyf = React.useContext(NotyfContext);
  const provideNotyf = (message, type, duration = 2000) => {
    notyf.open({
      type,
      message,
      duration,
      dismissible: true,
      ripple: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const handleSearch = () => {
    let search = document.getElementById("raptorio-search");

    if (search.value === "") {
      return false;
    }

    const params = {
      service: "ExplorerSearch",
      method: "searchFor",
      filter: search.value,
    };

    setLoading(true);

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            if (typeof payload.data !== "undefined") {
              search.value = "";
              navigate("/explorer/" + payload.data.join("/"));
            } else {
              provideNotyf(payload.msg, "warning");
            }

            //setData(payload);
          }
        } else {
          console.log(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Form inline="true" onSubmit={(e) => e.preventDefault()}>
      <InputGroup className="input-group-navbar input-group-sm">
        <Form.Control
          id="raptorio-search"
          size="sm"
          placeholder={t("Search") + "height/hash, txid, address"}
          aria-label="Search"
        />
        <Button
          variant=""
          onClick={() => handleSearch()}
          disabled={loading}
          type="submit"
          tabIndex="-1"
        >
          {loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <Search className="feather" />
          )}
        </Button>
      </InputGroup>
    </Form>
  );
};

export default NavbarSearch;
