import React from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import AddButton from "./AddWidgets";

const HeaderControls = ({
  name,
  category,
  size,
  variant,
  handleAdd,
  handleNativeAdd,
  copyAll,
  handleRefresh,
  addFields,
  addMethods,
}) => {
  return (
    <Dropdown as={ButtonGroup}>
      <AddButton
        name={name}
        category={category}
        size={size}
        variant={variant}
        handleAdd={handleAdd}
        handleNativeAdd={handleNativeAdd}
        addMethods={addMethods}
        addFields={addFields}
        className="text-success"
      />
      <Dropdown.Toggle
        split
        variant="light"
        size="sm"
        id="dropdown-split-basic"
      />
      <Dropdown.Menu className="p-1">
        <Dropdown.Item className="ps-2 small" onClick={() => copyAll()}>
          <FontAwesomeIcon className="me-2" icon={faCopy} />
          Copy All
        </Dropdown.Item>
        {category === "native" && (
          <Dropdown.Item className="ps-2 small" onClick={() => handleRefresh()}>
            <FontAwesomeIcon className="me-2" icon={faRedoAlt} />
            Refresh Transactions
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export { HeaderControls, AddButton };
