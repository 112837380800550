import { useState } from "react";

export const useModal = () => {
  let [show, setShow] = useState(false);
  let [options, setOptions] = useState({});

  let handleModal = (options = false) => {
    setShow(!show);
    if (options) {
      setOptions(options);
    }
  };

  const hideModal = () => {
    setShow(false);
  };

  return { show, handleModal, options, hideModal };
};

export default useModal;
