import React, { useCallback, useContext } from "react";
import { Alert, Button, Dropdown } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { ModalContext } from "../../../../contexts/ModalContext";

export const DeleteDropdownItem = ({
  doc,
  entryKey,
  name,
  category,
  value,
  handleDelete,
}) => {
  const { handleModal, hideModal } = useContext(ModalContext);
  const memoizedCallback = useCallback(
    (doc) => {
      hideModal();
      handleDelete(doc);
    },
    [hideModal, handleDelete]
  );

  return (
    <React.Fragment>
      <Dropdown.Divider />
      <Dropdown.Item
        disabled={
          (entryKey === "0" && doc.category === "native") ||
          (doc.isDefault && category === "portfolio")
        }
        as="a"
        className={
          (entryKey === "0" && doc.category === "native") ||
          (doc.isDefault && category === "portfolio")
            ? "ps-2 small text-secondary"
            : "ps-2 small text-danger"
        }
        onClick={() =>
          handleModal({
            className: "text-center",
            confirmDelete: true,
            content: (
              <React.Fragment>
                Are you sure you want to delete
                <br />
                <strong className="text-danger">
                  {isNaN(value) ? (
                    value
                  ) : (
                    <NumericFormat
                      value={value}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  )}
                </strong>
                <br />
                from <em>{name}</em>?
                {category === "portfolio" && (
                  <Alert key="delete-alert" variant="danger" className="mt-3">
                    <div className="float-start p-3 m-0">
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        fixedWidth
                        size="4x"
                      />
                    </div>
                    <div className="p-3 m-0 float-end text-start">
                      <strong>ALL KEYS WILL BE LOST.</strong>
                      <br />
                      <small>
                        If you are confident that you have documented your seed
                        phrase, confirm to proceed.
                      </small>
                    </div>
                  </Alert>
                )}
                <br />
              </React.Fragment>
            ),
            header: (
              <React.Fragment>
                <FontAwesomeIcon className="me-2" icon={faTrash} />
                <span className="fw-light">Delete from {name}</span>
              </React.Fragment>
            ),
            footer: (
              <Button
                variant="danger"
                size="sm"
                onClick={() => memoizedCallback(doc)}
              >
                <FontAwesomeIcon className="me-2" icon={faTrash} fixedWidth />
                Delete
              </Button>
            ),
          })
        }
      >
        <FontAwesomeIcon className="me-2" icon={faTrash} />
        Delete
      </Dropdown.Item>
    </React.Fragment>
  );
};

const exportable = {
  DeleteDropdownItem,
};

export default exportable;
