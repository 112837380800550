import React from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import DataTable from "../../components/tables/DataTable";
import { ExplorerBreadcrumbs } from "../../components/Breadcrumbs";
import { getData } from "../../service/Service";
import BlockCard from "./components/BlockCard";
import ChainworkCard from "./components/ChainworkCard";
import ChainsizeCard from "./components/ChainsizeCard";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var updateLocale = require("dayjs/plugin/updateLocale");
var utc = require("dayjs/plugin/utc");
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "1 minute",
    mm: "%d minutes",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day",
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years",
  },
});

const Blocks = () => {
  // We'll start our table without any data
  const [loading, setLoading] = React.useState(false);
  const defaultSortCol = React.useRef("block_height");
  const service = React.useRef("explorer");
  const method = React.useRef("getBlockList");

  const BlockListCols = React.useMemo(
    () => [
      {
        id: "block_height",
        cell: (info) => info.getValue(),
        header: () => <span>Height</span>,
        footer: (props) => props.column.id,
        accessorFn: (row) => {
          return (
            <Link
              to={"/explorer/block/" + row.block_height}
              className="cursor-zoom-in"
            >
              <NumericFormat
                value={row.block_height}
                displayType={"text"}
                thousandSeparator={true}
              />
            </Link>
          );
        },
      },
      {
        id: "miner",
        enableSorting: false,
        cell: (info) => info.getValue(),
        header: () => <span>Miner</span>,
        footer: (props) => props.column.id,
        accessorFn: (row) => {
          return (
            <OverlayTrigger
              key={`miner-${row.block_height}`}
              placement="top"
              delay={{ show: 500, hide: 100 }}
              overlay={<Tooltip id="tooltip-top">{row.miner}</Tooltip>}
            >
              <span
                className={
                  row.miner_name !== "Unknown"
                    ? "badge bg-success"
                    : "badge bg-light text-dark"
                }
              >
                {row.miner_name}
              </span>
            </OverlayTrigger>
          );
        },
      },
      {
        id: "txs",
        cell: (info) => info.getValue(),
        header: () => (
          <span>
            <span className="d-none d-lg-inline">Transactions</span>
            <span className="d-lg-none">TXs</span>
          </span>
        ),
        footer: (props) => props.column.id,
        accessorKey: "transactions",
      },
      {
        id: "n_size",
        cell: (info) => info.getValue(),
        header: () => <span>Bytes</span>,
        footer: (props) => props.column.id,
        accessorFn: (row) => {
          return (
            <OverlayTrigger
              key={`size-${row.block_height}`}
              placement="left"
              delay={{ show: 500, hide: 100 }}
              overlay={<Tooltip id="tooltip-left">{row.n_size} bytes</Tooltip>}
            >
              <span>{row.n_size_format}</span>
            </OverlayTrigger>
          );
        },
      },
      {
        id: "n_time",
        cell: (info) => info.getValue(),
        header: () => <span>Age</span>,
        footer: (props) => props.column.id,
        accessorFn: (row) => {
          return (
            <OverlayTrigger
              key={`age-${row.block_height}`}
              placement="top"
              delay={{ show: 500, hide: 100 }}
              overlay={<Tooltip id="tooltip-top">{row.time_format}</Tooltip>}
            >
              <span>{dayjs.unix(row.n_time).fromNow(true)}</span>
            </OverlayTrigger>
          );
        },
      },
    ],
    []
  );

  const fetchData = React.useCallback(
    ({ pageIndex, pageSize, sorting, globalFilter }) => {
      // Set the loading state
      setLoading(true);

      const sortBy = sorting[0]?.id ?? defaultSortCol.current;
      const sortDir =
        sorting[0]?.id !== undefined
          ? sorting[0]?.desc === true
            ? "DESC"
            : "ASC"
          : "DESC";

      const params = {
        service: service.current,
        method: method.current,
        page: pageIndex,
        limit: pageSize,
        dir: sortDir,
        sort: sortBy,
        filter: globalFilter,
      };

      const serverData = getData(params)
        .then((data) => {
          const status = data.status;
          if (status === "success") {
            if (typeof data[params.method].results !== "undefined") {
              const payload = data[params.method].results;
              const count = parseInt(data[params.method].summary.pages);

              return { rows: payload, pageCount: count };
              //setPageCount(parseInt(data[params.method].summary.pages));
            }
          } else {
            throw new Error("didnt get data");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .then((data) => {
          setLoading(false);
          return data;
        });
      return serverData;
    },
    []
  );

  return (
    <React.Fragment>
      <Helmet title="Blocks" />
      <Container fluid className="p-0">
        <ExplorerBreadcrumbs tabname="Blocks" />
        <Row xs={1} md={3}>
          <Col className="d-flex">
            <BlockCard reload={loading} />
          </Col>
          <Col className="d-flex">
            <ChainworkCard reload={loading} />
          </Col>
          <Col className="d-flex">
            <ChainsizeCard reload={loading} />
          </Col>
        </Row>
        <Row>
          <Col>
            <DataTable
              queryKey={method.current}
              columns={BlockListCols}
              fetchData={fetchData}
              loading={loading}
              placeholderText="Search (by height or hash)"
              defaultSortCol={defaultSortCol.current}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Blocks;
