import React from "react";
import CreateWallet from "./CreateWallet";
import ImportWallet from "./ImportWallet";

const HeaderControls = ({ raptorio, handleAdd }) => {
  return (
    <React.Fragment>
      <CreateWallet raptorio={raptorio} handleAdd={handleAdd} />{" "}
      <ImportWallet raptorio={raptorio} handleAdd={handleAdd} />
    </React.Fragment>
  );
};

export default HeaderControls;
