export const copyEntry = (entry, callback) => {
  let successResponse = "Copied!";
  if (entry.includes(",")) {
    successResponse = "Copied all!";
  }
  try {
    navigator.clipboard.writeText(entry).then(
      () => {
        return callback(successResponse, "success");
      },
      (err) => {
        console.log("clipboard didnt work", err);
        return callback("Problem adding to clipboard!", "danger");
      }
    );
  } catch (error) {
    return callback("Copy didn't work!", "danger");
  }
};

const exportable = {
  copyEntry,
};

export default exportable;
