import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import {
  Badge,
  Col,
  Container,
  OverlayTrigger,
  Popover,
  Row,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { ExplorerBreadcrumbs } from "../../components/Breadcrumbs";
import DataTable from "../../components/tables/DataTable";
import { getData } from "../../service/Service";
import NodeMap from "./components/SmartnodesMapCard";
import SmartnodesList from "./components/SmartnodesCard";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var updateLocale = require("dayjs/plugin/updateLocale");
var utc = require("dayjs/plugin/utc");
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "1 minute",
    mm: "%d minutes",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day",
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years",
  },
});

const determineStatusClass = (status) => {
  switch (status) {
    case "ENABLED":
      return "success";
    case "POSE_BANNED":
      return "danger";
    case "UNKNOWN":
      return "secondary";
    default:
      return "primary";
  }
};

const Nodes = ({ reload }) => {
  // We'll start our table without any data
  const [loading, setLoading] = React.useState(false);
  const defaultSortCol = React.useRef("prev_paid_block");
  const service = React.useRef("explorer");
  const method = React.useRef("getNodeList");

  const NodeListCols = React.useMemo(
    () => [
      {
        header: "Service",
        footer: (props) => props.column.id,
        columns: [
          {
            id: "service",
            cell: (info) => info.getValue(),
            header: () => <span>IP</span>,
            footer: (props) => props.column.id,
            accessorFn: (row) => {
              return (
                <Link
                  to={"/explorer/node/" + row.service}
                  className="cursor-zoom-in"
                >
                  {row.service}
                </Link>
              );
            },
          },
          {
            id: "status",
            cell: (info) => info.getValue(),
            header: () => <span>Status</span>,
            footer: (props) => props.column.id,
            accessorFn: (row) => {
              return (
                <OverlayTrigger
                  key={`status-${row.service}`}
                  placement="top"
                  delay={{ show: 500, hide: 100 }}
                  overlay={
                    <Popover id={`statuspopover-${row.service}`}>
                      <Popover.Header as="h3">
                        <FontAwesomeIcon
                          icon={faCircle}
                          className={"text-" + determineStatusClass(row.status)}
                        />{" "}
                        {`${row.service}`}
                      </Popover.Header>
                      <Popover.Body>
                        {parseInt(row.reachable)
                          ? "Reachable"
                          : "Not Reachable"}
                        {row.pose_penalty > 0 ? (
                          <span className="d-block">
                            PoSe Penalty:{" "}
                            <span
                              className={
                                "text-" + determineStatusClass(row.status)
                              }
                            >
                              {row.pose_penalty}
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <div>
                    <span className="d-none d-xl-block">
                      <Badge
                        bg={determineStatusClass(row.status)}
                        key={row.service}
                      >
                        {row.status}
                      </Badge>
                    </span>
                    <span className="d-xl-none">
                      <FontAwesomeIcon
                        icon={faCircle}
                        className={"text-" + determineStatusClass(row.status)}
                      />
                    </span>
                  </div>
                </OverlayTrigger>
              );
            },
          },
          {
            id: "pose_penalty",
            cell: (info) => info.getValue(),
            header: () => <span>PoSe Penalty</span>,
            footer: (props) => props.column.id,
            accessorKey: "pose_penalty",
          },
        ],
      },
      {
        header: "Rewards",
        footer: (props) => props.column.id,
        columns: [
          {
            id: "prev_paid_block",
            cell: (info) => info.getValue(),
            header: () => <span>Last Block</span>,
            footer: (props) => props.column.id,
            accessorFn: (row) => {
              if (row.prev_paid_block === "0") {
                return "";
              }
              return (
                <Link
                  to={"/explorer/block/" + row.prev_paid_block}
                  className="cursor-zoom-in"
                >
                  <NumericFormat
                    value={row.prev_paid_block}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </Link>
              );
            },
          },
          {
            id: "prev_paid_time",
            cell: (info) => info.getValue(),
            header: () => <span>Last Reward</span>,
            footer: (props) => props.column.id,
            accessorFn: (row) => {
              if (row.prev_paid_time === "0") {
                return "Never";
              }
              return (
                <OverlayTrigger
                  key={`prevtime-${row.service}`}
                  placement="top"
                  delay={{ show: 500, hide: 100 }}
                  overlay={
                    <Tooltip id="tooltip-top">
                      {row.prev_paid_time_format}
                    </Tooltip>
                  }
                >
                  <span>
                    {row.prev_paid_time > 0
                      ? dayjs.unix(row.prev_paid_time).fromNow(false)
                      : ""}
                  </span>
                </OverlayTrigger>
              );
            },
          },
          {
            id: "next_paid_time",
            cell: (info) => info.getValue(),
            header: () => <span>Next Reward</span>,
            footer: (props) => props.column.id,
            accessorFn: (row) => {
              return (
                <OverlayTrigger
                  key={`nexttime-${row.service}`}
                  placement="left"
                  delay={{ show: 500, hide: 100 }}
                  overlay={
                    <Tooltip id="tooltip-left">
                      {row.next_paid_time_format}
                    </Tooltip>
                  }
                >
                  <span>
                    {row.next_paid_time > 0
                      ? dayjs.unix(row.next_paid_time).fromNow(true)
                      : ""}
                  </span>
                </OverlayTrigger>
              );
            },
          },
        ],
      },
    ],
    []
  );

  const fetchData = React.useCallback(
    ({ pageIndex, pageSize, sorting, globalFilter }) => {
      // Set the loading state
      setLoading(true);

      const sortBy = sorting[0]?.id ?? defaultSortCol.current;
      const sortDir =
        sorting[0]?.id !== undefined
          ? sorting[0]?.desc === true
            ? "DESC"
            : "ASC"
          : "DESC";

      const params = {
        service: service.current,
        method: method.current,
        page: pageIndex,
        limit: pageSize,
        dir: sortDir,
        sort: sortBy,
        filter: globalFilter,
      };

      const serverData = getData(params)
        .then((data) => {
          const status = data.status;
          if (status === "success") {
            if (typeof data[params.method].results !== "undefined") {
              const payload = data[params.method].results;
              const count = parseInt(data[params.method].summary.pages);

              return { rows: payload, pageCount: count };
              //setPageCount(parseInt(data[params.method].summary.pages));
            }
          } else {
            throw new Error("didnt get data");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .then((data) => {
          setLoading(false);
          return data;
        });
      return serverData;
    },
    []
  );

  return (
    <React.Fragment>
      <Helmet title="Smartnodes" />
      <Container fluid className="p-0">
        <ExplorerBreadcrumbs tabname="Smartnodes" />
        <Row xs={1} md={2}>
          <Col className="d-flex">
            <SmartnodesList reload={loading} />
          </Col>
          <Col className="d-flex">
            <NodeMap reload={loading} />
          </Col>
        </Row>
        <Row>
          <Col>
            <DataTable
              queryKey={method.current}
              columns={NodeListCols}
              fetchData={fetchData}
              loading={loading}
              placeholderText="Search (by IP, addresses)"
              defaultSortCol={defaultSortCol.current}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Nodes;
