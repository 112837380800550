import React from "react";
import { Link } from "react-router-dom";
import { Card, Spinner } from "react-bootstrap";
import Chart from "react-apexcharts";
import { getData } from "../../../service/Service";
import usePalette from "../../../hooks/usePalette";
import useTheme from "../../../hooks/useTheme";

const TransactionTypeCard = ({ reload }) => {
  const palette = usePalette();
  const appTheme = useTheme();
  const [loading, setLoading] = React.useState(true);
  const [chartData, setChartData] = React.useState([]);
  const [chartOptions] = React.useState({
    chart: {
      theme: appTheme.theme,
      foreColor: palette["gray-600"],
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    },
    tooltip: {
      enabled: false,
    },
    labels: [
      "Standard",
      "Node Registration",
      "Update Node Service",
      "Update Node Operator",
      "Node Operator Revocation",
      "Coinbase",
      "Quorum Commitment",
      "Future",
      "Asset",
      "Update Asset",
    ],
    stroke: {
      width: 0,
    },
    colors: [
      palette.success,
      "#6610f2",
      palette.warning,
      palette.warning,
      palette["gray-500"],
      palette.primary,
      palette.danger,
      "#e83e8c",
      "#20c997",
      "#20c997",
    ],
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "10px",
    },
    plotOptions: {
      pie: {
        expandOnClick: true,
        customScale: 0.9,
        dataLabels: {
          offset: 45,
          minAngleToShowLabel: 10,
        },
        donut: {
          background: "transparent",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "14px",
              fontWeight: 100,
              offsetY: -10,
            },
            value: {
              show: true,
              fontSize: "14px",
              fontWeight: 100,
              offsetY: 16,
              formatter: (val) => {
                return (
                  Number(val).toLocaleString("en", {
                    maximumFractionDigits: 2,
                  }) + " Txs"
                );
              },
            },
            total: {
              show: true,
              showAlways: false,
              label: "Total",
              fontSize: "16px",
              fontWeight: 200,
              formatter: (w) => {
                return (
                  w.globals.seriesTotals
                    .reduce((a, b) => {
                      return a + b;
                    }, 0)
                    .toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    }) + " Txs"
                );
              },
            },
          },
        },
      },
    },
  });

  const fetchData = React.useCallback(() => {
    const params = {
      service: "statistics",
      method: "getTxTypeChart",
    };

    setLoading(true);

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setChartData(payload.chart[0].series);
          }
        } else {
          console.log(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  return (
    <Card className="flex-grow-1 align-self-stretch shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          <Link to={"/explorer/transactions"} className="text-reset">
            Transaction Types
          </Link>
          {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="chart">
          {chartData.length > 0 && (
            <Chart
              options={chartOptions}
              series={chartData}
              type="donut"
              height="300"
            />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default TransactionTypeCard;
