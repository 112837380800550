import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet-async";
import { SettingsBreadcrumbs } from "../../components/Breadcrumbs";
import {
  Alert,
  Card,
  Col,
  Container,
  Dropdown,
  Fade,
  Form,
  ListGroup,
  Row,
  Tab,
} from "react-bootstrap";

import currencies from "../../assets/static/currencies";
import timezones from "../../assets/static/timezones";
import locales from "../../assets/static/locales";

import {
  SIDEBAR_POSITION,
  SIDEBAR_BEHAVIOR,
  LAYOUT,
  THEME,
} from "../../constants";

import useTheme from "../../hooks/useTheme";
import useSidebar from "../../hooks/useSidebar";
import useLayout from "../../hooks/useLayout";

const themeOptions = [
  {
    name: "Dark",
    value: THEME.DARK,
  },
  {
    name: "Balanced",
    value: THEME.DEFAULT,
  },
  {
    name: "Light",
    value: THEME.LIGHT,
  },
  {
    name: "Colored",
    value: THEME.COLORED,
  },
  {
    name: "Raptoreum",
    value: THEME.RAPTOREUM,
  },
];

const sidebarPositionOptions = [
  {
    name: "Left",
    value: SIDEBAR_POSITION.LEFT,
  },
  {
    name: "Right",
    value: SIDEBAR_POSITION.RIGHT,
  },
];

const sidebarBehaviorOptions = [
  {
    name: "Sticky",
    value: SIDEBAR_BEHAVIOR.STICKY,
  },
  {
    name: "Fixed",
    value: SIDEBAR_BEHAVIOR.FIXED,
  },
  {
    name: "Compact",
    value: SIDEBAR_BEHAVIOR.COMPACT,
  },
];

const layoutOptions = [
  {
    name: "Fluid",
    value: LAYOUT.FLUID,
  },
  {
    name: "Boxed",
    value: LAYOUT.BOXED,
  },
];

const Timezone = () => {
  const [tz, setTZ] = useState("America/New_York");
  const handleChange = (e) => {
    setTZ(e.currentTarget.value);
  };
  return (
    <Form.Select
      aria-label="timezone"
      size="sm"
      onChange={handleChange}
      defaultValue={tz}
    >
      {timezones.map((zones, i) => (
        <optgroup label={zones.text} key={"tz" + i}>
          {zones.utc.map((zone, z) => {
            return (
              <option key={"z" + z} value={zone}>
                {zone}
              </option>
            );
          })}
        </optgroup>
      ))}
    </Form.Select>
  );
};

const Locale = () => {
  const [lc, setLC] = useState("en-US");
  const handleChange = (e) => {
    setLC(e.currentTarget.value);
  };
  return (
    <Form.Select
      aria-label="locale"
      size="sm"
      onChange={handleChange}
      defaultValue={lc}
    >
      {locales.map((locale) => (
        <option key={locale.value} value={locale.value}>
          {locale.value} - {locale.desc}
        </option>
      ))}
    </Form.Select>
  );
};

const Currency = () => {
  const [cur, setCur] = useState("usd");
  const handleChange = (e) => {
    setCur(e.currentTarget.value);
  };
  return (
    <Form.Select
      aria-label="currency"
      size="sm"
      onChange={handleChange}
      defaultValue={cur}
    >
      {currencies.map((currency) => (
        <option key={currency.value} value={currency.value}>
          {currency.desc} ({currency.sym})
        </option>
      ))}
    </Form.Select>
  );
};

const navItems = [
  {
    key: "theme",
    name: "Theme",
    tooltip: "Theme",
  },
  {
    key: "locale",
    name: "Locale",
    tooltip: "Locale",
  },
  {
    key: "data",
    name: "Data Management",
    tooltip: "Data Management",
  },
];

const Navigation = ({ setTab, setTabname }) => {
  const [key, setKey] = useState("theme");
  const [name, setName] = useState("Theme");
  const isSmallScreen = useMediaQuery({ query: "(max-width: 575px)" });
  if (isMobile || isSmallScreen) {
    return (
      <Dropdown
        className="dropdown-block"
        onSelect={(k) => {
          setKey(k);
          setTab(k);
        }}
      >
        <Dropdown.Toggle>{name}</Dropdown.Toggle>
        <Dropdown.Menu>
          {navItems.map((item) => {
            return (
              <Dropdown.Item
                key={`dd-${item.key}`}
                eventKey={item.key}
                active={key === item.key}
                name={item.name}
                onClick={() => {
                  setName(item.name);
                  setTabname(item.name);
                }}
              >
                {item.name}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
  return (
    <Card className="shadow-lg">
      <ListGroup
        variant="flush"
        onSelect={(k) => {
          setKey(k);
          setTab(k);
        }}
      >
        {navItems.map((item) => {
          return (
            <ListGroup.Item
              key={`lg-${item.key}`}
              tag="a"
              eventKey={item.key}
              action
              active={key === item.key}
              onClick={() => {
                setName(item.name);
                setTabname(item.name);
              }}
            >
              {item.name}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </Card>
  );
};

const Theme = () => {
  const { theme, setTheme } = useTheme();
  const { position, setPosition, behavior, setBehavior } = useSidebar();
  const { layout, setLayout } = useLayout();

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Theme
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Alert variant="primary">
          <div className="alert-message">
            <strong>Hey there!</strong>{" "}
            <span>
              Set your own customized style below. Choose the ones that best
              fits your needs.
            </span>
          </div>
        </Alert>

        <div className="mb-3">
          <span className="d-block font-size-lg fw-bold">Color scheme</span>
          <span className="d-block text-muted mb-2">
            The perfect color mode for your portfolio.
          </span>
          <div className="row g-0 text-center mx-n1 mb-2">
            {themeOptions.map(({ name, value }) => (
              <div className="col-3" key={value}>
                <label className="mx-1 d-block mb-1">
                  <input
                    className="settings-scheme-label"
                    type="radio"
                    name="theme"
                    value={value}
                    checked={theme === value}
                    onChange={() => setTheme(value)}
                  />
                  <div className="settings-scheme">
                    <div
                      className={`settings-scheme-theme settings-scheme-theme-${value}`}
                    ></div>
                  </div>
                </label>
                {name}
              </div>
            ))}
          </div>
        </div>
        <hr />
        <div className="mb-3">
          <span className="d-block font-size-lg fw-bold">Sidebar position</span>
          <span className="d-block text-muted mb-2">
            Toggle the position of the sidebar.
          </span>
          <div>
            {sidebarPositionOptions.map(({ name, value }) => (
              <label className="me-1" key={value}>
                <input
                  className="settings-button-label"
                  type="radio"
                  name="sidebarPosition"
                  value={value}
                  checked={position === value}
                  onChange={() => setPosition(value)}
                />
                <div className="settings-button">{name}</div>
              </label>
            ))}
          </div>
        </div>
        <hr />
        <div className="mb-3">
          <span className="d-block font-size-lg fw-bold">Sidebar behavior</span>
          <span className="d-block text-muted mb-2">
            Change the behavior of the sidebar.
          </span>
          <div>
            {sidebarBehaviorOptions.map(({ name, value }) => (
              <label className="me-1" key={value}>
                <input
                  className="settings-button-label"
                  type="radio"
                  name="sidebarBehavior"
                  value={value}
                  checked={behavior === value}
                  onChange={() => setBehavior(value)}
                />
                <div className="settings-button">{name}</div>
              </label>
            ))}
          </div>
        </div>
        <hr />
        <div className="mb-3">
          <span className="d-block font-size-lg fw-bold">Layout</span>
          <span className="d-block text-muted mb-2">
            Toggle container layout system.
          </span>
          <div>
            {layoutOptions.map(({ name, value }) => (
              <label className="me-1" key={value}>
                <input
                  className="settings-button-label"
                  type="radio"
                  name="layout"
                  value={value}
                  checked={layout === value}
                  onChange={() => setLayout(value)}
                />
                <div className="settings-button">{name}</div>
              </label>
            ))}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

const LocalePreferences = () => {
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Locale
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group as={Row} className="mb-3" controlId="prefLocale">
            <Form.Label column sm={2}>
              Locale
            </Form.Label>
            <Col xs="auto">
              <Locale />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="prefCurrency">
            <Form.Label column sm={2}>
              Currency
            </Form.Label>
            <Col xs="auto">
              <Currency />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="prefTimezone">
            <Form.Label column sm={2}>
              Timezone
            </Form.Label>
            <Col xs="auto">
              <Timezone />
            </Col>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};

const DataPreferences = () => {
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Data Management
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <input type="text" />
      </Card.Body>
    </Card>
  );
};

const Preferences = () => {
  const [tab, setTab] = useState("theme");
  const [tabname, setTabname] = useState("Theme");
  return (
    <React.Fragment>
      <Helmet title="Preferences" />
      <Container fluid className="p-0">
        <SettingsBreadcrumbs tabname={tabname} />
        <Tab.Container
          id="pref-tabs"
          defaultActiveKey="theme"
          transition={Fade}
        >
          <Row>
            <Col sm={4} lg={3} xl={2} className="text-nowrap">
              <Navigation setTab={setTab} setTabname={setTabname} />
            </Col>
            <Col sm={8} lg={9} xl={10}>
              <Tab.Content>
                <Tab.Pane eventKey="theme">
                  {!!tab && tab === "theme" && <Theme />}
                </Tab.Pane>
                <Tab.Pane eventKey="locale">
                  {!!tab && tab === "locale" && <LocalePreferences />}
                </Tab.Pane>
                <Tab.Pane eventKey="data">
                  {!!tab && tab === "data" && <DataPreferences />}
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </React.Fragment>
  );
};

export default Preferences;
