import React from "react";
import {
  Badge,
  Container,
  OverlayTrigger,
  Popover,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import SyncStatus from "./live/SyncStatus";
import TipJar from "./TipJar";
import stoplooking from "../assets/img/stoplooking.gif";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var LocalizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const WenLambo = () => {
  const randomDate = (start, end) => {
    return new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
  };

  const d = randomDate(new Date(), new Date(2099, 0, 1)).toString();
  const t = new Date().getFullYear();
  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      key="wenlambo"
      placement="top"
      overlay={
        <Popover id="wenwenwen">
          <Popover.Header as="h3">It's {t}, wEn LaMbO?</Popover.Header>
          <Popover.Body className="text-center">
            <span className="fs-6">{dayjs(d).format("L LT")}</span>
            <br />
            <strong className="text-warning fs-4 text-uppercase">
              {dayjs(d).fromNow()}
            </strong>
          </Popover.Body>
        </Popover>
      }
    >
      <span>{t}</span>
    </OverlayTrigger>
  );
};

const DemoVersion = () => {
  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      key="wenprod"
      placement="top"
      overlay={
        <Popover id="versionpopover">
          <Popover.Body className="fs-4 text-uppercase">
            <img
              src={stoplooking}
              width="200"
              height="120"
              alt="felt cute, might delete later"
            />
          </Popover.Body>
        </Popover>
      }
    >
      <Badge bg="" className="badge-soft-danger">
        DEMO
      </Badge>
    </OverlayTrigger>
  );
};

const Footer = () => (
  <footer className="footer user-select-none">
    <Container fluid>
      <Row className="text-muted">
        <Col xs="6" className="text-start">
          <ul className="list-inline">
            <li className="list-inline-item">
              <TipJar />
            </li>
            <li className="list-inline-item">
              <SyncStatus />
              <Link to={"/status"} className="ms-2 text-reset small">
                Status
              </Link>
            </li>
          </ul>
        </Col>
        <Col xs="6" className="text-end">
          <p className="mb-0 small">
            &copy; <WenLambo />{" "}
            <span className="text-muted">{process.env.REACT_APP_NAME}</span>
            <Badge bg="" className="badge-soft-primary p-1 ms-2">
              v{process.env.REACT_APP_VERSION}
            </Badge>
            {process.env.REACT_APP_BUILD === "DEMO" ? <DemoVersion /> : ""}
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
