import React from "react";
import PasswordStrengthBar from "react-password-strength-bar";
//import Raptorio from "../../../../service/Raptorio";
import {
  Accordion,
  Alert,
  Badge,
  Button,
  Container,
  Col,
  Form,
  InputGroup,
  Row,
  Tabs,
  Tab,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import VanityAddress from "./VanityAddress";
const randomAnimalName = require("random-animal-name");
const _ = require("lodash");
//const raptorio = new Raptorio({ coin: "RTM" });

const CreateWallet = ({ handleAdd, raptorio }) => {
  const [show, setShow] = React.useState(false);
  const [key, setKey] = React.useState("home");
  const [mnemonic, setMnemonic] = React.useState(
    _.split(raptorio.generateMnemonic(), " ")
  );
  const [confirmedWordlist, setConfirmedWordlist] = React.useState([]);
  const [currentWord, setCurrentWord] = React.useState("");
  //const [valid, setValid] = React.useState(false);
  const [isValidWordlist, setIsValidWordlist] = React.useState(false);
  const [isValidOptions, setIsValidOptions] = React.useState(false);
  const [entropy, setEntropy] = React.useState(256);
  const [label, setLabel] = React.useState(randomAnimalName());
  const [password, setPassword] = React.useState("");
  const [isDefault, setIsDefault] = React.useState(false);
  const [isTestnet, setIsTestnet] = React.useState(false);
  const [vanityPath, setVanityPath] = React.useState(false);
  const [countAdd, setCountAdd] = React.useState(1);

  const resetState = () => {
    setKey("home");
    setMnemonic(_.split(raptorio.generateMnemonic(), " "));
    setConfirmedWordlist([]);
    setCurrentWord("");
    setIsValidWordlist(false);
    setIsValidOptions(false);
    setEntropy(256);
    setLabel(randomAnimalName());
    setPassword("");
    setIsDefault(false);
    setIsTestnet(false);
  };

  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      resetState();
    }, 100);
  };

  const handleShow = () => {
    setShow(true);
  };

  const savePortfolio = () => {
    //createPortfolio(seed, label, pass, isDefault)
    try {
      raptorio
        .createPortfolio(
          mnemonic.join(" "),
          label,
          password,
          isDefault,
          isTestnet,
          vanityPath,
          parseInt(countAdd)
        )
        .then((res) => {
          if (!!res?.id) {
            handleAdd(res.id);
            handleClose();
          } else {
            throw new Error("Couldnt create portfolio!");
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleEntropyChange = (e) => {
    let set = e.target.value;
    setEntropy(set);
    setMnemonic(_.split(raptorio.generateMnemonic(set), " "));
    setConfirmedWordlist([]);
  };

  const handleDefaultChange = (set) => {
    const isSet = set.target.checked ? true : false;
    setIsDefault(isSet);
  };

  const handleNetworkChange = (set) => {
    const isSet = set.target.checked ? true : false;
    setIsTestnet(isSet);
  };

  const handleSkipConfirmChange = (e) => {
    setIsValidWordlist(e.target.checked);
  };

  const validateLabel = () => {
    const label = document.getElementById("create-label");
    if (
      label.value.length < 1 ||
      label.value === " " ||
      label.value.toLowerCase() === "default"
    ) {
      label.classList.remove("is-valid");
      label.classList.add("is-invalid");
      return false;
    }
    label.classList.add("is-valid");
    label.classList.remove("is-invalid");
    return true;
  };

  const validatePassword = () => {
    const pass = document.getElementById("create-password");
    const passConfirm = document.getElementById("create-passwordConfirm");
    setPassword(pass.value);
    if (pass.value.length < 1 || pass.value === " ") {
      pass.classList.remove("is-valid");
      pass.classList.add("is-invalid");
      return false;
    }
    if (pass.value.length > 1 && passConfirm.value === "") {
      passConfirm.classList.remove("is-valid");
      passConfirm.classList.remove("is-invalid");
      return false;
    }
    if (pass.value !== passConfirm.value) {
      passConfirm.classList.remove("is-valid");
      passConfirm.classList.add("is-invalid");
      return false;
    }
    pass.classList.add("is-valid");
    pass.classList.remove("is-invalid");
    passConfirm.classList.add("is-valid");
    passConfirm.classList.remove("is-invalid");
    return true;
  };

  const validateInput = () => {
    if (validateLabel() && validatePassword()) {
      return setIsValidOptions(true);
    } else {
      return false;
    }
  };

  const handleDragStart = (e, word) => {
    //console.log(e)
    e.dataTransfer.setData("text/plain", "This text may be dragged");
    setCurrentWord(word);
  };
  const handleDragEnd = (e) => {
    //console.log(e, e.target.parentElement)
    //console.log(e)
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e) => {
    //console.log(e)
    e.target.classList.remove("drops");
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = (e) => {
    if (e.target.classList.contains("is-valid")) {
    } else {
      e.target.classList.add("drops");
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.target.classList.remove("drops");
    if (e.target.classList.contains("is-valid")) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    e.target.value = currentWord;
    var targetWord = document.getElementById(currentWord);
    var index = Array.prototype.indexOf.call(
      document.getElementById("accepts").children,
      e.target.parentNode
    );

    if (mnemonic[index] === currentWord) {
      e.target.readOnly = true;
      e.target.classList.add("is-valid");
      e.target.classList.remove("is-invalid");
      targetWord.classList.add("fade");
      setTimeout(() => {
        targetWord.classList.add("d-none");
      }, 300);
      confirmedWordlist.push(currentWord);
      if (confirmedWordlist.length === mnemonic.length) {
        setIsValidWordlist(true);
      }
    } else {
      e.target.classList.add("is-invalid");
      e.target.classList.remove("is-valid");
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleChange = (e, i) => {
    const sibling = document.getElementById("wordlistconfirmform-" + (i + 1));
    if (e.target.value === "") {
      e.target.classList.remove("is-invalid");
    }
    //e.target.value = currentWord;
    var targetWord = document.getElementById(e.target.value);
    var index = Array.prototype.indexOf.call(
      document.getElementById("accepts").children,
      e.target.parentNode
    );

    if (mnemonic[index] === e.target.value) {
      e.target.readOnly = true;
      e.target.classList.add("is-valid");
      e.target.classList.remove("is-invalid");
      targetWord.classList.add("fade");
      if (!!sibling) {
        sibling.focus();
      }
      setTimeout(() => {
        targetWord.classList.add("d-none");
      }, 300);
      confirmedWordlist.push(e.target.value);
      if (confirmedWordlist.length === mnemonic.length) {
        setIsValidWordlist(true);
      }
    } else {
      e.target.classList.add("is-invalid");
      e.target.classList.remove("is-valid");
    }
  };

  const handleCountChange = (e) => {
    setCountAdd(e.target.value);
  };

  const handleFormChange = (e) => {
    var id = e.target.id.split("-").slice().pop();
    var value = e.target.value;
    if (value === "") {
      return false;
    }
    confirmedWordlist[id] = e.target.value;

    if (
      confirmedWordlist.filter(function () {
        return true;
      }).length === mnemonic.length
    ) {
      //this.props.onValid({ valid: true, wordlist: this.state.enteredMnemonic });
    } else {
      //console.log(this.state.enteredMnemonic);
      //console.log("not valid yet");
    }
  };

  const ControlButtons = () => {
    let controls;
    switch (key) {
      case "finalize":
        controls = (
          <Button
            onClick={() => savePortfolio()}
            disabled={!isValidOptions || !isValidWordlist}
          >
            Save Portfolio
          </Button>
        );
        break;
      case "confirm":
        controls = (
          <Button
            onClick={() => setKey("finalize")}
            disabled={!isValidWordlist}
          >
            Continue
          </Button>
        );
        break;
      default:
        controls = <Button onClick={() => setKey("confirm")}>Continue</Button>;
    }
    return controls;
  };

  const handleLabelRefresh = () => {
    let randomLabel = randomAnimalName();
    document.getElementById("create-label").value = randomLabel;
    setLabel(randomLabel);
  };

  return (
    <React.Fragment>
      <Button variant="success" size="sm" onClick={handleShow}>
        Create
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        fullscreen={true}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            id="create-tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
            mountOnEnter={true}
          >
            <Tab eventKey="home" title="Home">
              <Container>
                <Row>
                  <Col className="text-start">
                    <p>Write down your word list, then continue</p>
                  </Col>
                </Row>
                <Row className="justify-content-start">
                  {mnemonic.map((word, i) => (
                    <Col
                      xs={4}
                      md={3}
                      lg={2}
                      className="position-relative p-2"
                      key={i}
                    >
                      <span className="float-label text-sm fw-light">
                        {i + 1}
                      </span>
                      <Form.Control
                        id={"create_word-" + i}
                        size="sm"
                        className="w-float-label font-monospace"
                        type="text"
                        autoComplete="off"
                        placeholder={word}
                        disabled={true}
                        onBlur={(e) => handleFormChange(e)}
                      />
                    </Col>
                  ))}
                </Row>
                <Row className="mt-5">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Advanced Options</Accordion.Header>
                      <Accordion.Body>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label as="legend" column sm={3}>
                            Set as Default Portfolio?
                          </Form.Label>
                          <Col sm={9}>
                            <Form.Switch
                              label="Yes"
                              value="true"
                              name="create-formSetDefault"
                              onChange={(e) => handleDefaultChange(e)}
                              defaultChecked={isDefault}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label as="legend" column sm={3}>
                            Use on Testnet Network?
                          </Form.Label>
                          <Col sm={9}>
                            <Form.Switch
                              label="Yes"
                              value="true"
                              name="create-formSetNetwork"
                              onChange={(e) => handleNetworkChange(e)}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label as="legend" column sm={3}>
                            Create Addresses
                          </Form.Label>
                          <Col sm={1}>
                            <Form.Control
                              aria-label="Small"
                              aria-describedby="inputGroup-sizing-sm"
                              type="number"
                              min="1"
                              defaultValue="1"
                              onChange={(e) => handleCountChange(e)}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label as="legend" column sm={3}>
                            Word List
                          </Form.Label>
                          <Col sm={9}>
                            <Form.Check
                              type="radio"
                              label="12 Words"
                              value="128"
                              name="create-formHorizontalRadios"
                              key="create-formHorizontalRadios1"
                              onChange={(e) => handleEntropyChange(e)}
                              defaultChecked={entropy === 128}
                            />
                            <Form.Check
                              type="radio"
                              label="24 Words"
                              value="256"
                              name="create-formHorizontalRadios"
                              key="create-formHorizontalRadios2"
                              onChange={(e) => handleEntropyChange(e)}
                              defaultChecked={entropy === 256}
                            />
                          </Col>
                        </Form.Group>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Vanity Address</Accordion.Header>
                      <Accordion.Body>
                        <VanityAddress
                          mnemonic={mnemonic}
                          setVanityPath={setVanityPath}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey="confirm" title="Confirm">
              <Container>
                <Row>
                  <Col className="text-start">
                    <p>
                      Confirm your word list to continue.
                      <br />
                      <span className="small text-info">
                        Hint: Drag and drop the word blocks into order{" "}
                        <em>(if on PC)</em> or retype them into the fields.
                        <br />
                        <span className="text-secondary">
                          You may also skip this step below if you are having
                          trouble.
                        </span>
                      </span>
                    </p>
                  </Col>
                </Row>
                <Row id="accepts" className="justify-content-start">
                  {mnemonic.map((word, i) => (
                    <Col
                      xs={4}
                      md={3}
                      lg={2}
                      className="position-relative p-2"
                      key={"wordlistconfirm-" + i}
                    >
                      <span className="float-label text-sm fw-light">
                        {i + 1}
                      </span>
                      <Form.Control
                        id={"wordlistconfirmform-" + i}
                        className="w-float-label font-monospace"
                        size="sm"
                        type="text"
                        autoComplete="off"
                        autoCapitalize="off"
                        disabled={false}
                        onDrop={(e) => handleDrop(e)}
                        onDragOver={(e) => handleDragOver(e)}
                        onDragEnter={(e) => handleDragEnter(e)}
                        onDragLeave={(e) => handleDragLeave(e)}
                        onChange={(e) => handleChange(e, i)}
                      />
                    </Col>
                  ))}
                </Row>
                <Row id="drags" className="justify-content-start mt-4 py-2">
                  {mnemonic
                    .slice()
                    .sort()
                    .map((word, i) => (
                      <Col
                        xs={4}
                        md={3}
                        lg={2}
                        id={word}
                        key={i}
                        className="wordlist-item"
                      >
                        <h4
                          draggable="true"
                          onDragStart={(e) => handleDragStart(e, word)}
                          onDragEnd={(e) => handleDragEnd(e)}
                          className="user-select-none"
                        >
                          <Badge
                            bg="success"
                            className="font-monospace user-select-none"
                          >
                            {word}
                          </Badge>
                        </h4>
                      </Col>
                    ))}
                </Row>
                <Row className="mt-5">
                  <Col>
                    <Alert key="create-alert" variant="warning">
                      <div className="float-start p-3 m-0 text-center">
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          fixedWidth
                          size="4x"
                        />
                      </div>
                      <div className="p-3 m-0 float-end">
                        <strong>You can skip this step.</strong>
                        <br />
                        If you are confident that you have documented your seed
                        phrase, confirm to proceed.
                        <hr />
                        <Form.Switch
                          label="Yes, skip confirmation."
                          value="true"
                          name="formSkipConfirm"
                          onChange={(e) => handleSkipConfirmChange(e)}
                        />
                      </div>
                    </Alert>
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab
              eventKey="finalize"
              title="Finalize"
              disabled={!isValidWordlist}
            >
              <Container>
                <Row>
                  <Col className="text-start">
                    <Form>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                          Label
                        </Form.Label>
                        <Col sm="4">
                          <InputGroup className="mb-3">
                            <Form.Control
                              id="create-label"
                              size="sm"
                              type="text"
                              name="create-label"
                              className="is-valid"
                              placeholder="Label"
                              defaultValue={label}
                              onBlur={(e) => validateInput()}
                              onChange={(e) => validateInput()}
                              required
                              autoComplete="off"
                            />
                            <Button variant="outline-secondary" size="sm">
                              <FontAwesomeIcon
                                icon={faRedo}
                                fixedWidth
                                onClick={() => handleLabelRefresh()}
                              />
                            </Button>
                          </InputGroup>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                          Password
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            id="create-password"
                            size="sm"
                            type="password"
                            name="create-password"
                            placeholder="Enter password"
                            onBlur={(e) => validateInput()}
                            onChange={(e) => validateInput()}
                            required
                            autoComplete="off"
                          />
                          <PasswordStrengthBar password={password} />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                          Confirm Password
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            id="create-passwordConfirm"
                            size="sm"
                            type="password"
                            name="create-passwordConfirm"
                            placeholder="Confirm password"
                            onBlur={(e) => validateInput()}
                            onChange={(e) => validateInput()}
                            required
                            autoComplete="off"
                          />
                        </Col>
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Cancel
          </Button>
          <ControlButtons />
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default CreateWallet;
