import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import Brand from "../sidebar/Brand";
import { Link, useLocation } from "react-router-dom";

const AuthNavbar = () => {
  let location = useLocation();
  return (
    <Navbar className="text-dark py-3 px-0 mt-1" expand="lg">
      <Brand className="py-0 d-block" />
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav>
          <Navbar.Text>
            <Link className="text-primary me-2" to={"/dashboard"}>
              Home
            </Link>
          </Navbar.Text>
          {location.pathname === "/auth/sign-in" && (
            <Navbar.Text>
              <Link className="text-primary me-2" to={"/auth/sign-up"}>
                Sign Up
              </Link>
            </Navbar.Text>
          )}
          {location.pathname === "/auth/sign-up" && (
            <Navbar.Text>
              <Link className="text-primary me-2" to={"/auth/sign-in"}>
                Sign In
              </Link>
            </Navbar.Text>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AuthNavbar;
