import React from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faEdit,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { DeleteDropdownItem } from "./DeleteWidgets";

export const RowControls = ({
  name,
  entry,
  i,
  category,
  editMode,
  setEditMode,
  handleDelete,
  handleUpdateDefault,
}) => {
  return (
    <Dropdown align="end">
      <Dropdown.Toggle variant="light" size="sm">
        <span className="pe-2">
          <FontAwesomeIcon icon={faCogs} fixedWidth />
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-1">
        <Dropdown.Item
          className="ps-2 small"
          onClick={() => setEditMode(!!editMode && editMode === i ? false : i)}
          active={editMode === i}
        >
          <FontAwesomeIcon className="me-2" icon={faEdit} fixedWidth />
          Edit Label
        </Dropdown.Item>
        <Dropdown.Item
          className={
            entry.isDefault ? "ps-2 small text-secondary" : "ps-2 small"
          }
          onClick={() => handleUpdateDefault(entry.id.toString())}
          disabled={entry.isDefault ? true : false}
        >
          <FontAwesomeIcon className="me-2" icon={faCheckCircle} fixedWidth />
          {entry.isDefault ? "Default" : "Set as Default"}
        </Dropdown.Item>
        <DeleteDropdownItem
          name={name}
          category={category}
          doc={entry}
          value={entry.label}
          handleDelete={handleDelete}
          entryKey={i}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

const exportable = {
  RowControls,
};

export default exportable;
