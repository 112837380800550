import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import CandleChart from "./CandleChart";
import Markets from "./Markets";
import Tickers from "./Tickers";
import IndicatorsChart from "./IndicatorsChart";
import RefreshButtons from "../../components/live/RefreshTimer";
import { MarketBreadcrumbs } from "../../components/Breadcrumbs";

const Market = () => {
  const [loading, setLoading] = React.useState(true);

  const fetchData = React.useCallback(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <React.Fragment>
      <Helmet title="Market" />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col xs="auto" className="d-sm-block">
            <MarketBreadcrumbs tabname="Market" />
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <RefreshButtons loading={loading} callback={fetchData} />
          </Col>
        </Row>
        <Tickers reload={loading} />
        <Row xs={1} md={2}>
          <Col className="d-flex">
            <CandleChart reload={loading} />
          </Col>
          <Col className="d-flex">
            <Markets reload={loading} />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex">
            <IndicatorsChart reload={loading} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Market;
