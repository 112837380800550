import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { getData } from "../../service/Service";
import DataTable from "../../components/tables/DataTable";
import { truncate } from "../../utils/Formatters";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var updateLocale = require("dayjs/plugin/updateLocale");
var utc = require("dayjs/plugin/utc");
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "1 minute",
    mm: "%d minutes",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day",
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years",
  },
});

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, className = "", ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Form.Check
          type="checkbox"
          ref={resolvedRef}
          {...rest}
          className={className + " cursor-pointer"}
        />
      </>
    );
  }
);

const CoinControl = ({
  addresses,
  showCoinControl,
  handleCoinControlClose,
  handleCoinControlSelect,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState({});
  const defaultSortCol = React.useRef("n_time");
  const service = React.useRef("explorer");
  const method = React.useRef("getAddressUtxoList");
  //const [selectedRowsData, setSelectedRowsData] = React.useState({});

  const utxoCols = React.useMemo(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: "hash_this_tx",
        cell: (info) => info.getValue(),
        header: () => <span>Tx ID</span>,
        footer: (props) => props.column.id,
        accessorFn: (row) => {
          return (
            <Link
              to={"/explorer/tx/" + row.txid + "#output-" + row.n_vout}
              className="cursor-zoom-in"
            >
              <span className="d-none d-xl-inline">{row.txid}</span>
              <span className="d-xl-none">{truncate(row.txid)}</span>
            </Link>
          );
        },
      },
      {
        id: "n_time",
        cell: (info) => info.getValue(),
        header: () => <span>Age</span>,
        footer: (props) => props.column.id,
        accessorFn: (row) => {
          return (
            <OverlayTrigger
              key={`age-${row.txid}`}
              placement="top"
              delay={{ show: 500, hide: 100 }}
              overlay={<Tooltip id="tooltip-top">{row.time_format}</Tooltip>}
            >
              <span>{dayjs.unix(row.n_time).fromNow(true)}</span>
            </OverlayTrigger>
          );
        },
      },
      {
        id: "satoshis",
        cell: (info) => {
          return <div className="text-end">{info.getValue()}</div>;
        },
        header: () => <div className="text-end">Amount</div>,
        footer: (props) => props.column.id,
        accessorFn: (row) => {
          return (
            <OverlayTrigger
              key={`value-${row.txid}`}
              placement="left"
              delay={{ show: 500, hide: 100 }}
              overlay={<Tooltip id="tooltip-left">RTM</Tooltip>}
            >
              <span>
                <NumericFormat
                  value={row.satoshis_format}
                  displayType={"text"}
                  thousandSeparator={true}
                  defaultValue={0}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </span>
            </OverlayTrigger>
          );
        },
      },
    ],
    []
  );

  const fetchData = React.useCallback(
    ({ pageIndex, pageSize, sorting, globalFilter }) => {
      // Set the loading state
      setLoading(true);

      const sortBy = sorting[0]?.id ?? defaultSortCol.current;
      const sortDir =
        sorting[0]?.id !== undefined
          ? sorting[0]?.desc === true
            ? "DESC"
            : "ASC"
          : "DESC";

      const params = {
        service: service.current,
        method: method.current,
        address: addresses.join(","),
        page: pageIndex,
        limit: pageSize,
        dir: sortDir,
        sort: sortBy,
        filter: globalFilter,
      };

      const serverData = getData(params)
        .then((data) => {
          const status = data.status;
          if (status === "success") {
            if (typeof data[params.method].results !== "undefined") {
              const payload = data[params.method].results;
              const count = parseInt(data[params.method].summary.pages);

              return { rows: payload, pageCount: count };
              //setPageCount(parseInt(data[params.method].summary.pages));
            }
          } else {
            throw new Error("didnt get data");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .then((data) => {
          setLoading(false);
          return data;
        });
      return serverData;
    },
    [addresses]
  );

  return (
    <Modal show={showCoinControl} onHide={handleCoinControlClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Coin Control</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataTable
          columns={utxoCols}
          fetchData={fetchData}
          loading={loading}
          placeholderText="Search (by tx hash)"
          defaultSortCol={defaultSortCol.current}
          enableSelection={true}
          handleSelection={setSelectedRows}
          selectedRows={selectedRows.rows}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCoinControlClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() =>
            handleCoinControlSelect(selectedRows?.rowData ?? false)
          }
        >
          Select
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CoinControl;
