import React from "react";
import { Card, Spinner, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { NumericFormat } from "react-number-format";
import { getData } from "../../service/Service";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var LocalizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const Markets = ({ reload }) => {
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const fetchData = React.useCallback(() => {
    // Set the loading state
    setLoading(true);

    const params = {
      service: "market",
      method: "getRTMTickers",
    };

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setData(payload);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  const determineClass = (status) => {
    if (status === "green") {
      return "text-success";
    }
    if (status === "yellow") {
      return "text-warning";
    }
    if (status === "red") {
      return "text-danger";
    }
  };

  return (
    <Card className="flex-fill shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Markets {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table size="sm" striped hover className="small" responsive>
          <thead>
            <tr>
              <th></th>
              <th>Market</th>
              <th>Pair</th>
              <th className="text-end">Last</th>
              <th className="text-end">Volume</th>
              <th className="text-end">Spread</th>
              <th className="text-end">Last Traded</th>
            </tr>
          </thead>
          <tbody>
            {!!data &&
              data.map((entry, i) => {
                return (
                  <tr key={"marketsrow-" + i}>
                    <td>
                      <FontAwesomeIcon
                        icon={faCircle}
                        className={determineClass(entry.trust_score)}
                      />
                    </td>
                    <td>{entry.market}</td>
                    <td>
                      {entry.base}/{entry.target}
                    </td>
                    <td className="text-end font-monospace small">
                      <NumericFormat
                        value={entry.converted_last_usd}
                        displayType={"text"}
                        thousandSeparator={true}
                        defaultValue={0}
                        decimalScale={6}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td className="text-end font-monospace small">
                      <NumericFormat
                        value={entry.converted_volume_usd}
                        displayType={"text"}
                        thousandSeparator={true}
                        defaultValue={0}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td className="text-end font-monospace small">
                      <NumericFormat
                        value={entry.bid_ask_spread_percentage}
                        displayType={"text"}
                        thousandSeparator={true}
                        defaultValue={0}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                      %
                    </td>
                    <td className="text-end text-nowrap small">
                      {dayjs.utc(entry.last_traded_at).fromNow()}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer>
        <span className="text-secondary small">Data provided by CoinGecko</span>
      </Card.Footer>
    </Card>
  );
};

export default Markets;
