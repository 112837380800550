import React from "react";
import { Button, Card, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
//get our table component
import AddressTable from "./components/AddressTable";

const addFields = [
  {
    name: "address",
    placeholder: "Address",
    validate: "wallet",
    type: "text",
    required: true,
    autofocus: true,
  },
  { name: "label", placeholder: "Label", type: "text" },
];
const addMethods = [
  { name: "Add One", value: "1" },
  { name: "Add Multiple", value: "2" },
  { name: "Scan QR", value: "3" },
];

const popoverContent = {
  native: (
    <div>
      {`These are addresses that are created within a native ${process.env.REACT_APP_NAME} portfolio.`}
      <hr />
      <span className="text-warning">
        {`It is imperitive that you maintain control of your created private keys
        to access funds!`}
      </span>
    </div>
  ),
  watched: (
    <div>
      {`Watched addresses are external addresses that you can monitor balance and transaction changes on.`}
      <br />
      <span className="text-primary">
        {`They are counted towards your overall portfolio balance.`}
      </span>
      <hr />
      <span className="text-warning">
        {`However, you cannot send from these addresses unless you import the private keys.`}
      </span>
    </div>
  ),
  addressbook: (
    <div>
      {`These addresses are for quick access to send funds to.`}
      <hr />
      <span className="text-warning">These addresses are not watched!</span>
    </div>
  ),
};

const Addresses = ({ name, category, raptorio }) => {
  return (
    <Card key={category + "-card"} className="shadow-lg">
      <Card.Header>
        <div className="float-end">
          <OverlayTrigger
            trigger="click"
            placement="left"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="text-info me-2"
                    fixedWidth
                  />
                  {name}
                </Popover.Header>
                <Popover.Body>{popoverContent[category]}</Popover.Body>
              </Popover>
            }
            rootClose={true}
          >
            <Button variant="light" size="sm">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="text-info"
                fixedWidth
              />
            </Button>
          </OverlayTrigger>
        </div>
        <Card.Title tag="h5" className="mb-0">
          {name}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <AddressTable
          name={name}
          category={category}
          addFields={addFields}
          addMethods={addMethods}
          raptorio={raptorio}
        />
      </Card.Body>
    </Card>
  );
};

export default Addresses;
