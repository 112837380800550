import React from "react";
import { Helmet } from "react-helmet-async";
//import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import RefreshButtons from "../../components/live/RefreshTimer";
import { ExplorerBreadcrumbs } from "../../components/Breadcrumbs";
import BlockList from "./components/BlockListCard";
import TransactionList from "./components/TransactionsListCard";
import SmartnodesList from "./components/SmartnodesCard";
import BlockCard from "./components/BlockCard";
import AddressesCard from "./components/AddressesCard";
import TransactionsCard from "./components/TransactionsCard";
import MaxCoinCard from "./components/MaxCoinCard";
import LockedCoinCard from "./components/LockedCoinCard";
import CirculatingCoinCard from "./components/CirculatingCoinCard";
import HashrateCard from "./components/HashrateCard";
import DifficultyCard from "./components/DifficultyCard";
import ChainworkCard from "./components/ChainworkCard";
import ChainsizeCard from "./components/ChainsizeCard";
import NodeMap from "./components/SmartnodesMapCard";
import BlockchainMsgCard from "./components/BlockchainMsgCard";
import MempoolCard from "./components/MempoolCard";

const Explorer = () => {
  const [loading, setLoading] = React.useState(true);
  //const { t } = useTranslation();

  const fetchData = React.useCallback(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <React.Fragment>
      <Helmet title="Explorer" />
      <Container fluid className="p-0">
        <Row>
          <Col>
            <ExplorerBreadcrumbs tabname="Dashboard" />
          </Col>
          <Col>
            <RefreshButtons loading={loading} callback={fetchData} />
          </Col>
        </Row>
        <Row xs={1} sm={2} xl={4}>
          <Col className="d-flex">
            <BlockCard reload={loading} />
          </Col>
          <Col className="d-flex">
            <AddressesCard reload={loading} />
          </Col>
          <Col className="d-flex">
            <TransactionsCard reload={loading} />
          </Col>
          <Col className="d-flex">
            <MempoolCard reload={loading} />
          </Col>
        </Row>
        <Row xs={1} md={3}>
          <Col className="d-flex">
            <MaxCoinCard reload={loading} />
          </Col>
          <Col className="d-flex">
            <CirculatingCoinCard reload={loading} />
          </Col>
          <Col className="d-flex">
            <LockedCoinCard reload={loading} />
          </Col>
        </Row>
        <Row xs={1} sm={2} xl={4}>
          <Col className="d-flex">
            <HashrateCard reload={loading} />
          </Col>
          <Col className="d-flex">
            <DifficultyCard reload={loading} />
          </Col>
          <Col className="d-flex">
            <ChainworkCard reload={loading} />
          </Col>
          <Col className="d-flex">
            <ChainsizeCard reload={loading} />
          </Col>
        </Row>
        <Row xs={1} md={2}>
          <Col className="d-flex">
            <SmartnodesList reload={loading} />
          </Col>
          <Col className="d-flex">
            <NodeMap reload={loading} />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex">
            <BlockchainMsgCard reload={loading} />
          </Col>
        </Row>
        <Row xs={1} md={2}>
          <Col className="d-flex">
            <BlockList reload={loading} />
          </Col>
          <Col className="d-flex">
            <TransactionList reload={loading} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Explorer;
