import React from "react";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import classNames from "classnames";

const Brand = ({ className }) => {
  return (
    <a className={classNames("sidebar-brand", className)} href="/">
      <Logo />{" "}
      <span className="align-middle me-3">{process.env.REACT_APP_NAME}</span>
      <span className="slogan text-muted fw-lighter fs-sm d-block">
        {process.env.REACT_APP_SLOGAN}
      </span>
    </a>
  );
};

export default Brand;
