import React from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faCopy,
  faEdit,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { QRDropdownItem } from "./QrWidgets";
import { DeleteDropdownItem } from "./DeleteWidgets";

export const RowControls = ({
  name,
  entry,
  i,
  field,
  category,
  editMode,
  setEditMode,
  handleCopy,
  handleRefresh,
  handleDelete,
}) => {
  return (
    <Dropdown align="end">
      <Dropdown.Toggle variant="light" size="sm">
        <span className="pe-2">
          <FontAwesomeIcon icon={faCogs} fixedWidth />
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-1">
        <Dropdown.Item
          className="ps-2 small"
          onClick={() => handleCopy(entry[field])}
        >
          <FontAwesomeIcon className="me-2" icon={faCopy} fixedWidth />
          Copy
        </Dropdown.Item>
        <QRDropdownItem className="ps-2 small" entry={entry[field]} />
        <Dropdown.Item
          className="ps-2 small"
          onClick={() => setEditMode(!!editMode && editMode === i ? false : i)}
          active={editMode === i}
        >
          <FontAwesomeIcon className="me-2" icon={faEdit} fixedWidth />
          Edit Label
        </Dropdown.Item>
        {category === "native" && (
          <Dropdown.Item
            className="ps-2 small"
            onClick={() => handleRefresh(entry[field])}
          >
            <FontAwesomeIcon className="me-2" icon={faRedoAlt} fixedWidth />
            Refresh Transactions
          </Dropdown.Item>
        )}
        <DeleteDropdownItem
          name={name}
          doc={entry}
          value={entry[field]}
          handleDelete={handleDelete}
          entryKey={i}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

const exportable = {
  RowControls,
};

export default exportable;
