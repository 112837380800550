import React, { useState, useContext } from "react";
import { Alert, Card, Table, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { ModalProvider } from "../../../../contexts/ModalContext";
import NotyfContext from "../../../../contexts/NotyfContext";
import HeaderControls from "./PortfolioTableHeaderControls";
import { RowControls } from "./PortfolioTableRowControls";
import { InlineEdit } from "./EditWidgets";

var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const PortfolioTable = ({ name, category, raptorio }) => {
  const [editMode, setEditMode] = useState(false);
  const notyf = useContext(NotyfContext);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const fetchData = React.useCallback(() => {
    // Set the loading state
    setLoading(true);

    const rows = raptorio.getPortfolios();

    rows
      .then((data) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [raptorio]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const provideNotyf = (message, type, duration = 2000) => {
    notyf.open({
      type,
      message,
      duration,
      dismissible: true,
      ripple: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  const formatCreationTime = (timestamp) => {
    var timer = new Date().getTime();
    return dayjs()
      .subtract(timer - timestamp, "ms")
      .fromNow();
  };

  const handleDelete = (doc) => {
    if (!!doc) {
      const portfolioToDelete = doc.id;
      raptorio.deletePortfolio(portfolioToDelete).then((res) => {
        console.log(res);
        /*if (res.deleted) {
          provideNotyf("Deleted portfolio!", "success");
        } else {
          provideNotyf("Couldn't delete portfolio!", "danger");
        }*/
        fetchData();
      });
    }
  };

  const handleLabel = (entry) => {
    //console.log("edit label", entry);
    raptorio.updatePortfolioLabel(entry.id, entry.label).then((res) => {
      if (!!res.rev) {
        provideNotyf("Updated label!", "success");
      } else {
        provideNotyf("Couldn't update label!", "danger");
      }
      fetchData();
    });
  };

  const handleUpdateDefault = (id) => {
    const defaultIt = raptorio.updateDefault(id);
    defaultIt.then((res) => {
      fetchData();
    });
  };

  const updateList = (res) => {
    if (!!res) {
      provideNotyf("Added portfolio!", "success");
    } else {
      provideNotyf("Couldn't add portfolio!", "danger");
    }
    fetchData();
  };

  return (
    <React.Fragment>
      <ModalProvider>
        {(data && data.length > 0 && (
          <Table className="fs-medium user-select-none" hover size="sm">
            <thead>
              <tr>
                <th>Active</th>
                <th>Label</th>
                <th>Created</th>
                <th className="text-end">
                  <HeaderControls raptorio={raptorio} handleAdd={updateList} />
                </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan={4} className="text-center">
                    <Spinner animation="border" variant="primary" size="sm" />
                  </td>
                </tr>
              )}
              {data.map((row, i) => (
                <tr
                  key={row.id}
                  className={editMode === String(i) ? "table-light" : ""}
                >
                  <td>
                    {row.isDefault && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-success"
                        fixedWidth
                      />
                    )}
                  </td>
                  <td
                    onDoubleClick={() => {
                      return setEditMode(String(i));
                    }}
                  >
                    {(!!editMode && editMode === String(i) && (
                      <InlineEdit
                        key={`inlineEdit-${i}`}
                        entry={row}
                        field="label"
                        sibling="address"
                        handleLabel={handleLabel}
                        setEditMode={setEditMode}
                      />
                    )) ||
                      row.label}
                  </td>
                  <td>{formatCreationTime(row.created)}</td>
                  <td className="text-end">
                    <RowControls
                      entry={row}
                      i={String(i)}
                      name={name}
                      category={category}
                      editMode={editMode}
                      setEditMode={setEditMode}
                      handleDelete={handleDelete}
                      handleUpdateDefault={handleUpdateDefault}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )) || (
          <Card bg="light">
            <Card.Body>
              <Alert variant="info" className="p-4">
                <strong>
                  {`We didn't find any portfolios, go ahead and add one!`}
                </strong>
              </Alert>
              <HeaderControls raptorio={raptorio} handleAdd={updateList} />
            </Card.Body>
          </Card>
        )}
      </ModalProvider>
    </React.Fragment>
  );
};

export default PortfolioTable;
