import React from "react";
import { Button, Card, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
//get our table component
import EntryTable from "./components/EntryTable";

const Smartnodes = ({ name, category, raptorio }) => {
  const addMethods = [
    { name: "Add One", value: "1" },
    { name: "Add Multiple", value: "2" },
  ];
  const addFields = [
    {
      name: "ip",
      placeholder: "IP Address",
      validate: "ip",
      type: "text",
      autofocus: true,
      required: true,
    },
    { name: "label", placeholder: "Label", type: "text" },
    {
      name: "notifications",
      placeholder: "Notifications",
      default: true,
      type: "switch",
    },
  ];
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">
        <FontAwesomeIcon
          icon={faInfoCircle}
          className="text-info me-2"
          fixedWidth
        />
        {name}
      </Popover.Header>
      <Popover.Body>
        <div>
          {`You smartnodes can be tracked in your ${process.env.REACT_APP_NAME} portfolio.`}
          <hr />
          <span className="text-warning">
            {`Adding a smartnode to your portfolio will allow you to receive status notifications.`}
          </span>
          <hr />
          <span className="text-info">
            {`The associated addresses for the smartnode will be added to your watched addresses.`}
          </span>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <Card key={category + "-card"} className="shadow-lg">
      <Card.Header>
        <div className="float-end">
          <OverlayTrigger
            trigger="click"
            placement="left"
            overlay={popover}
            rootClose={true}
          >
            <Button variant="light" size="sm">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="text-info"
                fixedWidth
              />
            </Button>
          </OverlayTrigger>
        </div>
        <Card.Title tag="h5" className="mb-0">
          {name}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <EntryTable
          key={name}
          name={name}
          category={category}
          addFields={addFields}
          addMethods={addMethods}
          raptorio={raptorio}
        />
      </Card.Body>
    </Card>
  );
};

export default Smartnodes;
