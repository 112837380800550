import React from "react";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import { Card, Spinner } from "react-bootstrap";
import { getData } from "../../../service/Service";
import { useTranslation } from "react-i18next";
import usePalette from "../../../hooks/usePalette";
import useTheme from "../../../hooks/useTheme";
import { formatBytes } from "../../../utils/Formatters";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var LocalizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const BlockCard = ({ reload }) => {
  const palette = usePalette();
  const appTheme = useTheme();
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [chartData, setChartData] = React.useState([]);
  const [chartOptions] = React.useState({
    chart: {
      theme: appTheme.theme,
      foreColor: palette["gray-600"],
      fontFamily:
        "'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      width: "100%",
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      show: false,
    },
    fill: {
      type: "solid",
      opacity: 0.35,
    },
    xaxis: {
      type: "category",
      //categories: categories.length && categories,
    },
    tooltip: {
      theme: appTheme.theme,
      style: {
        fontSize: "10px",
      },
      x: {
        formatter: (val) => {
          return "Block " + val;
        },
      },
      y: {
        formatter: (val) => {
          return formatBytes(val);
        },
      },
    },
    colors: [palette.primary],
  });
  const { t } = useTranslation();

  const fetchData = React.useCallback(() => {
    const params = {
      service: "statistics",
      method: "getBlockSizeChart",
    };

    setLoading(true);

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].summary !== "undefined") {
            const payload = data[params.method];
            setData(payload.summary);
            setChartData(payload.series);
          }
        } else {
          console.log(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  return (
    <Card className="flex-grow-1 align-self-stretch shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          <Link to={"/explorer/blocks"} className="text-reset">
            {t("Blocks")}
          </Link>
          {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body className="p-0" style={{ minHeight: 125 }}>
        {!!data && (
          <div className="p-4">
            <span className="fs-1">{data.blocks}</span>
            <br />
            <span className="small">{dayjs.unix(data.n_time).fromNow()}</span>
          </div>
        )}
        {chartData.length > 0 && (
          <div className="position-absolute bottom-0 w-100">
            <Chart
              options={chartOptions}
              series={chartData}
              type="bar"
              height={100}
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default BlockCard;
