import React from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Card,
  OverlayTrigger,
  Popover,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { getData } from "../../../service/Service";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
var LocalizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const BlockList = ({ reload }) => {
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { t } = useTranslation();

  const fetchData = React.useCallback(() => {
    const params = {
      service: "explorer",
      method: "getblocklist",
    };

    setLoading(true);

    const serverData = getData(params);

    serverData
      .then((data) => {
        const status = data.status;
        if (status === "success") {
          if (typeof data[params.method].results !== "undefined") {
            const payload = data[params.method].results;
            setData(payload);
          }
        } else {
          console.log(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [fetchData, reload]);

  return (
    <Card className="flex-grow-1 align-self-stretch shadow-lg">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {t("Latest Blocks")}{" "}
          {loading && <Spinner animation="border" size="sm" />}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table hover size="sm">
          <tbody>
            {!!data &&
              data.map((block) => {
                return (
                  <tr key={"latest_b_" + block.block_height}>
                    <td>
                      <Link
                        to={"/explorer/block/" + block.block_height}
                        className="cursor-zoom-in font-monospace"
                      >
                        {block.block_height_format}
                      </Link>
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        fixedWidth
                        className="align-middle mx-2"
                      />
                      <Link
                        to={"/explorer/address/" + block.miner}
                        className="cursor-zoom-in"
                      >
                        <OverlayTrigger
                          key={"miner-tooltip" + block.block_height}
                          placement="top"
                          overlay={
                            <Popover id={"miner-tooltip" + block.block_height}>
                              <Popover.Header as="h3">
                                {block.miner_name}
                              </Popover.Header>
                              <Popover.Body className="font-monospace p-2">
                                {block.miner}
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <Badge bg="success">{block.miner_name}</Badge>
                        </OverlayTrigger>
                      </Link>

                      <span className="small float-end">
                        <OverlayTrigger
                          key={"tx-tooltip" + block.block_height}
                          placement="top"
                          overlay={
                            <Tooltip id={"tx-tooltip" + block.block_height}>
                              {block.time_format}
                            </Tooltip>
                          }
                        >
                          <span className="user-select-none">
                            {dayjs.unix(block.n_time).fromNow()}
                          </span>
                        </OverlayTrigger>
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer>
        <Link to={"/explorer/blocks"} className="btn btn-info btn-sm float-end">
          View All
        </Link>
      </Card.Footer>
    </Card>
  );
};

export default BlockList;
